import React from "react";
import { useTranslation } from "react-i18next";
import DropDown from "./DropDown";
import InputField from "./Field";
import DateTimeField from "./DateTime";
import { INVALID, parse, stringify } from "./../../../helpers/parser";

/*
Format of the value

`${type} ${value}`

type:
  'simple'
  value:
    'one_time {ISO date}'
    'repeat (count)_(minute | hour | day | month | year) (starting date)'
    'before (count)_(minute | hour | day | week | month) (general_meeting | financial_year)'


examples:
  'simple one_time 2022-08-29T19:22:43.644Z'
  'simple repeat 1_minute 2022-08-29T19:22:43.644Z'
  'simple repeat 1_day 2022-08-29T19:22:43.644Z'
  'simple before 5_week general_meeting'
  'simple before 6_week financial_year'


type ParsedValue = {
  type: 'simple';
  repeatType: 'one_time' | 'repeat' | 'before';
  date?: string;
  count?: string;
  unit?: string;
  startingDate?: string;
  event?: string;
}
*/
export function AdvancedTimeInterval({ value, onChange, error }) {
  const { t } = useTranslation("common");

  const result = parse(value);

  const intervalTypeOptions = [
    {
      value: "simple",
      label: t(
        "inputs.advancedTimeInterval.intervalType.options.simple",
        "Simple"
      ),
    },
  ];

  return (
    <div>
      <DropDown
        name="intervalType"
        label={t(
          "inputs.advancedTimeInterval.intervalType.label",
          "Interval Type"
        )}
        options={intervalTypeOptions}
        value={result.type === INVALID ? null : result.type}
        placeholder={t(
          "inputs.advancedTimeInterval.intervalType.placeholder",
          "select interval type"
        )}
        onChange={(selected) => {
          onChange(
            stringify({
              type: selected.value,
            })
          );
        }}
        error={error && result.type === INVALID ? true : false}
      />
      {result.type === "simple" && (
        <AdvancedTimeIntervalSimple
          value={result}
          onChange={(value) => {
            onChange(stringify(value));
          }}
          error={error}
        />
      )}
    </div>
  );
}

function AdvancedTimeIntervalSimple({ value, onChange, error }) {
  const { t } = useTranslation("common");

  const intervalTypeOptions = [
    {
      value: "one_time",
      label: t(
        "inputs.advancedTimeInterval.intervalType.options.one_time",
        "One time"
      ),
    },
    {
      value: "repeat",
      label: t(
        "inputs.advancedTimeInterval.intervalType.options.repeat",
        "Repeat"
      ),
    },
    {
      value: "before",
      label: t(
        "inputs.advancedTimeInterval.intervalType.options.before",
        "Before an event"
      ),
    },
  ];

  return (
    <div>
      <DropDown
        name="repeatType"
        label={t("inputs.advancedTimeInterval.repeatType.label", "Repeat type")}
        options={intervalTypeOptions}
        value={value.repeatType === INVALID ? null : value.repeatType}
        placeholder={t(
          "inputs.advancedTimeInterval.repeatType.placeholder",
          "select repeat type"
        )}
        onChange={(selected) => {
          onChange({
            type: value.type,
            repeatType: selected.value,
          });
        }}
        error={error && value.repeatType === INVALID ? true : false}
      />
      {value.repeatType === "one_time" && (
        <AdvancedTimeIntervalSimpleOneTime
          value={value}
          onChange={onChange}
          error={error}
        />
      )}
      {value.repeatType === "repeat" && (
        <AdvancedTimeIntervalSimpleRepeat
          value={value}
          onChange={onChange}
          error={error}
        />
      )}
      {value.repeatType === "before" && (
        <AdvancedTimeIntervalSimpleBefore
          value={value}
          onChange={onChange}
          error={error}
        />
      )}
    </div>
  );
}

function AdvancedTimeIntervalSimpleOneTime({ value, onChange, error }) {
  const { t } = useTranslation("common");

  return (
    <DateTimeField
      label={t(
        "inputs.advancedTimeInterval.dateOfReceiving.label",
        "Receiving date"
      )}
      name="starts_at"
      value={new Date(value.date)}
      placeholder={t(
        "inputs.advancedTimeInterval.dateOfReceiving.placeholder",
        "select receiving date"
      )}
      onChange={(date) => {
        onChange({
          ...value,
          date: date.toISOString(),
        });
      }}
      showtime="yes"
      error={error && value.date === INVALID ? true : false}
    />
  );
}

function getUnitOptions(t, count) {
  const unitOptions = [
    {
      value: "minute",
      label: t("inputs.advancedTimeInterval.unit.options.minute", {
        defaultValue: "Minute",
        count,
        ns: "common",
      }),
    },
    {
      value: "hour",
      label: t("inputs.advancedTimeInterval.unit.options.hour", {
        defaultValue: "Hour",
        count,
        ns: "common",
      }),
    },
    {
      value: "day",
      label: t("inputs.advancedTimeInterval.unit.options.day", {
        defaultValue: "Day",
        count,
        ns: "common",
      }),
    },
    {
      value: "week",
      label: t("inputs.advancedTimeInterval.unit.options.week", {
        defaultValue: "Week",
        count,
        ns: "common",
      }),
    },
    {
      value: "month",
      label: t("inputs.advancedTimeInterval.unit.options.month", {
        defaultValue: "Month",
        count,
        ns: "common",
      }),
    },
    {
      value: "year",
      label: t("inputs.advancedTimeInterval.unit.options.year", {
        defaultValue: "Year",
        count,
        ns: "common",
      }),
    },
  ];

  return unitOptions;
}

function getUnitOptionLabel(value, t, count) {
  const unitOptions = getUnitOptions(t, count);

  const unit = unitOptions.find((uo) => uo.value === value);

  if (unit) {
    return unit.label;
  }

  return t("inputs.advancedTimeInterval.unit.unknownUnit", {
    defaultValue: "Unknown unit",
    ns: "common",
  });
}

function getEventOptions(t) {
  const eventOptions = [
    {
      value: "general_meeting",
      label: t("inputs.advancedTimeInterval.event.options.general_meeting", {
        defaultValue: "General meeting",
        ns: "common",
      }),
    },
    {
      value: "financial_year",
      label: t("inputs.advancedTimeInterval.event.options.financial_year", {
        defaultValue: "Financial year",
        ns: "common",
      }),
    },
  ];

  return eventOptions;
}

function getEventOptionLabel(value, t) {
  const eventOptions = getEventOptions(t);

  const event = eventOptions.find((uo) => uo.value === value);

  if (event) {
    return event.label;
  }

  return t("inputs.advancedTimeInterval.event.unknownEvent", {
    defaultValue: "Unknown event",
    ns: "common",
  });
}

function AdvancedTimeIntervalSimpleRepeat({ value, onChange, error }) {
  const { t } = useTranslation("common");

  const unitOptions = getUnitOptions(t);

  return (
    <>
      <InputField
        label={t("inputs.advancedTimeInterval.count.label", "Count")}
        type="number"
        placeholder={t(
          "inputs.advancedTimeInterval.count.placeholder",
          "enter count"
        )}
        value={value.count === INVALID ? "" : value.count}
        onChange={(v) => {
          if (v) {
            onChange({ ...value, count: v });
          } else {
            onChange({ ...value, count: INVALID });
          }
        }}
        error={error && value.count === INVALID ? true : undefined}
        decimals={0}
      />
      <DropDown
        name="unit"
        label={t("inputs.advancedTimeInterval.unit.label", "Unit")}
        options={unitOptions}
        value={value.unit === "<invalid>" ? null : value.unit}
        placeholder={t(
          "inputs.advancedTimeInterval.unit.placeholder",
          "select unit"
        )}
        onChange={(selected) => {
          onChange({
            ...value,
            unit: selected.value,
          });
        }}
        error={error && value.unit === INVALID ? true : undefined}
      />
      <DateTimeField
        label={t(
          "inputs.advancedTimeInterval.startingDate.label",
          "Starting date"
        )}
        name="starts_at"
        value={
          value.startingDate === INVALID ? "" : new Date(value.startingDate)
        }
        placeholder={t(
          "inputs.advancedTimeInterval.startingDate.placeholder",
          "select starting date"
        )}
        onChange={(date) => {
          onChange({
            ...value,
            startingDate: date.toISOString(),
          });
        }}
        showtime="yes"
        error={error && value.startingDate === INVALID ? true : undefined}
      />
    </>
  );
}

function AdvancedTimeIntervalSimpleBefore({ value, onChange, error }) {
  const { t } = useTranslation("common");

  const unitOptions = getUnitOptions(t);

  const eventOptions = getEventOptions(t);

  return (
    <>
      <InputField
        label={t("inputs.advancedTimeInterval.count.label", "Count")}
        type="number"
        placeholder={t(
          "inputs.advancedTimeInterval.count.placeholder",
          "enter count"
        )}
        value={value.count === INVALID ? "" : value.count}
        onChange={(v) => {
          if (v) {
            onChange({ ...value, count: v });
          } else {
            onChange({ ...value, count: INVALID });
          }
        }}
        error={error && value.count === INVALID ? true : undefined}
        decimals={0}
      />
      <DropDown
        name="unit"
        label={t("inputs.advancedTimeInterval.unit.label", "Unit")}
        options={unitOptions}
        value={value.unit}
        placeholder={t(
          "inputs.advancedTimeInterval.unit.placeholder",
          "select unit"
        )}
        onChange={(selected) => {
          onChange({
            ...value,
            unit: selected.value,
          });
        }}
        error={error && value.unit === INVALID ? true : undefined}
      />
      <DropDown
        name="event"
        label={t("inputs.advancedTimeInterval.event.label", "Event")}
        options={eventOptions}
        value={value.event}
        placeholder={t(
          "inputs.advancedTimeInterval.event.placeholder",
          "select event"
        )}
        onChange={(selected) => {
          onChange({
            ...value,
            event: selected.value,
          });
        }}
        error={error && value.event === INVALID ? true : undefined}
      />
    </>
  );
}

export function format(value, t) {
  const parsedValue = typeof value === "string" ? parse(value) : value;

  if (parsedValue.type === "simple") {
    if (parsedValue.repeatType === "one_time") {
      return t("inputs.advancedTimeInterval.format.simple.one_time", {
        defaultValue: "Once at {{date}}",
        date: new Date(parsedValue.date).toLocaleString() || "NA",
        ns: "common",
      });
    }
    if (parsedValue.repeatType === "repeat") {
      return t("inputs.advancedTimeInterval.format.simple.repeat", {
        defaultValue: "Repeat every {{amount}} {{unit}} starting from {{date}}",
        date: new Date(parsedValue.startingDate).toLocaleString() || "NA",
        amount: parsedValue.count,
        unit: getUnitOptionLabel(parsedValue.unit, t, parsedValue.count),
        ns: "common",
      });
    }
    if (parsedValue.repeatType === "before") {
      return t("inputs.advancedTimeInterval.format.simple.before", {
        defaultValue: "Remind {{amount}} {{unit}} before {{event}}",
        event: getEventOptionLabel(parsedValue.event, t),
        amount: parsedValue.count,
        unit: getUnitOptionLabel(parsedValue.unit, t, parsedValue.count),
        ns: "common",
      });
    }
  }

  return "";
}
