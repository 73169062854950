import React from "react";
import Table from "../../atoms/List/Table";
import Modal from "../Modal";
import { extractData } from "./converters/utils";
import { converters } from "./converters";
import DropDown from "../../atoms/Input/DropDown";

function ConverterPicker(props) {
  const options = converters.map((c) => ({ value: c, label: c.name }));

  const value = options.find((option) => option.value === props.value);

  const handleConverterChange = (value) => {
    props.onChange(value.value);
  };

  return (
    <DropDown
      options={options}
      value={value}
      onChange={handleConverterChange}
      marginbottom={false}
      menuPlacement={props.menuPlacement}
    />
  );
}

function getColumnKey(column) {
  if (typeof column.accessor === "function") {
    return column.Header;
  }
  return column.accessor;
}

function getInitialAllColumns(columns, visible = true) {
  return columns.reduce((res, column) => {
    const key = getColumnKey(column);
    res[key] = visible;

    return res;
  }, {});
}

const SELECT_ALL_COLUMN_KEY = "__select__all__";

export function ExportModal({
  data,
  columns,
  modalVisible,
  onCloseModal,
  exportFileName,
  tableTitle,
  renderDataFilterComponent,
  dataFilter = (data, filters) => data,
}) {
  const columnsWithAdditionals = [
    { Header: "", accessor: SELECT_ALL_COLUMN_KEY },
    ...columns,
  ];

  const [selectedColumns, setSelectedColumns] = React.useState(() =>
    getInitialAllColumns(columnsWithAdditionals)
  );
  const [converter, setConverter] = React.useState(converters[0]);
  const [filters, setFilters] = React.useState({});

  const filteredData = dataFilter(data, filters);

  return (
    <Modal
      show={modalVisible}
      title="Export"
      submitTitle="Export"
      onSubmit={async () => {
        const { columnNames, rows } = await extractData(
          filteredData,
          columnsWithAdditionals,
          selectedColumns
        );

        const content = await converter.stringify(columnNames, rows);

        await converter.download(exportFileName, content);
      }}
      handleClose={() => {
        onCloseModal();
      }}
      dialogClassName="max-width-none w-80"
      body={
        <>
          {renderDataFilterComponent &&
            renderDataFilterComponent({ filters, setFilters })}
          <Table
            columns={columnsWithAdditionals}
            data={filteredData}
            title={tableTitle}
            enableColumnSelections={true}
            selectedColumns={selectedColumns}
            onSelectColumn={(id, selected) =>
              setSelectedColumns((prev) => {
                if (id === SELECT_ALL_COLUMN_KEY) {
                  return Object.fromEntries(
                    Object.entries(prev).map(([key]) => [key, selected])
                  );
                }
                return { ...prev, [id]: selected };
              })
            }
          />
          <ConverterPicker value={converter} onChange={setConverter} />
        </>
      }
    />
  );
}
