import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { Line } from "./shared/Line";
import { Table } from "./shared/Table";
import { formatNumber } from "./shared/utils";
import { useTranslation } from "react-i18next";

//-----------------------------------------------------------------------------
export async function getActivePassiveData() {
  return exampleData;
}
//-----------------------------------------------------------------------------
const font = {
  normal: "Poppins",
  bold: "Poppins",
};
//-----------------------------------------------------------------------------

const styles = StyleSheet.create({
  body: {
    padding: "30 30 30 30",
    fontSize: 12,
    fontFamily: font.normal,
  },
  title: {
    fontSize: 12,
    fontFamily: font.bold,
    fontWeight: 700,
    marginBottom: 7,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export function ActivePassiveBalanceDocument({ data }) {
  const { t } = useTranslation("common");

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View fixed>
          <View style={styles.header}>
            <View style={styles.title}>
              <Text>{data.label}</Text>
              <Text>
                {data.contact} {t("Period", "Period")}:
              </Text>
              <Text>{data.period}</Text>
            </View>
            <PageDateNumber date={data.date} />
          </View>
          <Line />
        </View>
        <Table
          data={[
            {
              cells: [
                {
                  value: <Text>{t("ACTIVE", "ACTIVE")}</Text>,
                },
                {
                  value: <Text>{t("PASSIVE", "PASSIVE")}</Text>,
                },
              ],
              style: {
                fontFamily: font.bold,
                fontWeight: 700,
              },
            },
            {
              cells: [
                {
                  value: <Balance groups={data.active.groups} />,
                },
                {
                  value: <Balance groups={data.passive.groups} />,
                },
              ],
            },
            {
              cells: [
                {
                  value: (
                    <Text>
                      {data.active.totalLabel}:{" "}
                      {formatNumber(
                        data.active.groups.reduce(
                          (ggs, gg) =>
                            ggs +
                            gg.groups.reduce(
                              (gs, g) =>
                                gs + g.items.reduce((s, i) => s + i.amount, 0),
                              0
                            ),
                          0
                        )
                      )}
                    </Text>
                  ),
                  style: {
                    textAlign: "right",
                    padding: "8px 15px 15px",
                  },
                },
                {
                  value: (
                    <Text>
                      {data.active.totalLabel}:{" "}
                      {formatNumber(
                        data.passive.groups.reduce(
                          (ggs, gg) =>
                            ggs +
                            gg.groups.reduce(
                              (gs, g) =>
                                gs + g.items.reduce((s, i) => s + i.amount, 0),
                              0
                            ),
                          0
                        )
                      )}
                    </Text>
                  ),
                  style: {
                    padding: "8px 15px 15px",
                  },
                },
              ],
              style: {
                fontFamily: font.bold,
                fontWeight: 700,
              },
            },
          ]}
          columns={[
            {
              style: {
                width: "50%",
              },
            },
            {
              style: {
                width: "50%",
                textAlign: "right",
              },
            },
          ]}
          useBorders={true}
        />
      </Page>
    </Document>
  );
}

//-----------------------------------------------------------------------------

function Balance({ groups }) {
  return (
    <View style={{ fontSize: 8 }}>
      {groups.map((group, gi) => (
        <View key={gi}>
          {group.groups.map((g, i) => (
            <Table
              key={i}
              data={[
                ...g.items.map((item) => ({
                  cells: [
                    {
                      value: <Text>{item.number}</Text>,
                    },
                    {
                      value: <Text>{item.description}</Text>,
                    },
                    {
                      value: <Text>{formatNumber(item.amount)}</Text>,
                    },
                  ],
                })),
                {
                  cells: [
                    { value: <Text> </Text> },
                    { value: <Text> </Text> },
                    {
                      value: (
                        <>
                          <Line />
                          <Text>
                            {formatNumber(
                              g.items.reduce((s, i) => s + i.amount, 0)
                            )}
                          </Text>
                        </>
                      ),
                    },
                  ],
                },
              ]}
              columns={[
                {
                  style: {
                    width: "20%",
                    textAlign: "left",
                  },
                },
                {
                  style: {
                    width: "55%",
                    textAlign: "left",
                  },
                },
                {
                  style: {
                    width: "25%",
                    textAlign: "right",
                  },
                },
              ]}
            />
          ))}
          <Line />
          <View
            style={{
              paddingHorizontal: 8,
              justifyContent: "space-between",
              flexDirection: "row",
              fontFamily: font.bold,
              fontWeight: 700,
            }}
          >
            <Text>{group.totalLabel}</Text>
            <Text>
              {formatNumber(
                group.groups.reduce(
                  (gs, g) => gs + g.items.reduce((s, i) => s + i.amount, 0),
                  0
                )
              )}
            </Text>
          </View>
          <Line />
        </View>
      ))}
    </View>
  );
}

//-----------------------------------------------------------------------------

const pageDateNumberStyles = StyleSheet.create({
  pageNumber: {
    fontSize: 12,
    fontFamily: font.bold,
    fontWeight: 700,
  },
});

function PageDateNumber({ date }) {
  const { t } = useTranslation("common");

  return (
    <Text
      style={pageDateNumberStyles.pageNumber}
      render={({ pageNumber, totalPages }) =>
        `${date}\n${t("Page", "Page")}: ${pageNumber} / ${totalPages}`
      }
    />
  );
}

//-----------------------------------------------------------------------------

export const exampleData = {
  label: "Active passive balance",
  contact: "Park residence Sterea 35-36-37 (0669.956.135)",
  period: "2022 (01/11/2021 - 31/10/2022)",
  date: "31/08/2022",
  active: {
    totalLabel: "Total",
    groups: [
      {
        totalLabel: "Receivables and payables within 1 year",
        groups: [
          {
            items: [
              {
                number: "400000",
                description: "Customers",
                amount: 10500.21,
              },
            ],
          },
          {
            items: [
              {
                number: "4101",
                description: "Called, unpaid advance working capital",
                amount: 500,
              },
            ],
          },
          {
            items: [
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
              {
                number: "499",
                description: "checking accounts",
                amount: 944.67,
              },
              {
                number: "4990",
                description: "roundings",
                amount: -0.01,
              },
              {
                number: "499001",
                description: "Payments outside period",
                amount: 1776.38,
              },
            ],
          },
        ],
      },
      {
        totalLabel: "Cash investments and liquid assets",
        groups: [
          {
            items: [
              {
                number: "5521",
                description: "Current account",
                amount: 1157.21,
              },
            ],
          },
        ],
      },
      {
        totalLabel: "Cost",
        groups: [
          {
            items: [
              {
                number: "6160",
                description: "Administrative costs syndic",
                amount: 250,
              },
              {
                number: "61650",
                description: "Certificates article 577-11 Civil Code",
                amount: 650,
              },
            ],
          },
        ],
      },
    ],
  },
  passive: {
    totalLabel: "Total",
    groups: [
      {
        totalLabel: "Receivables and payables within 1 year",
        groups: [
          {
            items: [
              {
                number: "440",
                description: "Suppliers",
                amount: 8818.46,
              },
            ],
          },
        ],
      },
      {
        totalLabel: "Revenue",
        groups: [
          {
            items: [
              {
                number: "701",
                description: "Working capital advances",
                amount: 6750.0,
              },
              {
                number: "7491",
                description: "Proceeds of reminder costs",
                amount: 210.0,
              },
            ],
          },
        ],
      },
    ],
  },
};
