import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { Line } from "./shared/Line";
import { Table } from "./shared/Table";
import { formatNumber, formatTime } from "./shared/utils";
import { getOwners, getSuppliers } from "@syntbeheer/api/services/documents";
import { formatDate } from "./shared/utils";
import { useTranslation } from "react-i18next";

//-----------------------------------------------------------------------------
export async function getMovementOwnersData({ t, vmeId, startDate, endDate }) {
  const data = await getOwners({ vmeId, startDate, endDate });

  const now = new Date();

  return {
    title:
      data.vme.alias +
      (data.vme.vat_number ? " (" + data.vme.vat_number + ")" : ""),
    address: data.vme.Company.address,
    date: formatDate(now),
    time: formatTime(now),

    entities: data.commissioners.map((c) => {
      return {
        name: c.full_name,
        address: c.address,
        items: [
          ...c.provisions.map((p) => {
            return [
              {
                date: formatDate(p.invoice_date),
                number: p.reference,
                description:
                  t(p.type, p.type) +
                  " - " +
                  p.Lot?.name +
                  " - " +
                  formatDate(p.invoice_date, true),
                debit: p.amount,
                credit: 0,
                checked: false,
              },
            ];
          }),
        ],
      };
    }),
  };
}
//-----------------------------------------------------------------------------
export async function getMovementSuppliersData({
  t,
  vmeId,
  startDate,
  endDate,
}) {
  const data = await getSuppliers({ vmeId, startDate, endDate });

  const suppliers = data.purchases.reduce((res, p) => {
    res[p.Supplier.id] = res[p.Supplier.id] ?? [];
    res[p.Supplier.id].push(p);

    return res;
  }, {});

  const now = new Date();

  return {
    title:
      data.vme.alias +
      (data.vme.vat_number ? " (" + data.vme.vat_number + ")" : ""),
    address: data.vme.Company.address,
    date: formatDate(now),
    time: formatTime(now),

    entities: Object.values(suppliers).map((ps) => {
      const s = ps.at(0).Supplier;

      return {
        name: s.alias || s.Company?.name || "",
        address: s.Company?.address || "",
        items: ps.map((p) => {
          return [
            {
              date: formatDate(p.invoice_date),
              number: p.reference,
              description: p.description,
              debit: p.total_amount,
              credit: 0,
              checked: false,
            },
            p.paid_at
              ? {
                  date: formatDate(p.paid_at),
                  number: p.reference,
                  description: p.description,
                  credit: p.total_amount,
                  debit: 0,
                  checked: false,
                }
              : null,
          ].filter((i) => i !== null);
        }),
      };
    }),
  };
}
//-----------------------------------------------------------------------------
const font = {
  normal: "Poppins",
  bold: "Poppins",
};
//-----------------------------------------------------------------------------

const styles = StyleSheet.create({
  body: {
    padding: "30 30 30 30",
    fontSize: 8,
    fontFamily: font.normal,
  },
  title: {
    fontSize: 9,
    fontFamily: font.bold,
    fontWeight: 700,
    color: "#c01d21",
  },
  pageNumber: {
    fontSize: 9,
    fontFamily: font.bold,
    fontWeight: 700,
    alignItems: "flex-end",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
  },
  tableHeader: {
    flexDirection: "row",
    width: "100%",
    border: "2px solid black",
    padding: 5,
    fontFamily: font.bold,
    fontWeight: 700,
    marginBottom: 5,
    backgroundColor: "#0036361a",
  },
});

const columnWidths = [20, 10, 15, 35, 10, 10];
const whole = columnWidths.reduce((s, v) => s + v, 0);
const part = columnWidths.slice(1).reduce((s, v) => s + v, 0);
const listWidths = columnWidths.slice(1).map((v) => (v * whole) / part);

const columns = [
  {
    style: {
      width: listWidths[0] + "%",
    },
  },
  {
    style: {
      width: listWidths[1] + "%",
    },
  },
  {
    style: {
      width: listWidths[2] + "%",
    },
  },
  {
    style: {
      width: listWidths[3] + "%",
    },
  },
  {
    style: {
      width: listWidths[4] + "%",
    },
  },
];

export function FullEntityHistoryDocument({ data, entityColumnHeader }) {
  const { t } = useTranslation("common");

  const grandDebit = data.entities.reduce(
    (gs, c) =>
      gs +
      c.items.reduce(
        (is, i) => is + i.reduce((s, v) => s + (v.debit ?? 0), 0),
        0
      ),
    0
  );
  const grandCredit = data.entities.reduce(
    (gs, c) =>
      gs +
      c.items.reduce(
        (is, i) => is + i.reduce((s, v) => s + (v.credit ?? 0), 0),
        0
      ),
    0
  );
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View fixed>
          <View style={styles.header}>
            <View style={styles.title}>
              <Text>{data.title}</Text>
              <Text>{data.address}</Text>
              <Text>{data.period}</Text>
            </View>
            <Text>
              {t(
                "Situation according to document date on",
                "Situation according to document date on"
              )}
              : {data.date}
            </Text>
            <View style={styles.pageNumber}>
              <Text>
                {t("issue", "issue")}: {data.date} {data.time}
              </Text>
              <PageNumber />
            </View>
          </View>
          <View style={styles.tableHeader}>
            <View style={{ width: columnWidths[0] + "%" }}>
              <Text>{entityColumnHeader}</Text>
            </View>
            <View style={{ width: columnWidths[1] + "%" }}>
              <Text>{t("DOC.DAT.", "DOC.DAT.")}</Text>
            </View>
            <View style={{ width: columnWidths[2] + "%" }}>
              <Text>{t("DOC.NO.", "DOC.NO.")}</Text>
            </View>
            <View style={{ width: columnWidths[3] + "%" }}>
              <Text>{t("DESCRIPTION", "DESCRIPTION")}</Text>
            </View>
            <View style={{ width: columnWidths[4] + "%" }}>
              <Text>{t("DEBIT", "DEBIT")}</Text>
            </View>
            <View style={{ width: columnWidths[5] + "%" }}>
              <Text>{t("CREDIT", "CREDIT")}</Text>
            </View>
          </View>
        </View>
        <Table
          data={[
            ...data.entities.map((customer) => {
              const debit = customer.items.reduce(
                (s, g) => s + g.reduce((ss, i) => ss + (i.debit ?? 0), 0),
                0
              );
              const credit = customer.items.reduce(
                (s, g) => s + g.reduce((ss, i) => ss + (i.credit ?? 0), 0),
                0
              );
              return {
                cells: [
                  {
                    value: (
                      <View style={{ paddingTop: 10 }}>
                        <Text
                          style={{ fontFamily: font.bold, fontWeight: 700 }}
                        >
                          {customer.name}
                        </Text>
                        <Text>{customer.address}</Text>
                      </View>
                    ),
                  },
                  {
                    value: (
                      <>
                        {customer.items.map((group, i) => (
                          <View key={i}>
                            <Line />
                            <Table
                              data={group.map((item) => ({
                                cells: [
                                  {
                                    value: (
                                      <View style={{ flexDirection: "row" }}>
                                        <Text>{item.date}</Text>
                                        {item.checked && (
                                          <Text
                                            style={{
                                              fontFamily: font.bold,
                                              fontWeight: 700,
                                            }}
                                          >
                                            V
                                          </Text>
                                        )}
                                      </View>
                                    ),
                                  },
                                  {
                                    value: <Text>{item.number}</Text>,
                                  },
                                  {
                                    value: <Text>{item.description}</Text>,
                                  },
                                  {
                                    value: (
                                      <Text>
                                        {item.debit !== null &&
                                          formatNumber(item.debit)}
                                      </Text>
                                    ),
                                  },
                                  {
                                    value: (
                                      <Text>
                                        {item.credit !== null &&
                                          formatNumber(item.credit)}
                                      </Text>
                                    ),
                                  },
                                ],
                              }))}
                              columns={columns}
                            />
                          </View>
                        ))}
                        <Table
                          data={[
                            {
                              cells: [
                                {
                                  value: null,
                                },
                                {
                                  value: null,
                                },
                                {
                                  value: (
                                    <View style={{ marginTop: 10 }}>
                                      <Text>{t("Totals", "Totals")}</Text>
                                    </View>
                                  ),
                                },
                                {
                                  value: (
                                    <View>
                                      <Line />
                                      <Text>{formatNumber(debit)}</Text>
                                    </View>
                                  ),
                                },
                                {
                                  value: (
                                    <View>
                                      <Line />
                                      <Text>{formatNumber(credit)}</Text>
                                    </View>
                                  ),
                                },
                              ],
                              style: {
                                textAlign: "right",
                                fontFamily: font.bold,
                                fontWeight: 700,
                              },
                            },
                            {
                              cells: [
                                {
                                  value: null,
                                },
                                {
                                  value: null,
                                },
                                {
                                  value: (
                                    <View style={{ fontSize: 6 }}>
                                      <Text>{t("Balance", "Balance")}</Text>
                                    </View>
                                  ),
                                },
                                {
                                  value: (
                                    <View>
                                      <Text>
                                        {debit > credit
                                          ? formatNumber(debit - credit)
                                          : ""}
                                      </Text>
                                    </View>
                                  ),
                                },
                                {
                                  value: (
                                    <View>
                                      <Text>
                                        {credit > debit
                                          ? formatNumber(credit - debit)
                                          : ""}
                                      </Text>
                                    </View>
                                  ),
                                },
                              ],
                              style: {
                                textAlign: "right",
                                fontFamily: font.bold,
                                fontWeight: 700,
                              },
                            },
                          ]}
                          columns={columns}
                        />
                      </>
                    ),
                  },
                ],
              };
            }),
            {
              cells: [
                { value: null },
                {
                  value: (
                    <Table
                      data={[
                        {
                          cells: [
                            {
                              value: null,
                            },
                            {
                              value: null,
                            },
                            {
                              value: (
                                <View style={{ marginTop: 10 }}>
                                  <Text>
                                    {t("Grand total", "Grand total")} :
                                  </Text>
                                </View>
                              ),
                            },
                            {
                              value: (
                                <View>
                                  <Line />
                                  <Text>{formatNumber(grandDebit)}</Text>
                                </View>
                              ),
                            },
                            {
                              value: (
                                <View>
                                  <Line />
                                  <Text>{formatNumber(grandCredit)}</Text>
                                </View>
                              ),
                            },
                          ],
                          style: {
                            textAlign: "right",
                            fontFamily: font.bold,
                            fontWeight: 700,
                          },
                        },
                        {
                          cells: [
                            {
                              value: null,
                            },
                            {
                              value: null,
                            },
                            {
                              value: (
                                <View style={{ fontSize: 6 }}>
                                  <Text>
                                    {t("Closing balance", "Closing balance")} :
                                  </Text>
                                </View>
                              ),
                            },
                            {
                              value: (
                                <View>
                                  <Text>
                                    {grandDebit > grandCredit
                                      ? formatNumber(grandDebit - grandCredit)
                                      : ""}
                                  </Text>
                                </View>
                              ),
                            },
                            {
                              value: (
                                <View>
                                  <Text>
                                    {grandCredit > grandDebit
                                      ? formatNumber(grandCredit - grandDebit)
                                      : ""}
                                  </Text>
                                </View>
                              ),
                            },
                          ],
                          style: {
                            textAlign: "right",
                            fontFamily: font.bold,
                            fontWeight: 700,
                          },
                        },
                      ]}
                      columns={columns}
                    />
                  ),
                },
              ],
            },
          ]}
          columns={[
            {
              style: {
                width: "20%",
              },
            },
            {
              style: {
                width: "80%",
              },
            },
          ]}
        />
      </Page>
    </Document>
  );
}
//-----------------------------------------------------------------------------
function PageNumber() {
  const { t } = useTranslation("common");

  return (
    <Text
      render={({ pageNumber, totalPages }) =>
        t("Page {{pageNumber}} of {{totalPages}}", {
          defaultValue: "Page {{pageNumber}} of {{totalPages}}",
          pageNumber,
          totalPages,
        })
      }
    />
  );
}
//-----------------------------------------------------------------------------
export const exampleData = {
  title: "003 - ALFEN 0684.473.075",
  address: "Kerkstraat 42 - 46\n1742 Sint-Katherina-lombeek",
  date: "31/05/2022",
  time: "15:10:48",
  entities: [
    {
      name: "Mrs. Bauwens Tessa",
      address: "Kerkstraat 42 bus 0.01 1742 Sint-Katherina-lombeek",
      items: [
        [
          {
            date: "18/10/2021",
            number: "VC1/10",
            description: "CN Guarantee Fund P1",
            debit: null,
            credit: 3,
            checked: false,
          },
          {
            date: "18/10/2021",
            number: "VC1/5",
            description: "CN Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: false,
          },
          {
            date: "18/10/2021",
            number: "VC1/7",
            description: "CN Guarantee Fund 0.6",
            debit: null,
            credit: 88.5,
            checked: false,
          },
          {
            date: "18/10/2021",
            number: "VC1/8",
            description: "CN Working capital P1",
            debit: null,
            credit: 3,
            checked: false,
          },
          {
            date: "05/11/2021",
            number: "VC1/6",
            description: "CN Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: false,
          },
          {
            date: "05/11/2021",
            number: "VC1/9",
            description: "CN Working capital P1",
            debit: null,
            credit: 3,
            checked: false,
          },
          {
            date: "05/11/2021",
            number: "VF1/174",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: false,
          },
          {
            date: "05/11/2021",
            number: "VF1/175",
            description: "Working capital P1",
            debit: 3,
            credit: null,
            checked: false,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
      ],
    },
    {
      name: "Mrs. Bauwens Tessa",
      address: "Kerkstraat 42 bus 0.01 1742 Sint-Katherina-lombeek",
      items: [
        [
          {
            date: "18/10/2021",
            number: "VC1/10",
            description: "CN Guarantee Fund P1",
            debit: null,
            credit: 3,
            checked: false,
          },
          {
            date: "18/10/2021",
            number: "VC1/5",
            description: "CN Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: false,
          },
          {
            date: "18/10/2021",
            number: "VC1/7",
            description: "CN Guarantee Fund 0.6",
            debit: null,
            credit: 88.5,
            checked: false,
          },
          {
            date: "18/10/2021",
            number: "VC1/8",
            description: "CN Working capital P1",
            debit: null,
            credit: 3,
            checked: false,
          },
          {
            date: "05/11/2021",
            number: "VC1/6",
            description: "CN Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: false,
          },
          {
            date: "05/11/2021",
            number: "VC1/9",
            description: "CN Working capital P1",
            debit: null,
            credit: 3,
            checked: false,
          },
          {
            date: "05/11/2021",
            number: "VF1/174",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: false,
          },
          {
            date: "05/11/2021",
            number: "VF1/175",
            description: "Working capital P1",
            debit: 3,
            credit: null,
            checked: false,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 880.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
        [
          {
            date: "01/06/2021",
            number: "VF1/1",
            description: "Working capital 0.6",
            debit: 88.5,
            credit: null,
            checked: true,
          },
          {
            date: "16/06/2021",
            number: "BA3/001",
            description: "Payment: Working capital 0.6",
            debit: null,
            credit: 88.5,
            checked: true,
          },
        ],
      ],
    },
  ],
};
