import React from "react";
import { useTranslation } from "react-i18next";
import DropDown from "./DropDown";

export default function LanguagePicker(props) {
  const { i18n } = useTranslation();

  const languages = [
    {
      value: "nl",
      label: "Nederlands",
    },
    {
      value: "fr",
      label: "Français",
    },
    {
      value: "en",
      label: "English",
    },
  ];

  const value = languages.find((lang) => lang.value === i18n.language);

  const handleLanguageChange = (value) => {
    i18n.changeLanguage(value.value);
    if (props.onChange) {
      props.onChange(value.value);
    }
  };

  return (
    <DropDown
      options={languages}
      value={value}
      onChange={handleLanguageChange}
      marginbottom={false}
      menuPlacement={props.menuPlacement}
    />
  );
}
