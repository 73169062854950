import React from "react";

const CheckBox = ({ marginbottom = true, stylenone = false, ...props }) => {
  const customStyles = {
    // TODO: style
  };
  const checkInput = React.useRef(null);
  return (
    <div
      className={`form-group has-validation ${marginbottom ? "mb-5" : "mb-0"}`}
    >
      <input
        type="checkbox"
        {...props}
        name={props.name}
        aria-describedby={`${props.name}Help`}
        className={`${props.error ? "is-invalid" : ""}`}
        styles={customStyles}
        value="on"
        ref={checkInput}
      />
      <label
        style={stylenone ? { textTransform: "none", fontWeight: 300 } : {}}
        htmlFor={props.name}
        onClick={() => {
          checkInput.current.click();
        }}
      >
        {props.label}
      </label>
      {props.error && (
        <small id={`${props.name}Help`} className="invalid-feedback form-text">
          {props.error}
        </small>
      )}
    </div>
  );
};
export default CheckBox;
