import { csvConverter } from "./csv";
import { xmlConverter } from "./xml";
import { jsonConverter } from "./json";
import { pdfConverter } from "./pdf";
import { pdfPrintConverter } from "./pdfPrint";

export const converters = [
  csvConverter,
  xmlConverter,
  jsonConverter,
  pdfConverter,
  pdfPrintConverter,
];
