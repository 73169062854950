import React from "react";

export default class Button extends React.Component {
  static defaultProps = {
    buttontype: "btn-primary",
    type: "submit",
    marginbottom: 1,
    margintop: 1,
  };
  render() {
    return (
      <div
        className={`form-group ${
          this.props.center ? "d-flex justify-content-center" : ""
        } ${this.props.marginbottom ? "" : "mb-0"} ${
          this.props.margintop ? "" : "mt-0"
        }`}
      >
        <button
          type={this.props.type}
          className={`btn ${this.props.buttontype}`}
          style={
            (this.props.center && {
              marginLeft: "var(--input-padding-x)",
              marginTop: "var(--input-padding-y)",
            },
            this.props.minwidth && { minwidth: "200px" })
          }
          {...this.props}
        >
          {this.props.title}
        </button>
      </div>
    );
  }
}
