import React from "react";
import Table from "./../atoms/List/Table";
import { useTranslation } from "react-i18next";
import { getReminders } from "@syntbeheer/api/services/reminders";
import { getDefaultReminders } from "@syntbeheer/api/services/defaultReminders";
import { format } from "./../atoms/Input/AdvancedTimeInterval";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";

function getMessage(label, lang) {
  return (
    label?.Translations.find((t) => t.language === lang)?.value ??
    label?.Translations.find((t) => t.language === "en")?.value ??
    label?.Translations[0]?.value ??
    ""
  );
}

export default function Reminders({ title, defaultReminders = false }) {
  const { t, i18n } = useTranslation("common");
  const User = usersSelectors.get().User;
  const hasAccessToCreate =
    User?.is_admin ||
    User?.UserVME?.type === "synt_authoriser" ||
    User?.UserVME?.type === "bookkeeper";

  const [reminders, setReminders] = React.useState([]);
  const isDefault = defaultReminders && User.is_admin;

  React.useEffect(() => {
    if (isDefault) {
      getDefaultReminders()
        .then((res) => {
          console.log(res);
          if (res.success) {
            setReminders(res.Reminders);
          }
        })
        .catch((e) => console.log(e));
    } else {
      getReminders()
        .then((res) => {
          if (res.success) {
            setReminders(res.Reminders);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [isDefault]);

  const columns = [
    {
      id: "id",
      Header: t("dashboard.reminders.columns.id", "Id"),
      accessor: "id",
    },
    {
      id: "message",
      Header: t("dashboard.reminders.columns.message", "Message"),
      accessor: (row) =>
        hasAccessToCreate ? (
          <a
            href={
              isDefault
                ? `/app/reminders/default/${row.id}`
                : `/app/reminders/${row.id}`
            }
          >
            {row.type === "unpaid provision"
              ? t(
                  "dashboard.reminders,columns.message.unpaidProvision",
                  "[unpaid provision]"
                )
              : getMessage(row.Label, i18n.language)}
          </a>
        ) : (
          <span>{getMessage(row.Label, i18n.language)}</span>
        ),
    },
    {
      id: "recipient",
      Header: t("dashboard.reminders.columns.recipient", "Recipient"),
      accessor: (row) => row.recipient,
    },
    isDefault
      ? null
      : {
          id: "active",
          Header: t("dashboard.reminders.columns.active", "Active"),
          accessor: (row) =>
            row.active
              ? t("dashboard.reminders.active", "Active")
              : t("dashboard.reminders.disabled", "Disabled"),
        },
    {
      id: "dateOfReceiving",
      Header: t(
        "dashboard.reminders.columns.dateOfReceiving",
        "Receiving date"
      ),
      accessor: (row) => format(row.dateOfReceiving, t),
    },
  ].filter(Boolean);

  return (
    <Table
      columns={columns}
      data={reminders}
      title={title}
      addPath={
        hasAccessToCreate
          ? isDefault
            ? "/app/reminders/default"
            : "/app/reminders"
          : null
      }
    />
  );
}
