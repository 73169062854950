import { useMemo, useEffect, useState, useCallback } from "react";

import Table from "./../../atoms/List/Table";
import { getVMEs, freezeVME } from "@syntbeheer/api/services/vmes/index";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import LoginIcon from "@mui/icons-material/ExitToApp";
import Modal from "./../../organisms/Modal";
import { selectVME } from "@syntbeheer/api/services/users/index";
import { useTranslation, Trans } from "react-i18next";

export default function Associations() {
  const [VMEs, setVMEs] = useState([]);
  const [selectedVME, setSelectedVME] = useState(false);
  const { t } = useTranslation("common");

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    getVMEs()
      .then((response) => {
        console.log(response);
        if (response.success) {
          setVMEs(response.VMEs.sort((a, b) => a.alias.localeCompare(b.alias)));
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const freeze = (id) => {
    setVMEs(
      VMEs.map((VME) => {
        if (VME.id === id) VME.is_frozen = !VME.is_frozen;
        return VME;
      })
    );
    freezeVME(id)
      .then((response) => {
        console.log(response);
      })
      .catch((e) => console.log(e));
  };

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: t("dashboard.associations.columns.alias", "Alias"),
        accessor: "alias",
        Cell: ({ row, value }) => {
          return <a href={`/app/association/${row.values.id}`}>{value}</a>;
        },
      },
      {
        Header: t("dashboard.associations.columns.company", "Company"),
        accessor: "vat_number",
      },
      {
        Header: t("dashboard.associations.columns.name", "Name"),
        accessor: "name",
      },
      {
        Header: t("dashboard.associations.columns.syntName", "Name Synt"),
        accessor: "synt_name",
      },
      {
        Header: t("dashboard.associations.columns.address", "Address"),
        accessor: "address",
      },
      {
        Header: () => null, // No header
        accessor: "is_frozen",
        Cell: ({ row, cell }) => (
          <div style={{ textAlign: "right" }}>
            <button
              className="btn p-0 ml-auto"
              onClick={() => {
                setSelectedVME(VMEs.find((VME) => VME.id === row.values.id));
              }}
            >
              <AcUnitIcon style={cell.value ? { color: "blue" } : {}} />
            </button>
          </div>
        ),
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "login",
        Cell: ({ row }) => (
          <div style={{ textAlign: "right" }}>
            <button
              className="btn p-0 ml-auto"
              onClick={() => {
                selectVME(row.values.id)
                  .then((response) => {
                    console.log(response);
                    if (response.success) {
                      window.location.reload();
                    }
                  })
                  .catch((e) => console.log(e));
              }}
            >
              <LoginIcon />
            </button>
          </div>
        ),
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? "👇" : "👈"}
          </span>
        ),
      },
    ],
    [VMEs]
  );

  // Create a function that will render our row sub components
  const renderRowSubComponent = useCallback(
    ({ row }) => {
      let selectedVME;
      VMEs.forEach((VME) => {
        if (VME.id === row.values.id) {
          selectedVME = VME;
        }
      });
      return (
        <pre
          style={{
            fontSize: "10px",
          }}
          key={row.values.id}
        >
          <code>{JSON.stringify({ selectedVME }, null, 2)}</code>
        </pre>
      );
    },
    [VMEs]
  );

  return (
    <>
      <Modal
        show={Object.keys(selectedVME).length > 0}
        title={`${
          selectedVME.is_frozen
            ? t("dashboard.associations.modifyModal.title", "Modify")
            : t("dashboard.associations.freezeModal.title", "Freeze")
        } ${selectedVME.alias}`}
        submitTitle={`${
          selectedVME.is_frozen
            ? t("dashboard.associations.modifyModal.subTitle", "Hell yes!")
            : t("dashboard.associations.freezeModal.subTitle", "Freeze")
        }`}
        onSubmit={() => {
          console.log("submit");
          setSelectedVME(false);
          freeze(selectedVME.id);
        }}
        handleClose={() => {
          setSelectedVME(false);
        }}
        body={
          selectedVME.is_frozen ? (
            <div>
              <Trans
                i18nKey="dashboard.associations.modifyModal.body"
                t={t}
                ns="common"
                values={{ alias: selectedVME.alias }}
              >
                All basic data of the VME <b>{{ alias: selectedVME.alias }}</b>{" "}
                will be customizable. Thus, the general information, bank
                information, the apartments and their distribution can be
                changed.
              </Trans>
            </div>
          ) : (
            <div>
              <Trans
                i18nKey="dashboard.associations.freezeModal.body"
                t={t}
                ns="common"
                values={{ alias: selectedVME.alias }}
              >
                All basic data of the VME <b>{{ alias: selectedVME.alias }}</b>{" "}
                will be frozen and can no longer be edited. Thus, the general
                information, bank information, the apartments and their
                distribution cannot be modified.
              </Trans>
            </div>
          )
        }
      />
      <Table
        columns={columns}
        data={VMEs}
        title={t("dashboard.associations.tableTitle", "VMEs")}
        enableSearch={true}
        enableSort={true}
        addPath="/app/association"
        renderRowSubComponent={renderRowSubComponent}
      />
    </>
  );
}
