import React from "react";
import { useTranslation } from "react-i18next";
import RemindersTable from "../../organisms/RemindersTable";
import colors from "@syntbeheer/assets/colors";

export default function Reminders() {
  const { t } = useTranslation("common");

  return (
    <div className="row">
      <div className="col">
        <h2 style={{ fontWeight: 700, color: colors.primary }}>
          {t("dashboard.reminders.title", "Reminders")}
        </h2>
        <RemindersTable
          title={t("dashboard.reminders.tableTitle", "Reminders")}
        />
      </div>
    </div>
  );
}
