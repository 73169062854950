// function to generate the structured communication
export const generateStructuredCommunication = (id, type) => {
  // Map provision types to corresponding numbers
  const typeMap = { w: 4, e: 1, g: 2, r: 3, s: 5 };
  // w -> working capital (provision)
  // e -> exceptional capital
  // g -> guarantee fund
  // r -> reserve
  // s -> settlement

  // Convert provision type to number
  const typeNumber = typeMap[type.toLowerCase()];

  // Create a 12-digit id + zeroPadding + typeNumber combination
  const baseNumber =
    "0".repeat(9 - id.toString().length) +
    id.toString() +
    typeNumber.toString();

  // Calculate the module 97 of the 12-digit number using BigInt
  let mod97 = (baseNumber % 97).toString().padStart(2, "0");

  // If mod97 is 0, set it to 97
  mod97 = mod97 === "00" ? "97" : mod97;

  return `+++${baseNumber.slice(0, 3)}/${baseNumber.slice(
    3,
    7
  )}/${baseNumber.slice(7)}${mod97}+++`;
};

export const decodeStructuredCommunication = (reference) => {
  // Remove the leading and trailing +++ characters
  const cleanReference = reference.replace(/\+/g, "");

  // Extract the base number (first 10 digits)
  const baseNumber = cleanReference.slice(0, 10);

  // Extract the mod97 check digits (last 2 digits)
  const mod97 = cleanReference.slice(10);

  // Extract the provision type number (last digit of baseNumber)
  const typeNumber = baseNumber.slice(-1);

  //INFO: id => Commissioner ID (id related to table Users)
  let id;
  if (parseInt(baseNumber[0]) !== 0) {
    id = parseInt(baseNumber.slice(0, 9).replace(/0+/g, ""), 10);
  } else {
    // Get the original ID (first 9 digits) by removing leading zeros
    const idWithPadding = baseNumber.slice(0, 9);
    id = parseInt(idWithPadding.replace(/^0+/, ""), 10); // Remove leading zeros but keep the rest
  }

  // Map the typeNumber back to its provision type
  const typeMapReverse = { 4: "w", 1: "e", 2: "g", 3: "r", 5: "s" }; // Reverse of the original type map
  const provisionType = typeMapReverse[typeNumber];

  // Manual calculation of modulo 97
  const calculateMod97 = (numStr) => {
    let remainder = 0;
    for (let i = 0; i < numStr.length; i++) {
      remainder = (remainder * 10 + parseInt(numStr[i], 10)) % 97;
    }
    return remainder;
  };

  // Validate mod97
  const calculatedMod97 = calculateMod97(baseNumber)
    .toString()
    .padStart(2, "0");
  const isValid =
    mod97 === calculatedMod97 || (calculatedMod97 === "00" && mod97 === "97");

  return {
    id: isNaN(id) ? null : id,
    type: provisionType,
    isValid: isValid,
  };
};
