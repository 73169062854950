import React from "react";
import PaymentIcon from "@mui/icons-material/Payment";
import TextLink from "./../../atoms/Buttons/TextLink";
import { initiatePayment } from "@syntbeheer/api/services/banking/index";

const PaymentButton = ({ ...props }) => {
  const [errorText, setErrorText] = React.useState("");

  const startPayment = () => {
    initiatePayment({ id: props.id, type: props.type }).then((response) => {
      console.log(response);
      if (response.success) {
        // banking successful
        props.onSuccess(response);
      }
      if (response.redirect) {
        // redirect: auth or payment
        window.location.href = response.redirect;
      } else if (response.error) {
        setErrorText("Payment initiation error: " + response.error);
      }
    });
  };
  return (
    <TextLink
      onClick={() => startPayment()}
      title={
        <>
          <PaymentIcon />
          {errorText}
        </>
      }
    />
  );
};

export default PaymentButton;
