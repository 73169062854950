import React from "react";
import Table from "./../../atoms/List/Table";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import {
  getNotifications,
  postReadNotification,
} from "@syntbeheer/api/services/notifications/index";
import IncidentIcon from "@mui/icons-material/Opacity";
import PersonIcon from "@mui/icons-material/AccountCircleRounded";
import AgendaIcon from "@mui/icons-material/ViewAgenda";
import CreateFolderIcon from "@mui/icons-material/CreateNewFolderRounded";
import ReminderIcon from "@mui/icons-material/NotificationsActive";
import BankSaldo from "./../../organisms/banking/Saldo";
import { withTranslation } from "react-i18next";
import colors from "@syntbeheer/assets/colors";

const columnsNotifications = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "",
    accessor: "title",
    Cell: ({ row, value }) => {
      return (
        <div
          className="btnify"
          onClick={() => {
            postReadNotification({ id: row.original.id });
            const path = row.original.path;
            if (path.includes("provision")) {
              window.location.href = "/app#Documents";
            } else if (path.includes("profile")) {
              window.location.href = "/app#Data";
            } else {
              window.location.href = row.original.path;
            }
          }}
          style={
            row.original.seen_at
              ? {}
              : { backgroundColor: "#ffcccc", color: "black" }
          }
        >
          <b>
            {value}{" "}
            <small>
              {new Date(row.original.createdAt).toLocaleDateString()}
              {" - "}
              {new Date(row.original.createdAt).toLocaleTimeString([], {
                hour: "numeric",
                minute: "2-digit",
              })}
            </small>
          </b>
          <br />
          {row.original.description}
        </div>
      );
    },
  },
];

class Overview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Notifications: [],
    };
  }
  componentDidMount() {
    getNotifications().then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({
          Notifications: response.Notifications.sort((a, b) =>
            a.createdAt > b.createdAt ? -1 : 1
          ),
        });
      }
    });
  }

  render() {
    const User = usersSelectors.get().User;
    const { t } = this.props;

    const hasAccessToCreate =
      User?.is_admin ||
      User?.UserVME?.type === "synt_authoriser" ||
      User?.UserVME?.type === "bookkeeper";

    return (
      <div className="row">
        <div className="col">
          <h2 style={{ fontWeight: 700, color: colors.primary }}>
            {t("dashboard.overview.welcome", "Welcome")}&nbsp;
            {User.first_name}
          </h2>
          <div className="row">
            <div className="col">
              <Table
                columns={[
                  {
                    Header: "",
                    accessor: "title",
                  },
                ]}
                data={[
                  {
                    title: (
                      <a href="/app/incident">
                        <IncidentIcon /> &nbsp;
                        {t("dashboard.overview.newIncident", "New incident")}
                      </a>
                    ),
                  },
                  {
                    title: (
                      <a href="/app/agenda">
                        <AgendaIcon /> &nbsp;
                        {t("dashboard.overview.newEvent", "New event")}
                      </a>
                    ),
                  },
                  ...(hasAccessToCreate
                    ? [
                        {
                          title: (
                            <a href="/app/document">
                              <CreateFolderIcon /> &nbsp;
                              {t(
                                "dashboard.overview.newDocuments",
                                "New document"
                              )}
                            </a>
                          ),
                        },

                        {
                          title: (
                            <a href="/app/User">
                              <PersonIcon /> &nbsp;
                              {t("dashboard.overview.newUser", "New user")}
                            </a>
                          ),
                        },
                        {
                          title: (
                            <a href="/app/reminders">
                              <ReminderIcon /> &nbsp;
                              {t(
                                "dashboard.overview.newReminder",
                                "New reminder"
                              )}
                            </a>
                          ),
                        },
                      ]
                    : []),
                ]}
                title={t("dashboard.overview.shortcuts", "Shortcuts")}
              />
              <BankSaldo />
            </div>
            <div className="col">
              <Table
                columns={columnsNotifications.map((N) => ({
                  ...N,
                  label: (
                    <div style={{ backgroundColor: colors.primary }}>
                      <b>{N.title}</b>
                      <br />
                      {N.description}
                    </div>
                  ),
                }))}
                data={this.state.Notifications}
                title={t(
                  "dashboard.overview.communication.title",
                  "Communication"
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Overview);
