export const INVALID = "<invalid>";

export function parse(value = "") {
  const [type, ...rest] = value.split(" ");
  if (type === "simple") {
    const [repeatType, ...values] = rest;

    if (repeatType === "one_time") {
      return {
        type,
        repeatType,
        date: values[0],
      };
    }

    if (repeatType === "repeat") {
      const [intervalWithCount, startingDate] = values;
      const [count, unit] = intervalWithCount.split("_");

      return {
        type,
        repeatType,
        count: count === INVALID ? INVALID : Number(count),
        unit,
        startingDate,
      };
    }

    if (repeatType === "before") {
      const [offsetWithCount, event] = values;
      const [count, unit] = offsetWithCount.split("_");

      return {
        type,
        repeatType,
        count: count === INVALID ? INVALID : Number(count),
        unit,
        event,
      };
    }

    return {
      type,
      repeatType: INVALID,
    };
  }

  return {
    type: INVALID,
  };
}

export function stringify(value) {
  if (value.type === "simple") {
    if (value.repeatType === "one_time") {
      return [value.type, value.repeatType, value.date ?? INVALID].join(" ");
    }
    if (value.repeatType === "repeat") {
      return [
        value.type,
        value.repeatType,
        [value.count ?? INVALID, value.unit ?? INVALID].join("_"),
        value.startingDate ?? INVALID,
      ].join(" ");
    }
    if (value.repeatType === "before") {
      return [
        value.type,
        value.repeatType,
        [value.count ?? INVALID, value.unit ?? INVALID].join("_"),
        value.event ?? INVALID,
      ].join(" ");
    }

    return [value.type, INVALID].join(" ");
  }

  return INVALID;
}
