import { Parser } from "json2csv";
import { downloadTextFile } from "./utils";

async function toCSV(columns, rows) {
  const json2csvParser = new Parser({
    fields: columns.map((column) => column.header),
  });

  const content = json2csvParser.parse(rows);

  return content;
}

async function downloadCSV(filename, data) {
  await downloadTextFile(filename + ".csv", data, "csv");
}

export const csvConverter = {
  name: "csv",
  stringify: toCSV,
  download: downloadCSV,
};
