import React from "react";
import Table from "../../atoms/List/Table";
import { getTransactions } from "@syntbeheer/api/services/banking/index";
import { attachTransactionsToTransactionables } from "@syntbeheer/api/services/transactions";
import { useTranslation } from "react-i18next";
import { decodeStructuredCommunication } from "../../../helpers/utils";
import { getAccountingOverview } from "@syntbeheer/api/services/accounting/index";
import EditIcon from "@mui/icons-material/EditOutlined";
import ModalView from "../Modal";
import { getProvisionColumns } from "../Provision";
import CheckBox from "../../atoms/Input/CheckBox";
import LoadingRotating from "../../atoms/LoadingRotating";
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
`;

const BankTransactions = React.forwardRef((props, ref) => {
  const [accounts, setAccounts] = React.useState([]);
  const [errorText, setErrorText] = React.useState(false);
  const [redirect, setRedirect] = React.useState(null);
  const [provisions, setProvisions] = React.useState([]);
  const [selectedTransactionId, setSelectedTransactionId] =
    React.useState(null);

  const [submiting, setSubmiting] = React.useState(false);

  const selectedTransaction = React.useMemo(
    () =>
      selectedTransactionId === null
        ? undefined
        : accounts
            .flatMap((a) => a.transactions)
            .find((tr) => tr.internalReference === selectedTransactionId),
    [accounts, selectedTransactionId]
  );

  const initialSelection = React.useMemo(
    () =>
      selectedTransaction
        ? Object.fromEntries(
            selectedTransaction.Provisions.map((p) => [p.id, true])
          )
        : {},
    [selectedTransaction]
  );

  const [selection, setSelection] = React.useState(initialSelection);

  React.useEffect(() => {
    setSelection(initialSelection);
  }, [initialSelection]);

  const { t } = useTranslation("common");

  React.useEffect(() => {
    getAccountingOverview()
      .then((response) => {
        setProvisions(response.Provisions);
      })
      .catch((e) => console.log(e));
  }, []);

  React.useImperativeHandle(ref, () => ({
    updateBanking() {
      updateBanking();
    },
  }));

  const updateBanking = React.useCallback(() => {
    setAccounts([]);
    setErrorText(false);

    getTransactions().then((response) => {
      console.log(response);
      if (response.success) {
        // banking successful
        setAccounts(response.Accounts);
      }
      if (response.redirect) {
        // redirect: auth or payment
        setRedirect(response.redirect);
        //window.location.href = response.redirect;
      } else if (response.error) {
        setErrorText(
          t(
            "dashboard.banking.transactions.bankLinkError",
            "Bank link error: "
          ) + JSON.stringify(response.error)
        );
      }
    });
  }, [t]);

  React.useEffect(() => {
    //INFO: popup / auto fetching
    updateBanking();
  }, [updateBanking]);

  const columnsTransactions = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t(
        "dashboard.banking.transactions.columns.counterpartName",
        "Beneficiary"
      ),
      accessor: "counterpartName",
    },
    {
      Header: t(
        "dashboard.banking.transactions.columns.executionDate",
        "Execution date"
      ),
      accessor: "executionDate",
      Cell: ({ row, value }) => {
        return new Date(value).toLocaleDateString();
      },
    },
    {
      Header: t("dashboard.banking.transactions.columns.amount", "Amount [€]"),
      accessor: "amount",
      Cell: ({ row, value }) => {
        return value
          .toFixed(2) // always two decimal digits
          .replace(".", ",");
      },
    },
    {
      Header: t(
        "dashboard.banking.transactions.columns.remittanceInformation",
        "Structured Reference"
      ),
      accessor: "remittanceInformation",
      Cell: ({ row, value }) => {
        const is_structured =
          row.original.remittanceInformationType === "structured" &&
          row.values.amount > 0;
        let info = { isValid: false, is_structured };
        if (is_structured) info = decodeStructuredCommunication(value);
        return info.isValid ? (
          <>
            {info.id} {info.type} {value}
          </>
        ) : (
          value
        );
      },
    },
    {
      Header: t("dashboard.banking.transactions.columns.linkedTo", "Linked to"),
      accessor: "linkedTo",
      Cell: ({ row }) => {
        return (
          <StyledContainer>
            <div style={{ flex: 1 }}>
              {row.original.Provisions.map((p, index, { length }) => (
                <React.Fragment key={p.id}>
                  <a href={`/app/provision/${p.id}`}>{p.reference}</a>
                  {length > 1 && index < length - 1 ? <span>, </span> : null}
                </React.Fragment>
              ))}
            </div>
            <div className="text-right">
              <div className="btn btn-link">
                <EditIcon
                  onClick={() => {
                    setSelectedTransactionId(row.original.internalReference);
                  }}
                />
              </div>
            </div>
          </StyledContainer>
        );
      },
    },
  ];

  return (
    <>
      {errorText && (
        <div className="alert alert-danger mt-5" role="alert">
          {errorText}
        </div>
      )}
      {accounts.map((account, index) => (
        <Table
          columns={columnsTransactions}
          data={account.transactions}
          title={
            t("dashboard.banking.transactions.table.title", "Transactions") +
            " " +
            account.reference
          }
          enableSearch={true}
          enableSort={true}
          key={"account" + index}
        />
      ))}
      <ModalView
        show={selectedTransactionId !== null}
        title={t(
          "dashboard.banking.transactions.modal.attach.title",
          "Attach provisions"
        )}
        submitTitle={
          <>
            {t(
              "dashboard.banking.transactions.modal.attach.submitTitle",
              "Save"
            )}
            {submiting ? <LoadingRotating loading={submiting} /> : null}
          </>
        }
        dialogClassName="max-width-none w-80"
        onSubmit={async () => {
          setSubmiting(true);
          try {
            await attachTransactionsToTransactionables({
              ProvisionIds: Object.keys(selection)
                .map((key) => Number(key))
                .filter((id) => selection[id]),
              bankAccountTransactionId: selectedTransactionId,
              bankAccountTransaction: selectedTransaction,
            });
            const response = await getTransactions();
            if (response.success) {
              // banking successful
              setAccounts(response.Accounts);
            }
          } catch {}

          setSelectedTransactionId(null);
          setSubmiting(false);
        }}
        handleClose={() => {
          setSelectedTransactionId(null);
        }}
        showCancelButton={true}
        showConfirmButton={true}
        body={
          <div>
            <ProvisionsSelectableTable
              data={provisions}
              selection={selection}
              setSelection={setSelection}
            />
          </div>
        }
      />
    </>
  );
});

function ProvisionsSelectableTable({ data, selection, setSelection }) {
  const { t } = useTranslation("common");

  const columns = [
    {
      Header: "",
      accessor: "checkbox",
      Cell: ({ row }) => {
        return (
          <CheckBox
            marginbottom={false}
            checked={!!selection[row.original.id]}
            onChange={() => {
              setSelection((prev) => ({
                ...prev,
                [row.original.id]: !prev[row.original.id],
              }));
            }}
          />
        );
      },
      showOnEmail: true,
    },
    ...getProvisionColumns({
      hasAccessToCreate: false,
      t,
    }),
  ];

  return <Table data={data} columns={columns} />;
}

export default BankTransactions;
