/* eslint-disable jsx-a11y/heading-has-content */
import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "./../../atoms/List/Table";
import { getSuppliers } from "@syntbeheer/api/services/suppliers/index";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import { withTranslation } from "react-i18next";

class Suppliers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Suppliers: [],
    };
  }
  componentDidMount() {
    getSuppliers().then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({ Suppliers: response.Suppliers });
      }
    });
  }
  render() {
    const User = usersSelectors.get().User;
    const { t } = this.props;

    const columns = [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: t("dashboard.suppliers.columns.name", "Name"),
        accessor: "name",
        Cell: ({ row, value }) =>
          !User.is_admin &&
          User.UserVME &&
          User.UserVME?.type !== "synt_authoriser" ? (
            <span>
              {row.original.alias} ({value})
            </span>
          ) : (
            <a href={`/app/supplier/${row.values.id}`}>
              {row.original.alias} ({value})
            </a>
          ),
      },
      {
        Header: t(
          "dashboard.suppliers.columns.companyNumber",
          "Company number"
        ),
        accessor: "vat_number",
      },
      {
        Header: t(
          "dashboard.suppliers.columns.notificationTypes",
          "Notification types"
        ),
        accessor: (row) =>
          row.ReportTypes.length
            ? row.ReportTypes.map((T) => T.name).join(", ") +
              " (" +
              row.ReportTypes.length +
              ")"
            : "",
      },
    ];
    return (
      <div className="row">
        <div className="col">
          <h2 style={{ fontWeight: 700, color: colors.primary }}></h2>

          <Table
            columns={columns}
            data={this.state.Suppliers.filter((S) => S.SupplierFiles?.length)}
            title={t(
              "dashboard.suppliers.contractual.title",
              "Contractual suppliers"
            )}
            description=""
            enableSearch={false}
            enableSort={false}
            addPath={
              !User.is_admin &&
              User.UserVME &&
              User.UserVME?.type !== "synt_authoriser"
                ? null
                : "/app/supplier"
            }
          />

          <Table
            columns={columns}
            data={this.state.Suppliers.filter((S) => !S.SupplierFiles?.length)}
            title={t(
              "dashboard.suppliers.nonContractual.title",
              "Non-contractual suppliers"
            )}
            description=""
            enableSearch={false}
            enableSort={false}
            addPath={
              !User.is_admin &&
              User.UserVME &&
              User.UserVME?.type !== "synt_authoriser"
                ? null
                : "/app/supplier"
            }
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Suppliers);
