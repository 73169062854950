import { Text, View } from "@react-pdf/renderer";
import { getMajorityLabelByValue } from "../../definitions/majority";
import { blobToBase64, pdfToBlob, styles } from "../organisms/PDF";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import { getCommissioners } from "@syntbeheer/api/services/lots/index";
import {
  getMeeting,
  sendEmailToUsersWhenMeetingCreated,
} from "@syntbeheer/api/services/meetings/index";

function formatMeetingItemDescription(description, Meeting) {
  return description
    .replace(/[^{{\}}]+(?=})/g, (match) => {
      const path = match.split(".");
      let res = Meeting;
      for (let i = 0; i < path.length; i++)
        res = path[i] in res ? res[path[i]] : null;
      return res;
    })
    .replace(/{{/g, "")
    .replace(/\}}/g, "");
}

export function generatePdfDocument({ Meeting, VME, t, Commissioners }) {
  if (Meeting?.type !== "general") {
    return null;
  }

  const document = {
    title: Meeting.name,
    subtitle: `${VME.alias} (${VME.name}), ${VME.address}, ${VME.vat_number}`,
    sections: [
      {
        title: t(
          "dashboard.meetings.columns.pdf.document.sections.information.title",
          "Information"
        ),
        text: t(
          "dashboard.meetings.columns.pdf.document.sections.information.text",
          "The {{type}} meeting ({{meetingName}}) of the Co-Owners Association, {{VMEAlias}}, will take place on {{startDate}}, {{startTime}} at {{meetingAddress}}. The description of this meeting is as follows: {{meetingDescription}}",
          {
            type:
              Meeting?.type === "general" &&
              t("dashboard.meetings.meetingType.general", "general"),
            meetingName: Meeting.name,
            VMEAlias: VME.alias,
            startDate: new Date(Meeting.starts_at).toLocaleDateString(),
            startTime: new Date(Meeting.starts_at).toLocaleString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            meetingAddress: Meeting.address,
            meetingDescription: Meeting.description,
          }
        ),
      },
      {
        title: "",
      },
      ...(Meeting.Users?.length > 0
        ? [
            {
              title: t(
                "dashboard.meetings.columns.pdf.document.sections.presence.title",
                "PRESENCE"
              ),
              content: Meeting.Users.map((item, index) => {
                return (
                  <Text style={styles.text} key={item.id}>
                    {`> ${index + 1}. ${item.full_name} ${
                      item.MeetingUser.is_confirmed_at
                        ? t(
                            "dashboard.meetings.columns.pdf.document.sections.presence.text.isConfirmed",
                            "(confirmed)"
                          )
                        : t(
                            "dashboard.meetings.columns.pdf.document.sections.presence.text.isNotConfirmed",
                            "(not confirmed)"
                          )
                    }${
                      item.MeetingUser.PowerOfAttorneyGivenToUser
                        ? `${"\n"}` +
                          t(
                            "dashboard.meetings.columns.pdf.document.sections.presence.text.representedBy",
                            " represented by: "
                          ) +
                          item.MeetingUser.PowerOfAttorneyGivenToUser
                            .full_name +
                          t(
                            "dashboard.meetings.columns.pdf.document.sections.presence.text.coResident",
                            " (co-resident)"
                          )
                        : item.MeetingUser.power_of_attorney_given_to_first_name
                        ? `${"\n"}` +
                          t(
                            "dashboard.meetings.columns.pdf.document.sections.presence.text.representedBy",
                            " represented by: "
                          ) +
                          item.MeetingUser
                            .power_of_attorney_given_to_first_name +
                          " " +
                          item.MeetingUser
                            .power_of_attorney_given_to_last_name +
                          t(
                            "dashboard.meetings.columns.pdf.document.sections.presence.text.external",
                            " (external)"
                          )
                        : ""
                    } (${
                      new Date(
                        item.MeetingUser.createdAt
                      ).toLocaleDateString() +
                      ", " +
                      new Date(item.MeetingUser.createdAt).toLocaleString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    })`}
                    {"\n"}
                    {"\n"}
                  </Text>
                );
              }),
            },
          ]
        : [
            {
              title: t(
                "dashboard.meetings.columns.pdf.document.sections.invitations.title",
                "UITGENODIGDEN"
              ),
              content: Commissioners.map((item, index) => {
                return (
                  <Text style={styles.text} key={item.id}>
                    {`> ${index + 1}. ${item.full_name} ${
                      item?.Company?.id ? `(${item.Company.name})` : ""
                    }`}
                    {"\n"}
                  </Text>
                );
              }),
            },
          ]),
      {
        title: t(
          "dashboard.meetings.columns.pdf.document.sections.calendarItems.title",
          "CALENDAR ITEMS"
        ),
      },
      ...Meeting.MeetingItems.map((item, index) => {
        return {
          title: `> ${index + 1}. ${item.name}`,
          textContainerStyle: {
            backgroundColor: item.majority
              ? item.passed
                ? "#d5f2c9"
                : "#f5c0bc"
              : "#0036361a",
            border: "1px lightgray solid",
            borderRadius: 6,
          },
          content: (
            <View>
              {!!item?.description && (
                <View style={{ marginBottom: 12 }}>
                  <Text style={[styles.text]}>
                    {formatMeetingItemDescription(item.description, Meeting)}
                  </Text>
                </View>
              )}
              {item.majority &&
                item.majority !== "" &&
                item.is_vote_required && (
                  <View>
                    <Text style={[styles.text, { marginBottom: 3 }]}>
                      <Text style={{ fontWeight: 500 }}>
                        {t(
                          "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.requiredForApproval",
                          "Required for approval: "
                        )}{" "}
                      </Text>
                      {getMajorityLabelByValue(t, item.majority)}
                    </Text>
                    {(item.Users || []).length > 0 ? (
                      <View style={{ marginBottom: 12 }}>
                        <Text>{"\n"}</Text>
                        <Text style={[styles.text, { fontWeight: 500 }]}>
                          {t(
                            "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.vote",
                            "Vote: "
                          )}
                          {"\n"}
                        </Text>
                        {item.Users.map((user) => (
                          <Text
                            style={[
                              styles.text,
                              { marginTop: 0, marginBottom: 0 },
                            ]}
                            key={user.id}
                          >
                            - {user.full_name}:{" "}
                            {user.MeetingItemUser.is_pro === true
                              ? t(
                                  "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.for",
                                  "For"
                                )
                              : user.MeetingItemUser.is_pro === false
                              ? t(
                                  "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.against",
                                  "Against"
                                )
                              : t(
                                  "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.withhold",
                                  "Withhold"
                                )}{" "}
                            (
                            {Commissioners.reduce((acc, C) => {
                              if (user.id === C.id) {
                                return (acc = C.representing_shares);
                              }
                              return acc;
                            }, 0)}
                            )
                          </Text>
                        ))}

                        <Text style={[styles.text, { fontWeight: 500 }]}>
                          {t(
                            "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.result",
                            "Result: "
                          )}{" "}
                          {item.passed
                            ? t(
                                "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.approved",
                                "Approved"
                              )
                            : t(
                                "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.disapproved",
                                "Disapproved"
                              )}{" "}
                          ({item.total_pro}/{item.total_shares})
                        </Text>
                      </View>
                    ) : (
                      new Date(Meeting.starts_a) < new Date() && (
                        <View style={{ marginBottom: 12 }}>
                          {Commissioners.map((item, index) => {
                            return (
                              <View>
                                <Text style={styles.text} key={item.id}>
                                  {`> ${index + 1}. ${item.full_name} ${
                                    item?.Company?.id
                                      ? `(${item.Company.name})`
                                      : ""
                                  }`}
                                  {" | "}
                                  {t(
                                    "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.vote",
                                    "Vote: "
                                  ) +
                                    "(" +
                                    item.representing_shares +
                                    ")"}
                                  {"\n"}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      )
                    )}
                  </View>
                )}

              {!!item.decision && (
                <View>
                  <Text style={[styles.text, { fontWeight: 500 }]}>
                    {t(
                      "dashboard.meetings.columns.pdf.document.sections.calendarItems.text.decision",
                      "Decision: "
                    )}
                  </Text>
                  <Text style={styles.text}>{item.decision}</Text>
                </View>
              )}

              <Text>{"\n"}</Text>
            </View>
          ),
        };
      }),
    ],
  };

  return document;
}

export async function sendEmails(response, t, pdfFileName) {
  if (response.Meeting && response.job === "created") {
    try {
      const VME = vmesSelectors.get().VME || {};

      const Commissioners = await getCommissioners().then((response) => {
        if (response.success) {
          return response.Commissioners;
        }
        return null;
      });

      const createdMeeting = await getMeeting(response.Meeting.id);

      const document = generatePdfDocument({
        Meeting: createdMeeting.Meeting,
        VME,
        t,
        Commissioners,
      });

      const blob = await pdfToBlob(document);
      const base64 = await blobToBase64(blob);

      console.log({ pdfFileName });

      await sendEmailToUsersWhenMeetingCreated(createdMeeting.Meeting.id, {
        pdfFileContent: base64.split(",")[1],
        pdfFileName,
      });
    } catch (error) {
      console.error(error);
    }
  }
}
