import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { emptyCell, Table } from "./shared/Table";
import { formatDate, formatNumber } from "./shared/utils";
import { Line } from "./shared/Line";
import { getSuppliers } from "@syntbeheer/api/services/documents";
import { useTranslation } from "react-i18next";

//-----------------------------------------------------------------------------

export async function getSuppliersData({ t, vmeId, startDate, endDate }) {
  const data = await getSuppliers({ vmeId, startDate, endDate });

  const suppliers = data.purchases.reduce((res, p) => {
    res[p.Supplier.id] = res[p.Supplier.id] ?? [];
    res[p.Supplier.id].push(p);

    return res;
  }, {});

  return {
    building:
      data.vme.alias +
      (data.vme.vat_number ? " (" + data.vme.vat_number + ")" : ""),
    period: `${formatDate(data.start_date ?? startDate)} - ${formatDate(
      data.end_date ?? endDate
    )}`,
    dateTime: formatDate(new Date()),

    suppliers: Object.values(suppliers).map((ps) => {
      const supplier = ps.at(0).Supplier;

      return {
        name: supplier.alias || supplier.Company?.name || "",
        number: supplier.Company?.vat_number || t("NVT", "NVT"),
        calculateBalance: true,
        items: ps.flatMap((p) => {
          return [
            {
              date: formatDate(p.invoice_date),
              reference: p.reference,
              description: p.description,
              debit: 0,
              credit: p.total_amount,
            },
            ...(p.paid_at
              ? [
                  {
                    date: formatDate(p.paid_at),
                    reference: "B" + p.reference,
                    description: p.description,
                    debit: p.total_amount,
                    credit: 0,
                  },
                ]
              : []),
          ];
        }),
      };
    }),
  };
}
//-----------------------------------------------------------------------------
const font = {
  normal: "Poppins",
  bold: "Poppins",
};
//-----------------------------------------------------------------------------

const styles = StyleSheet.create({
  body: {
    padding: "30 30 30 30",
    fontSize: 8,
    fontFamily: font.normal,
  },
  title: {
    fontSize: 9,
    color: "#c01d21",
  },
  pageNumber: {
    fontSize: 9,
    fontFamily: font.bold,
    fontWeight: 700,
    alignItems: "flex-end",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    fontFamily: font.bold,
    fontWeight: 700,
  },
  tableHeader: {
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid black",
    padding: 4,
    fontFamily: font.bold,
    fontWeight: 700,
    marginBottom: 5,
    backgroundColor: "#0036361a",
  },
});

const columnWidthsRatio = [
  2, // supplier
  1, // date
  2, // reference
  3, // description
  1, // debit
  1, // credit
];

const totalRatio = columnWidthsRatio.reduce((s, r) => s + r, 0);

// in %
const columnWidths = columnWidthsRatio.map((r) => (r / totalRatio) * 100);

export function Suppliers({ data }) {
  const { t } = useTranslation("common");

  const suppliers = data.suppliers.map((supplier) => {
    const debit = supplier.items.reduce((s, i) => s + i.debit, 0);
    const credit = supplier.items.reduce((s, i) => s + i.credit, 0);

    return {
      ...supplier,
      debit,
      credit,
    };
  });

  const debit = suppliers.reduce((d, s) => d + s.debit, 0);
  const credit = suppliers.reduce((c, s) => c + s.credit, 0);

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View fixed>
          <View style={styles.header}>
            <View style={styles.title}>
              <Text>{t("RC Suppliers", "RC Suppliers")}</Text>
              <Text>{data.building}</Text>
              <Text>{data.period}</Text>
            </View>
            <View style={styles.pageNumber}>
              <Text>{data.dateTime}</Text>
              <PageNumber />
            </View>
          </View>
          <View style={styles.tableHeader}>
            <View style={{ width: columnWidths[0] + "%" }}>
              <Text>{t("Supplier", "Supplier")}</Text>
            </View>
            <View style={{ width: columnWidths[1] + "%" }}>
              <Text>{t("Date", "Date")}</Text>
            </View>
            <View style={{ width: columnWidths[2] + "%" }}>
              <Text>{t("Reference", "Reference")}</Text>
            </View>
            <View style={{ width: columnWidths[3] + "%" }}>
              <Text>{t("Description", "Description")}</Text>
            </View>
            <View style={{ width: columnWidths[4] + "%", textAlign: "right" }}>
              <Text>{t("Debit", "Debit")}</Text>
            </View>
            <View style={{ width: columnWidths[5] + "%", textAlign: "right" }}>
              <Text>{t("Credit", "Credit")}</Text>
            </View>
          </View>
        </View>
        {suppliers.map((supplier, index) => {
          return (
            <View key={index}>
              <Text
                style={{ fontFamily: font.bold, fontWeight: 700, padding: 4 }}
              >
                {supplier.alias || supplier.name} ({supplier.number})
              </Text>
              <Table
                data={[
                  ...supplier.items.map((item) => ({
                    cells: [
                      emptyCell,
                      {
                        value: <Text>{item.date}</Text>,
                      },
                      {
                        value: <Text>{item.reference}</Text>,
                      },
                      {
                        value: <Text>{item.description}</Text>,
                      },
                      {
                        value: <Text>{formatNumber(item.debit)}</Text>,
                      },
                      {
                        value: <Text>{formatNumber(item.credit)}</Text>,
                      },
                    ],
                  })),
                  {
                    cells: [
                      emptyCell,
                      {
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                      {
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                      {
                        value: (
                          <Text>
                            {t("Total", "Total")} {supplier.name}
                          </Text>
                        ),
                        style: {
                          borderTop: "1px solid black",
                          textAlign: "right",
                        },
                      },
                      {
                        value: <Text>{formatNumber(supplier.debit)}</Text>,
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                      {
                        value: <Text>{formatNumber(supplier.credit)}</Text>,
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                    ],
                  },
                  ...(supplier.calculateBalance
                    ? [
                        {
                          cells: [
                            emptyCell,
                            emptyCell,
                            emptyCell,
                            {
                              value: (
                                <Text>
                                  {t("Balance", "Balance")} {supplier.name}
                                </Text>
                              ),
                              style: {
                                textAlign: "right",
                              },
                            },
                            {
                              value:
                                supplier.debit > supplier.credit ? (
                                  <Text>
                                    {formatNumber(
                                      supplier.debit - supplier.credit
                                    )}
                                  </Text>
                                ) : null,
                            },
                            {
                              value:
                                supplier.credit > supplier.debit ? (
                                  <Text>
                                    {formatNumber(
                                      supplier.credit - supplier.debit
                                    )}
                                  </Text>
                                ) : null,
                            },
                          ],
                          style: {
                            fontFamily: font.bold,
                            fontWeight: 700,
                          },
                        },
                      ]
                    : []),
                ]}
                columns={[
                  { style: { width: columnWidths[0] + "%" } },
                  { style: { width: columnWidths[1] + "%" } },
                  { style: { width: columnWidths[2] + "%" } },
                  { style: { width: columnWidths[3] + "%" } },
                  {
                    style: { width: columnWidths[4] + "%", textAlign: "right" },
                  },
                  {
                    style: { width: columnWidths[5] + "%", textAlign: "right" },
                  },
                ]}
              />
            </View>
          );
        })}
        <Line />
        <Line />
        <Table
          data={[
            {
              cells: [
                emptyCell,
                emptyCell,
                emptyCell,
                {
                  value: <Text>{t("Total", "Total")}</Text>,
                  style: {
                    textAlign: "right",
                  },
                },
                {
                  value: <Text>{formatNumber(debit)}</Text>,
                },
                {
                  value: <Text>{formatNumber(credit)}</Text>,
                },
              ],
            },
            {
              cells: [
                emptyCell,
                emptyCell,
                emptyCell,
                {
                  value: <Text>{t("Balance", "Balance")}</Text>,
                },
                {
                  value:
                    debit > credit ? (
                      <Text>{formatNumber(debit - credit)}</Text>
                    ) : null,
                },
                {
                  value:
                    credit > debit ? (
                      <Text>{formatNumber(credit - debit)}</Text>
                    ) : null,
                },
              ],
              style: {
                fontFamily: font.bold,
                fontWeight: 700,
                textAlign: "right",
              },
            },
          ]}
          columns={[
            { style: { width: columnWidths[0] + "%" } },
            { style: { width: columnWidths[1] + "%" } },
            { style: { width: columnWidths[2] + "%" } },
            { style: { width: columnWidths[3] + "%" } },
            {
              style: { width: columnWidths[4] + "%", textAlign: "right" },
            },
            {
              style: { width: columnWidths[5] + "%", textAlign: "right" },
            },
          ]}
        />
      </Page>
    </Document>
  );
}

//-----------------------------------------------------------------------------
function PageNumber() {
  const { t } = useTranslation("common");

  return (
    <Text
      render={({ pageNumber, totalPages }) =>
        `${t("Page", "Page")} ${pageNumber} / ${totalPages}`
      }
    />
  );
}
//-----------------------------------------------------------------------------

export const exampleData = {
  building: "VME.alias (0851.873.596)",
  period: "2020 (01/01/2020 - 31/12/2020)",
  invoice: "General ledger account",
  dateTime: "31/07/2022",

  suppliers: [
    {
      name: "Davy Van Aken bvba",
      number: 3123,
      calculateBalance: false,
      items: [
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
      ],
    },
    {
      name: "Davy Van Aken bvba",
      number: 3123,
      calculateBalance: true,
      items: [
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
      ],
    },
    {
      name: "Davy Van Aken bvba",
      number: 3123,
      calculateBalance: false,
      items: [
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
      ],
    },
    {
      name: "Davy Van Aken bvba",
      number: 3123,
      calculateBalance: true,
      items: [
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
      ],
    },
    {
      name: "Davy Van Aken bvba",
      number: 3123,
      calculateBalance: false,
      items: [
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
      ],
    },
    {
      name: "Davy Van Aken bvba",
      number: 3123,
      calculateBalance: true,
      items: [
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
      ],
    },
    {
      name: "Davy Van Aken bvba",
      number: 3123,
      calculateBalance: false,
      items: [
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
      ],
    },
    {
      name: "Davy Van Aken bvba",
      number: 3123,
      calculateBalance: true,
      items: [
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
        {
          date: "01/01/2020",
          reference: "",
          description: "Balance last financial year",
          debit: 0,
          credit: 3953.48,
        },
        {
          date: "14/01/2020",
          reference: "B1",
          description: "2020/00005 IF 2019/00129 Davy Van Aken bvba",
          debit: 735.07,
          credit: 0,
        },
      ],
    },
  ],
};
