import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { Table } from "./shared/Table";
import { formatDate, formatDateTime, formatNumber } from "./shared/utils";
import { useTranslation } from "react-i18next";
import { fetchApi } from "@syntbeheer/api/fetch";

//-----------------------------------------------------------------------------
export async function getDiaryPurchasesData({ t, vmeId, startDate, endDate }) {
  const data = await fetchApi(
    "/documents/purchases/" +
      vmeId +
      "/" +
      new Date(startDate).toISOString() +
      "/" +
      new Date(endDate).toISOString(),
    "get"
  );

  return {
    vme:
      data.vme.alias +
      (data.vme.vat_number ? " (" + data.vme.vat_number + ")" : ""),
    address: data.vme?.Company?.address || "",
    diaryType: "PURCHASES",
    diaryCompany: data.vme.Company.name,
    diaryCompanyType: "all",
    registrationDateFrom: formatDate(data.start_date),
    registrationDateTo: formatDate(data.end_date),
    detailedCetralization: false,
    documentNumber: "all",
    dateTime: formatDateTime(new Date()),
    currency: "EURO",

    documents: data.purchases.map((p, i) => {
      return {
        number: i + 1,
        date: formatDate(p.invoice_date),
        reference: p.reference,
        dueDate: formatDate(p.due_date),
        supplier: p.Supplier.alias ?? p.Supplier.Company.name,
        supplierAddress: p.Supplier?.Company?.address || "",
        vat: p.vat_amount ?? 0,
        items: p.PurchaseEntries.filter(
          (PE) => !!PE.GeneralLedgerAccountId
        ).map((PE) => PE.GeneralLedgerAccount.name),
        description: p.description,
        debit: p.total_amount,
        credit: 0,
      };
    }),
  };
}
//-----------------------------------------------------------------------------
const font = {
  normal: "Poppins",
  bold: "Poppins",
};
//-----------------------------------------------------------------------------

const styles = StyleSheet.create({
  body: {
    padding: "30 30 30 30",
    fontSize: 8,
    fontFamily: font.normal,
  },
  title: {
    fontSize: 9,
    color: "#c01d21",
  },
  pageNumber: {
    fontSize: 9,
    fontFamily: font.bold,
    fontWeight: 700,
    alignItems: "flex-end",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    fontFamily: font.bold,
    fontWeight: 700,
  },
  tableHeader: {
    flexDirection: "row",
    width: "100%",
    border: "2px solid black",
    padding: 4,
    fontFamily: font.bold,
    fontWeight: 700,
    backgroundColor: "#0036361a",
    marginBottom: 5,
  },
});

const columnWidthsRatio = [
  9, // doc.no.
  9, // verv.dat.
  20, // supplier
  4, // vat
  20, // grand book
  20, // description
  9, // debit
  9, // credit
];

const totalRatio = columnWidthsRatio.reduce((s, r) => s + r, 0);

// in %
const columnWidths = columnWidthsRatio.map((r) => (r / totalRatio) * 100);

export function DiaryPurchase({ data }) {
  const { t } = useTranslation("common");

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.body}>
        <View fixed>
          <View style={styles.header}>
            <View style={styles.title}>
              <Text>{data.vme}</Text>
              <Text>{data.address}</Text>
            </View>
            <View style={{ alignItems: "center" }}>
              <Text>
                {t("DIARY", "DIARY")}: {data.diaryType}
              </Text>
              <View style={{ fontFamily: font.normal, alignItems: "center" }}>
                <Text>
                  {t("Diary", "Diary")} : {data.diaryCompany} :{" "}
                  {data.diaryCompanyType}
                </Text>
                <Text>
                  {t("Registration date : from {{from}} to {{to}}", {
                    defaultValu: "Registration date : from {{from}} to {{to}}",
                    from: data.registrationDateFrom,
                    to: data.registrationDateTo,
                  })}{" "}
                  {t("Detailed centralization", "Detailed centralization")} :{" "}
                  {data.detailedCetralization ? t("yes", "yes") : t("no", "no")}
                </Text>
                <Text>
                  {t("Document number", "Document number")} :{" "}
                  {data.documentNumber}
                </Text>
              </View>
            </View>
            <View style={styles.pageNumber}>
              <Text>
                {t("Issue", "Issue")}: {data.dateTime}
              </Text>
              <PageNumber />
              <Text>
                {t("Currency", "Currency")}: {data.currency}
              </Text>
            </View>
          </View>
          <View style={styles.tableHeader}>
            <View style={{ width: columnWidths[0] + "%" }}>
              <Text>{t("DOC.NO.", "DOC.NO.")}</Text>
              <Text>{t("DOC.DAT.", "DOC.DAT.")}</Text>
              <Text>{t("REFERENCE", "REFERENCE")}</Text>
            </View>
            <View style={{ width: columnWidths[1] + "%" }}>
              <Text>{t("VERV.DAT.", "VERV.DAT.")}</Text>
            </View>
            <View style={{ width: columnWidths[2] + "%" }}>
              <Text>{t("SUPPLIER", "SUPPLIER")}</Text>
            </View>
            <View style={{ width: columnWidths[3] + "%" }}>
              <Text>{t("VAT", "VAT")}</Text>
            </View>
            <View style={{ width: columnWidths[4] + "%" }}>
              <Text>{t("GENERAL LEDGER", "GENERAL LEDGER")}</Text>
            </View>
            <View style={{ width: columnWidths[5] + "%" }}>
              <Text>{t("DESCRIPTION", "DESCRIPTION")}</Text>
            </View>
            <View style={{ width: columnWidths[6] + "%", textAlign: "right" }}>
              <Text>{t("DEBIT", "DEBIT")}</Text>
            </View>
            <View style={{ width: columnWidths[7] + "%", textAlign: "right" }}>
              <Text>{t("CREDIT", "CREDIT")}</Text>
            </View>
          </View>
        </View>
        <Table
          data={data.documents.map((doc) => ({
            cells: [
              {
                value: (
                  <>
                    <Text>{doc.number}</Text>
                    <Text>{doc.date}</Text>
                    <Text>{doc.reference}</Text>
                  </>
                ),
              },
              {
                value: <Text>{doc.dueDate}</Text>,
              },
              {
                value: (
                  <>
                    <Text>{doc.supplier}</Text>
                    <Text>{doc.supplierAddress}</Text>
                  </>
                ),
              },
              {
                value: <Text>{doc.vat}</Text>,
              },
              {
                value: (
                  <>
                    {doc.items.map((item, index) => (
                      <Text key={index}>{item}</Text>
                    ))}
                  </>
                ),
              },
              {
                value: <Text>{doc.description}</Text>,
              },
              {
                value: <Text>{formatNumber(doc.debit)}</Text>,
              },
              {
                value: <Text>{formatNumber(doc.credit)}</Text>,
              },
            ],
            style: {
              borderBottom: "1px solid black",
            },
          }))}
          columns={[
            { style: { width: columnWidths[0] + "%" } },
            { style: { width: columnWidths[1] + "%" } },
            { style: { width: columnWidths[2] + "%" } },
            { style: { width: columnWidths[3] + "%" } },
            { style: { width: columnWidths[4] + "%" } },
            { style: { width: columnWidths[5] + "%" } },
            {
              style: {
                width: columnWidths[6] + "%",
                textAlign: "right",
              },
            },
            {
              style: {
                width: columnWidths[7] + "%",
                justifyContent: "center",
                textAlign: "right",
              },
            },
          ]}
        />
      </Page>
    </Document>
  );
}

//-----------------------------------------------------------------------------
function PageNumber() {
  const { t } = useTranslation("common");

  return (
    <Text
      render={({ pageNumber, totalPages }) =>
        t("Page {{pageNumber}} of {{totalPages}}", {
          defaultValue: "Page {{pageNumber}} of {{totalPages}}",
          pageNumber,
          totalPages,
        })
      }
    />
  );
}
//-----------------------------------------------------------------------------

export const exampleData = {
  vme: "003 - VME ALFEN",
  address: "Church Street 42 - 46\n1742 Sint-Katherina-lombeek",
  diaryType: "PURCHASES",
  diaryCompany: "AF1 Purchase Invoices Company",
  diaryCompanyType: "all",
  registrationDateFrom: "01/06/2021",
  registrationDateTo: "31/05/2022",
  detailedCetralization: false,
  documentNumber: "all",
  dateTime: "31/07/2022 15:10:49",
  currency: "EURO",

  documents: [
    {
      number: 1,
      date: "30/06/2021",
      reference: "01",
      dueDate: "30/06/2021",
      supplier: "Ligis management",
      supplierAddress: "",
      vat: 0,
      items: ["613000 FEES SYNDICIA", "440000 PAYABLE SUPPLIERS"],
      description: "bundle invoice ligis counter booking",
      debit: 3750,
      credit: 3750,
    },
    {
      number: 2,
      date: "01/08/2021",
      reference: "+++110/7777/19746+++",
      dueDate: "16/08/2021",
      supplier: "FARYS",
      supplierAddress: "Stropstraat 1\n9000 Ghent",
      vat: 2.32,
      items: ["612010 WATER GENERAL PARTS", "440000 PAYABLE SUPPLIERS"],
      description: "08/04/2021 - 07/04/2022 counter booking",
      debit: 41,
      credit: 41,
    },
    {
      number: 1,
      date: "30/06/2021",
      reference: "01",
      dueDate: "30/06/2021",
      supplier: "Ligis management",
      supplierAddress: "",
      vat: 0,
      items: ["613000 FEES SYNDICIA", "440000 PAYABLE SUPPLIERS"],
      description: "bundle invoice ligis counter booking",
      debit: 3750,
      credit: 3750,
    },
    {
      number: 2,
      date: "01/08/2021",
      reference: "+++110/7777/19746+++",
      dueDate: "16/08/2021",
      supplier: "FARYS",
      supplierAddress: "Stropstraat 1\n9000 Ghent",
      vat: 2.32,
      items: ["612010 WATER GENERAL PARTS", "440000 PAYABLE SUPPLIERS"],
      description: "08/04/2021 - 07/04/2022 counter booking",
      debit: 41,
      credit: 41,
    },
    {
      number: 1,
      date: "30/06/2021",
      reference: "01",
      dueDate: "30/06/2021",
      supplier: "Ligis management",
      supplierAddress: "",
      vat: 0,
      items: ["613000 FEES SYNDICIA", "440000 PAYABLE SUPPLIERS"],
      description: "bundle invoice ligis counter booking",
      debit: 3750,
      credit: 3750,
    },
    {
      number: 2,
      date: "01/08/2021",
      reference: "+++110/7777/19746+++",
      dueDate: "16/08/2021",
      supplier: "FARYS",
      supplierAddress: "Stropstraat 1\n9000 Ghent",
      vat: 2.32,
      items: ["612010 WATER GENERAL PARTS", "440000 PAYABLE SUPPLIERS"],
      description: "08/04/2021 - 07/04/2022 counter booking",
      debit: 41,
      credit: 41,
    },
    {
      number: 1,
      date: "30/06/2021",
      reference: "01",
      dueDate: "30/06/2021",
      supplier: "Ligis management",
      supplierAddress: "",
      vat: 0,
      items: ["613000 FEES SYNDICIA", "440000 PAYABLE SUPPLIERS"],
      description: "bundle invoice ligis counter booking",
      debit: 3750,
      credit: 3750,
    },
    {
      number: 2,
      date: "01/08/2021",
      reference: "+++110/7777/19746+++",
      dueDate: "16/08/2021",
      supplier: "FARYS",
      supplierAddress: "Stropstraat 1\n9000 Ghent",
      vat: 2.32,
      items: ["612010 WATER GENERAL PARTS", "440000 PAYABLE SUPPLIERS"],
      description: "08/04/2021 - 07/04/2022 counter booking",
      debit: 41,
      credit: 41,
    },
    {
      number: 1,
      date: "30/06/2021",
      reference: "01",
      dueDate: "30/06/2021",
      supplier: "Ligis management",
      supplierAddress: "",
      vat: 0,
      items: ["613000 FEES SYNDICIA", "440000 PAYABLE SUPPLIERS"],
      description: "bundle invoice ligis counter booking",
      debit: 3750,
      credit: 3750,
    },
    {
      number: 2,
      date: "01/08/2021",
      reference: "+++110/7777/19746+++",
      dueDate: "16/08/2021",
      supplier: "FARYS",
      supplierAddress: "Stropstraat 1\n9000 Ghent",
      vat: 2.32,
      items: ["612010 WATER GENERAL PARTS", "440000 PAYABLE SUPPLIERS"],
      description: "08/04/2021 - 07/04/2022 counter booking",
      debit: 41,
      credit: 41,
    },
    {
      number: 1,
      date: "30/06/2021",
      reference: "01",
      dueDate: "30/06/2021",
      supplier: "Ligis management",
      supplierAddress: "",
      vat: 0,
      items: ["613000 FEES SYNDICIA", "440000 PAYABLE SUPPLIERS"],
      description: "bundle invoice ligis counter booking",
      debit: 3750,
      credit: 3750,
    },
    {
      number: 2,
      date: "01/08/2021",
      reference: "+++110/7777/19746+++",
      dueDate: "16/08/2021",
      supplier: "FARYS",
      supplierAddress: "Stropstraat 1\n9000 Ghent",
      vat: 2.32,
      items: ["612010 WATER GENERAL PARTS", "440000 PAYABLE SUPPLIERS"],
      description: "08/04/2021 - 07/04/2022 counter booking",
      debit: 41,
      credit: 41,
    },
    {
      number: 1,
      date: "30/06/2021",
      reference: "01",
      dueDate: "30/06/2021",
      supplier: "Ligis management",
      supplierAddress: "",
      vat: 0,
      items: ["613000 FEES SYNDICIA", "440000 PAYABLE SUPPLIERS"],
      description: "bundle invoice ligis counter booking",
      debit: 3750,
      credit: 3750,
    },
    {
      number: 2,
      date: "01/08/2021",
      reference: "+++110/7777/19746+++",
      dueDate: "16/08/2021",
      supplier: "FARYS",
      supplierAddress: "Stropstraat 1\n9000 Ghent",
      vat: 2.32,
      items: ["612010 WATER GENERAL PARTS", "440000 PAYABLE SUPPLIERS"],
      description: "08/04/2021 - 07/04/2022 counter booking",
      debit: 41,
      credit: 41,
    },
    {
      number: 1,
      date: "30/06/2021",
      reference: "01",
      dueDate: "30/06/2021",
      supplier: "Ligis management",
      supplierAddress: "",
      vat: 0,
      items: ["613000 FEES SYNDICIA", "440000 PAYABLE SUPPLIERS"],
      description: "bundle invoice ligis counter booking",
      debit: 3750,
      credit: 3750,
    },
    {
      number: 2,
      date: "01/08/2021",
      reference: "+++110/7777/19746+++",
      dueDate: "16/08/2021",
      supplier: "FARYS",
      supplierAddress: "Stropstraat 1\n9000 Ghent",
      vat: 2.32,
      items: ["612010 WATER GENERAL PARTS", "440000 PAYABLE SUPPLIERS"],
      description: "08/04/2021 - 07/04/2022 counter booking",
      debit: 41,
      credit: 41,
    },
  ],
};
