import React from "react";
import { useTranslation } from "react-i18next";

import Table from "../../atoms/List/Table";
import Modal from "../Modal";
import Button from "../../atoms/Buttons/Button";

export function EmailModal({
  data,
  columns,
  modalVisible,
  onCloseModal,
  tableTitle,
  renderDataFilterComponent,
  dataFilter = (data, filters) => data,
  onSubmit = () => {},
  onDisableAll = () => {},
}) {
  const { t } = useTranslation();
  const [filters, setFilters] = React.useState({});

  const filteredData = dataFilter(data, filters);

  return (
    <Modal
      show={modalVisible}
      title={t("Send Reminders", "Send Reminders")}
      submitTitle={t("Send", "Send")}
      onSubmit={() => {
        onSubmit(filteredData);
      }}
      handleClose={() => {
        onCloseModal();
      }}
      dialogClassName="max-width-none w-80"
      body={
        <>
          {renderDataFilterComponent &&
            renderDataFilterComponent({ filters, setFilters })}
          <Button
            buttontype="btn-simple"
            title={t("Deselect All", "Deselect All")}
            onClick={onDisableAll}
          />
          <Table columns={columns} data={filteredData} title={tableTitle} />
          <div className="alert alert-info m-5" role="alert">
            {t(
              "components.EmailModal",
              "You are going to send out immediate reminders manually. Make sure you are not sending out reminders that have already been sent out, or unnecessary reminders."
            )}
          </div>
        </>
      }
    />
  );
}
