import React from "react";
import DashboardHeader from "./../../organisms/DashboardHeader";
import DashboardMenu from "./../../organisms/DashboardMenu";

export default class DashboardView extends React.Component {
  render() {
    return (
      <>
        <DashboardHeader title={this.props.title} />
        <div className="container-fluid">
          <div className="row" style={{ minHeight: "100vh" }}>
            <DashboardMenu activeKey={this.props.activeKey} />
            <main role="main" className="col p-4">
              {this.props.children}
            </main>
          </div>
        </div>
      </>
    );
  }
}
