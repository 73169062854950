export async function extractData(data, columns, selectedColumns) {
  const columnNames = columns
    .map((column) => ({
      key:
        typeof column.accessor === "function" ? column.Header : column.accessor,
      header: column.Header,
    }))
    .filter(({ key }) => selectedColumns[key]);

  const rows = data.map((row) => {
    return columns.reduce((res, column) => {
      let key = null;
      let value = null;

      if (typeof column.accessor === "function") {
        key = column.Header;
        value = column.accessor(row);
      } else {
        key = column.accessor;
        value = row[column.accessor];
      }

      if (key !== null && selectedColumns[key]) {
        res[column.Header] = value;
      }

      return res;
    }, {});
  });

  return { columnNames, rows };
}

export async function downloadTextFile(filename, data, format) {
  const blob = new Blob([data], { type: `text/${format}` });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}
