/* eslint-disable jsx-a11y/heading-has-content */
import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "./../../atoms/List/Table";
import { getIncidents } from "@syntbeheer/api/services/incidents/index";
import { withTranslation } from "react-i18next";

class Incidents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Incidents: [],
    };
  }
  componentDidMount() {
    getIncidents().then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({ Incidents: response.Incidents });
      }
    });
  }
  render() {
    const { t } = this.props;

    const columns = [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "hidden",
        accessor: "solved_at",
      },
      {
        Header: t("dashboard.incidents.columns.reference", "Serial number"),
        accessor: "reference",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.solved_at ? { textDecoration: "line-through" } : {}
              }
            >
              <a href={`/app/incident/${row.values.id}`}>{value}</a>
            </span>
          );
        },
      },
      {
        Header: t(
          "dashboard.incidents.columns.reportDescription",
          "Description"
        ),
        accessor: (row) => row.report_description,
        id: "report_description",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.solved_at ? { textDecoration: "line-through" } : {}
              }
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: t("dashboard.incidents.columns.type", "Type"),
        accessor: (row) => row.ReportType.name,
        id: "type",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.solved_at ? { textDecoration: "line-through" } : {}
              }
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: t("dashboard.incidents.columns.reportedBy", "Reported by"),
        accessor: (row) => (row.User && row.User.full_name) || "",
        id: "reported_by",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.solved_at ? { textDecoration: "line-through" } : {}
              }
            >
              {value}
            </span>
          );
        },
      },
      {
        Header: t("dashboard.incidents.columns.startDate", "Start date"),
        accessor: (row) => new Date(row.createdAt).toLocaleDateString(),
        id: "start_date",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.solved_at ? { textDecoration: "line-through" } : {}
              }
            >
              {value}
            </span>
          );
        },
      },
    ];
    return (
      <div className="row">
        <div className="col">
          <h2 style={{ fontWeight: 700, color: colors.primary }}></h2>
          <Table
            columns={columns}
            data={this.state.Incidents}
            title={t("dashboard.incidents.title", "Incidents and damage files")}
            description={t(
              "dashboard.incidents.description",
              "All files with internal suppliers and external repairers via insurance"
            )}
            enableSearch={true}
            enableSort={true}
            addPath="/app/incident"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Incidents);
