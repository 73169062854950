import colors from "@syntbeheer/assets/colors";
import React from "react";
import Table from "../../atoms/List/Table";
import { getMeetings } from "@syntbeheer/api/services/meetings/index";
import { getCommissioners } from "@syntbeheer/api/services/lots/index";
import { DownloadPdf } from "./../../organisms/PDF";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import { generatePdfDocument } from "../../organisms/Meeting";
import PlayIcon from "@mui/icons-material/PlayCircleFilled";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import { withTranslation } from "react-i18next";

class Meetings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Meetings: [],
      Commissioners: [],
    };
  }
  componentDidMount() {
    getMeetings().then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({ Meetings: response.Meetings });
      }
    });
    getCommissioners().then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({ Commissioners: response.Commissioners });
      }
    });
  }
  render() {
    const { t } = this.props;
    const { Meetings, Commissioners } = this.state;
    const VME = vmesSelectors.get().VME || {};
    const User = usersSelectors.get().User;

    const columns = [
      {
        Header: "Id",
        accessor: "id",
      },
      {
        Header: "hidden",
        accessor: "is_cancelled",
      },
      {
        Header: t("dashboard.meetings.columns.name", "Name"),
        accessor: "name",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.is_cancelled
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {!User.is_admin && User.UserVME?.type !== "synt_authoriser" ? (
                <span>{value}</span>
              ) : (
                <a href={`/app/agenda/${row.values.id}`}>{value}</a>
              )}
            </span>
          );
        },
      },
      {
        Header: t("dashboard.meetings.columns.startsAt", "Start date"),
        accessor: (row) => `${new Date(row.starts_at).toLocaleDateString()},
        ${new Date(row.starts_at).toLocaleString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`,
        id: "start_date",
        Cell: ({ row, value }) => {
          return (
            <span
              style={
                row.values.is_cancelled
                  ? { textDecoration: "line-through" }
                  : {}
              }
            >
              {value}
            </span>
          );
        },
      },
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "live",
        Cell: ({ row }) => {
          if (!User.is_admin && User.UserVME?.type !== "synt_authoriser") {
            return null;
          }
          const Meeting = this.state.Meetings.find(
            (item) => item.id === row.values.id
          );
          if (Meeting?.type !== "general") {
            return null;
          }
          return (
            <div style={{ textAlign: "right" }}>
              <a
                href={`/app/stream/agenda/${row.values.id}`}
                className="btn p-0 ml-auto"
              >
                {Meeting.is_streaming
                  ? t("Meeting Ongoing", "Meeting Ongoing")
                  : t("Start Meeting", "Start Meeting")}{" "}
                <PlayIcon
                  style={Meeting.is_streaming ? { color: colors.success } : {}}
                />
              </a>
            </div>
          );
        },
      },
      {
        Header: () => null, // No header
        id: "pdf", // It needs an ID
        Cell: ({ cell, row }) => {
          const Meeting = this.state.Meetings.find(
            (item) => item.id === row.values.id
          );

          const document = generatePdfDocument({
            Meeting,
            VME,
            t,
            Commissioners,
          });

          if (document) {
            return (
              <div className="d-flex justify-content-end">
                <DownloadPdf
                  document={document}
                  index={Meeting.id}
                  fileName={`Verslag: ${Meeting.name}`}
                />
              </div>
            );
          }

          return null;
        },
      },
    ];
    return (
      <div className="row">
        <div className="col">
          <Table
            columns={columns}
            data={Meetings}
            title={t("dashboard.meetings.table.title", "Events")}
            description=""
            enableSearch={false}
            enableSort={false}
            addPath="/app/agenda"
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Meetings);
