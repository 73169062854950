import colors from "@syntbeheer/assets/colors";
import React from "react";
import {
  postPayment,
  getAccountingOverview,
} from "@syntbeheer/api/services/accounting/index";
import CheckBox from "./../../atoms/Input/CheckBox";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import { getPaySignLink } from "@syntbeheer/api/services/banking/index";
import DropDown from "./../../atoms/Input/DropDown";
import PaymentButton from "../../organisms/banking/PaymentButton";
import { useTranslation, Trans } from "react-i18next";
import { ExportTable } from "../../organisms/ExportTable";
import DateTimeField from "../../atoms/Input/DateTime";
import { PaymentStatus } from "../../organisms/banking/PaymentStatus";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import Button from "../../atoms/Buttons/Button";
import ModalView from "../../organisms/Modal";

const PaymentsExplainerModal = ({ ...props }) => {
  const { t } = useTranslation("common");
  // TODO:
  // const [doNotShowAgain, setDoNotShowAgain] = React.useState(false);

  return (
    <ModalView
      show={props.show}
      showCancelButton={false}
      title={t("PaymentsExplainerModal.title", "Sign Payments Required")}
      submitTitle={t("redirectBankModal.submitTitle", "Understood!")}
      onSubmit={() => {
        console.log("submit");
        window.location.reload();
      }}
      body={
        <>
          <div className="alert alert-info" role="alert">
            {t(
              "PaymentsExplainerModal.body",
              "You can individually select (multiple) Purchases for payment like you just did. Afterwards you have to sign those payments by clicking the appearing button above the table. If needed, you can cancel payments by using the same button."
            )}
          </div>
          {/* <div className="ml-3">
            <CheckBox
              label={t(
                "PaymentsExplainerModal.doNotShowAgain",
                "Understood. Do not show again."
              )}
              checked={doNotShowAgain}
              onChange={(event) => {
                const { checked } = event.target;
                setDoNotShowAgain(checked);
              }}
              marginbottom={0}
            />
          </div> */}
        </>
      }
    />
  );
};

export default function Accounting() {
  const [purchases, setPurchases] = React.useState([]);
  const [financialYears, setFinancialYears] = React.useState([]);
  const [showAccounting, setShowAccounting] = React.useState(false);
  const [selectedFinancialYearId, setSelectedFinancialYearId] =
    React.useState(false);
  const [selectedFinancialYear, setSelectedFinancialYear] =
    React.useState(false);
  const VME = vmesSelectors.get().VME || {};
  const [signLink, setSignLink] = React.useState(false);
  const { t } = useTranslation("common");
  const hasPaymentsToSign = (purchases || []).some(
    (d) => d.payment?.status === "unsigned"
  );
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (hasPaymentsToSign) {
      getPaySignLink()
        .then((res) => {
          console.log(res);
          if (res.success) {
            setSignLink(res.pay_sign_link);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [hasPaymentsToSign]);

  React.useEffect(() => {
    getAccountingOverview()
      .then((response) => {
        console.log(response);
        setPurchases(response.Purchases);
        setFinancialYears(
          response.FinancialYears.sort((a, b) => (a.year > b.year ? -1 : 1))
        );
        if (response.GLAccounts?.length) {
          setShowAccounting(true);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="row">
      <PaymentsExplainerModal
        show={showModal}
        toggle={() => setShowModal(!showModal)}
      />
      <div className="col">
        <div className="row">
          <div className="col d-flex align-items-center">
            <h2 style={{ fontWeight: 700, color: colors.primary }}>
              {t("dashboard.accounting.title", "Accountancy")}
            </h2>
          </div>

          <div className="col my-auto d-flex justify-content-end align-items-center">
            {hasPaymentsToSign && (
              <div className="mx-3">
                <Button
                  title={t("Sign Payments", "Sign Payments")}
                  buttontype="btn-primary"
                  onClick={() => {
                    window.open(signLink, "_newtab");
                  }}
                  marginbottom={0}
                  margintop={0}
                />
              </div>
            )}
            <DropDown
              label=""
              options={[
                {
                  id: false,
                  year: t(
                    "dashboard.accounting.fiscalYearsDropdown.allYearsOption",
                    "All fiscal years"
                  ),
                },
                ...financialYears,
              ].map((Y) => {
                Y.label = `${Y.year}`;
                return Y;
              })}
              field="id"
              value={selectedFinancialYearId}
              placeholder={t(
                "dashboard.accounting.fiscalYearsDropdown.placeholder",
                "Filter by fiscal year"
              )}
              onChange={(selected) => {
                setSelectedFinancialYearId(selected.id);
                setSelectedFinancialYear(selected);
              }}
              marginbottom={0}
              style={{ minWidth: 300 }}
            />
          </div>
        </div>

        {showAccounting ? (
          <>
            <PurchasesTable
              data={purchases
                .filter(
                  (P) =>
                    selectedFinancialYearId === false ||
                    (new Date(P.invoice_date) >
                      new Date(selectedFinancialYear.start_date) &&
                      new Date(P.invoice_date) <
                        new Date(selectedFinancialYear.end_date))
                )
                .sort((a, b) => (a.id > b.id ? -1 : 1))}
              hasBanking={VME.has_banking}
              onChangePurchaseStatus={(id, checked) => {
                const P = purchases.find((p) => p.id === id);
                if (P) {
                  postPayment({
                    id: P.id,
                    paid_at: P.paid_at,
                    type: "Purchase",
                  });
                }

                setPurchases((purchases) =>
                  purchases.map((P) => {
                    if (P.id === id) {
                      return {
                        ...P,
                        paid_at: checked,
                      };
                    }
                    return P;
                  })
                );
              }}
              setShowModal={setShowModal}
            />
          </>
        ) : (
          <div>
            <Trans
              i18nKey="dashboard.accounting.hideAccounting.description"
              t={t}
              ns="common"
            >
              First of all, the general ledger accounts of the VME must be
              known. Go to{" "}
              <a href="/app/general-ledger-accounts">G/L Accounts</a>.
            </Trans>
          </div>
        )}
      </div>
    </div>
  );
}

function PurchaseStatusCell({
  hasBanking,
  row,
  onChangePurchaseStatus,
  hasAccess,
  setShowModal,
}) {
  return (
    <div className="d-flex align-items-center">
      {hasBanking && !row.original.paid_at && !row.original.payment && (
        <div className="mr-2">
          <PaymentButton
            id={row.values.id}
            type="Purchase"
            onSuccess={(response) => {
              console.log(response);
              if (response.success) {
                setShowModal(true);
              }
            }}
          />
        </div>
      )}
      <div>
        <PaymentStatus
          id={row.original.id}
          type="Purchase"
          paid_at={row.original.paid_at}
          payment={row.original.payment}
        />
      </div>
      {hasAccess && !hasBanking && (
        <div className="ml-2">
          <CheckBox
            label=""
            checked={row.original.paid_at}
            onChange={(event) => {
              console.log({ id: row.values.id, checked: event.target.checked });
              onChangePurchaseStatus(row.values.id, event.target.checked);
            }}
            marginbottom={0}
          />
        </div>
      )}
    </div>
  );
}

function PurchasesTable({
  data,
  hasBanking,
  onChangePurchaseStatus,
  setShowModal,
}) {
  const { t } = useTranslation("common");
  const User = usersSelectors.get().User;
  const hasAccessToCreate =
    User?.is_admin ||
    User?.UserVME?.type === "synt_authoriser" ||
    User?.UserVME?.type === "bookkeeper";

  const columnsPurchases = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: t(
        "dashboard.accounting.purchases.columns.reference",
        "Reference"
      ),
      accessor: "reference",
      Cell: ({ row, value }) => {
        return hasAccessToCreate ? (
          <a href={`/app/invoice/${row.values.id}`}>{value}</a>
        ) : (
          value
        );
      },
    },
    {
      Header: t("dashboard.accounting.purchases.columns.supplier", "Supplier"),
      accessor: (row) => row.Supplier?.alias || row.Supplier?.Company?.name,
    },
    {
      Header: t("dashboard.accounting.purchases.columns.totalAmount", "Amount"),
      accessor: (row) =>
        "€" +
        row.total_amount
          .toFixed(2) // always two decimal digits
          .replace(".", ",") // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
    },
    {
      Header: t(
        "dashboard.accounting.purchases.columns.invoiceDate",
        "Invoice date"
      ),
      accessor: "invoice_date",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
    {
      Header: t(
        "dashboard.accounting.purchases.columns.dueDate",
        "Expiration date"
      ),
      accessor: "due_date",
      Cell: ({ row, value }) => {
        return (
          <span
            style={
              !row.original.paid_at && new Date() > new Date(value)
                ? { color: "red" }
                : {}
            }
          >
            {new Date(value).toLocaleDateString()}
          </span>
        );
      },
    },
    {
      Header: t(
        "dashboard.accounting.purchases.columns.status.header",
        "Status"
      ),
      accessor: (row) => (row.paid_at != null ? "Betaald" : "Open"),
      Cell: ({ row }) => {
        return (
          <PurchaseStatusCell
            hasBanking={hasBanking}
            onChangePurchaseStatus={onChangePurchaseStatus}
            row={row}
            hasAccess={hasAccessToCreate}
            setShowModal={setShowModal}
          />
        );
      },
    },
  ];

  const additionalColumns = [
    {
      Header: t(
        "dashboard.accounting.purchases.columns.paid_at.header",
        "Paid at"
      ),
      accessor: "paid_at",
      Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : ""),
    },
  ];

  return (
    <ExportTable
      columns={columnsPurchases}
      mapColumns={(columns) => [
        ...columns.map((column) => {
          if (
            column.Header ===
            t("dashboard.accounting.purchases.columns.status.header", "Status")
          ) {
            return {
              ...column,
              Cell: ({ row, value }) => {
                return (
                  <div className="d-flex justify-content-between align-items-center">
                    {value
                      ? t(
                          "dashboard.accounting.purchases.columns.status.paid",
                          "Paid"
                        )
                      : t(
                          "dashboard.accounting.purchases.columns.status.notPaid",
                          "Opened"
                        )}
                  </div>
                );
              },
            };
          }
          return column;
        }),
        ...additionalColumns,
      ]}
      data={data}
      title={t("dashboard.accounting.purchases.title", "Purchase")}
      enableSearch={true}
      enableSort={true}
      addPath={hasAccessToCreate ? "/app/invoice" : null}
      addTitle={t("dashboard.accounting.purchases.addButton", "New purchase")}
      exportFileName="purchases"
      renderDataFilterComponent={({ filters, setFilters }) => (
        <DataFilterComponent filters={filters} setFilters={setFilters} />
      )}
      dataFilter={dataFilter}
    />
  );
}

function DataFilterComponent({ filters, setFilters }) {
  const { t } = useTranslation("common");

  return (
    <div className="row">
      <div className="col-md-4">
        <DateTimeField
          label={t(
            "dashboard.financialYearForm.fields.startDate.label",
            "Start date"
          )}
          name="start_date"
          value={filters.start_date ?? false}
          onChange={(start_date) =>
            setFilters((prev) => ({ ...prev, start_date }))
          }
          max={filters.end_date}
          utc={true}
          futureonly={0}
        />
      </div>
      <div className="col-md-4">
        <DateTimeField
          label={t(
            "dashboard.financialYearForm.fields.endDate.label",
            "End date"
          )}
          name="end_date"
          value={filters.end_date}
          onChange={(end_date) => setFilters((prev) => ({ ...prev, end_date }))}
          min={filters.start_date}
          utc={true}
          futureonly={0}
        />
      </div>
    </div>
  );
}

function dataFilter(data, filters) {
  let filteredData = data;

  if (filters.start_date && filters.start_date.getTime() > 0) {
    filteredData = filteredData.filter((item) => {
      return new Date(item.invoice_date) >= filters.start_date;
    });
  }

  if (filters.end_date && filters.end_date.getTime() > 0) {
    filteredData = filteredData.filter((item) => {
      return new Date(item.invoice_date) <= filters.end_date;
    });
  }

  return filteredData;
}
