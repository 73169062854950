import { StyleSheet, View } from "@react-pdf/renderer";
import React from "react";

const pdfTableStyles = StyleSheet.create({
  table: {
    width: "100%",
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  cell: {
    padding: 4,
  },
  cellBorder: {
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
  },
  lastCellBorderRight: {
    borderRight: "1px solid black",
  },
  lastCellBorderBottom: {
    borderBottom: "1px solid black",
  },
});

export function Table({ data, columns, useBorders, style }) {
  return (
    <View style={[pdfTableStyles.table, style]}>
      {data.map((row, rowIndex, rows) => (
        <View key={rowIndex} style={[pdfTableStyles.row, row.style]}>
          {row.cells.map((cell, cellIndex, cells) => {
            const columnStyle = columns[cellIndex]?.style;

            const borderStyles = useBorders
              ? [
                  pdfTableStyles.cellBorder,
                  cells.length - 1 === cellIndex &&
                    pdfTableStyles.lastCellBorderRight,
                  rows.length - 1 === rowIndex &&
                    pdfTableStyles.lastCellBorderBottom,
                ]
              : [];

            return (
              <View
                key={cellIndex}
                style={[
                  pdfTableStyles.cell,
                  ...borderStyles,
                  columnStyle,
                  cell.style,
                ]}
              >
                {cell.value}
              </View>
            );
          })}
        </View>
      ))}
    </View>
  );
}

export const emptyCell = {
  value: null,
};
