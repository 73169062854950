import React from "react";
import Modal from "./../Modal";
import { useTranslation } from "react-i18next";

const RedirectModal = ({ ...props }) => {
  const { t } = useTranslation("common");

  return (
    <>
      {" "}
      <Modal
        show={props.show}
        title={t("redirectBankModal.title", "Bank connection")}
        submitTitle={t("redirectBankModal.submitTitle", "Okay!")}
        onSubmit={() => {
          console.log("submit");
          props.redirect();
        }}
        handleClose={() => {
          console.log("close");
          props.toggle();
        }}
        body={
          <div>
            {t(
              "redirectBankModal.body",
              "We are going to refer you to the bank link via Ponto. This is necessary to retrieve and process the bank details."
            )}
          </div>
        }
      />
    </>
  );
};

export default RedirectModal;
