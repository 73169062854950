import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "./../atoms/Buttons/Button";
import { useTranslation } from "react-i18next";

export default function ModalView({
  show,
  title,
  body,
  onSubmit,
  submitTitle,
  handleClose,
  dialogClassName = "",
  closeTitle,
  showCancelButton = true,
  showConfirmButton = true,
}) {
  const { t } = useTranslation("common");

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName={dialogClassName}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          {showConfirmButton && (
            <Button
              buttontype="btn-primary"
              title={submitTitle}
              onClick={onSubmit}
            />
          )}
          {showCancelButton && (
            <Button
              buttontype="btn-outline-secondary"
              onClick={handleClose}
              title={closeTitle ?? t("modal.closeTitle", "Close")}
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
