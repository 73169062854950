import React from "react";
import { getVMEs } from "@syntbeheer/api/services/vmes/index";
import DropDown from "./DropDown";

export function VMEsPicker(props) {
  const [VMEs, setVMEs] = React.useState([]);

  React.useEffect(() => {
    let cancelled = false;

    getVMEs()
      .then((response) => {
        if (!cancelled) {
          console.log(response);
          if (response.success) {
            setVMEs(response.VMEs);
          }
        }
      })
      .catch((e) => console.log(e));

    return () => {
      cancelled = true;
    };
  }, []);

  const options = VMEs.map((VME) => ({
    label: VME.alias || VME.name,
    value: VME.id,
  }));

  if (VMEs.length === 0) {
    return null;
  }

  return <DropDown options={options} placeholder="" {...props} />;
}
