import colors from "@syntbeheer/assets/colors";
import React from "react";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import { getContacts, postStatus } from "@syntbeheer/api/services/sales/index";
import { getRequests } from "@syntbeheer/api/services/requests/index";
import Table from "./../../atoms/List/Table";
import CheckBox from "./../../atoms/Input/CheckBox";
import { withTranslation } from "react-i18next";

class Sales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Contacts: [],
      Requests: [],
    };
  }
  componentDidMount() {
    getContacts()
      .then((response) => {
        console.log(response);
        if (response.success) {
          this.setState({ Contacts: response.Contacts });
        }
      })
      .catch((e) => console.log(e));
    getRequests()
      .then((response) => {
        console.log(response);
        if (response.success) {
          this.setState({ Requests: response.Requests });
        }
      })
      .catch((e) => console.log(e));
  }

  render() {
    const User = usersSelectors.get().User;
    const { t } = this.props;

    const columns = [
      {
        Header: t("dashboard.sales.columns.id", "Id"),
        accessor: "id",
      },
      {
        Header: t("dashboard.sales.columns.name", "Name"),
        accessor: (row) => row.User?.full_name || "NA",
      },
      {
        Header: t("dashboard.sales.columns.date", "Date"),
        accessor: (row) => new Date(row.createdAt).toLocaleDateString() || "NA",
      },
      {
        Header: t("dashboard.sales.columns.email", "Email"),
        accessor: (row) => row.email || row.User?.email || "NA",
      },
      {
        Header: t("dashboard.sales.columns.telephone", "Telephone"),
        accessor: (row) => row.phone || row.User?.phone || "NA",
      },
      {
        Header: t("dashboard.sales.columns.description", "Message"),
        accessor: "description",
        Cell: ({ row, value }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <div>{value}</div>
              <div>
                <CheckBox
                  label=""
                  checked={row.original.is_done}
                  onChange={(event) => {
                    this.setState({
                      Contacts: this.state.Contacts.map((C) => {
                        if (C.id === row.values.id) {
                          C.is_done = event.target.checked;
                          postStatus({ id: C.id, is_done: C.is_done });
                        }
                        return C;
                      }),
                    });
                  }}
                  marginbottom={0}
                />
              </div>
            </div>
          );
        },
      },
    ];

    const requestsColumns = [
      {
        Header: t("dashboard.requests.columns.id", "Id"),
        accessor: "id",
      },
      {
        Header: t("dashboard.requests.columns.name", "Name"),
        accessor: (row) => row.User?.full_name || "NA",
      },
      {
        Header: t("dashboard.requests.columns.date", "Date"),
        accessor: (row) => new Date(row.createdAt).toLocaleDateString() || "NA",
      },
      {
        Header: t("dashboard.requests.columns.message", "Message"),
        accessor: "message",
        Cell: ({ row, value }) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              <div>{value}</div>
            </div>
          );
        },
      },
    ];

    return (
      <div className="row">
        <div className="col">
          <h2 style={{ fontWeight: 700, color: colors.primary }}>
            {t("dashboard.sales.title", "Support")}
          </h2>
          <Table
            columns={columns}
            data={this.state.Contacts}
            title={t("dashboard.sales.tableTitle", "Contacts")}
          />
          <Table
            columns={requestsColumns}
            data={this.state.Requests}
            title={t("dashboard.requests.tableTitle", "Requests")}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(Sales);
