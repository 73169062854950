import React from "react";
import { useTranslation } from "react-i18next";

const TextArea = ({ margin = 1, ...props }) => {
  const length = props.value?.length;
  const { t } = useTranslation("common");
  return (
    <div className={`form-group has-validation ${props.margin ? "mb-5" : ""}`}>
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <textarea
        {...props}
        aria-describedby={`${props.name}Help`}
        id={props.name}
        className={`form-control ${props.error ? "is-invalid" : ""}`}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
      />
      {props.maxLength && length > 0.9 * props.maxLength && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <small style={{ color: "red", marginLeft: "auto", padding: 6 }}>
            {props.maxLength - length}{" "}
            {t("Charachters Left", "Charachters Left")}
          </small>
        </div>
      )}
      {props.help && (
        <small id={`${props.name}Help`} className="form-text text-muted">
          {props.help}
        </small>
      )}
      {props.error && (
        <small id={`${props.name}Help`} className="invalid-feedback form-text">
          {props.error}
        </small>
      )}
    </div>
  );
};

export default TextArea;
