import colors from "@syntbeheer/assets/colors";
import React from "react";
import { getAccountingOverview } from "@syntbeheer/api/services/accounting/index";
import { postProvision } from "@syntbeheer/api/services/accounting/index";
import DropDown from "./../../atoms/Input/DropDown";
import { useTranslation, Trans } from "react-i18next";
import { isProvisionPaid, ProvisionsTable } from "../../organisms/Provision";

export default function Accounting() {
  const [provisions, setProvisions] = React.useState([]);
  const [financialYears, setFinancialYears] = React.useState([]);
  const [showAccounting, setShowAccounting] = React.useState(false);
  const [selectedFinancialYearId, setSelectedFinancialYearId] =
    React.useState(false);
  const { t } = useTranslation("common");

  React.useEffect(() => {
    getAccountingOverview()
      .then((response) => {
        console.log(response);
        setProvisions(response.Provisions);
        setFinancialYears(
          response.FinancialYears.sort((a, b) => (a.year > b.year ? -1 : 1))
        );
        if (response.GLAccounts?.length) {
          setShowAccounting(true);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h2 style={{ fontWeight: 700, color: colors.primary }}>
              {t("dashboard.accounting.title", "Accountancy")}
            </h2>
          </div>

          <div className="col my-auto d-flex justify-content-end">
            <DropDown
              label=""
              options={[
                {
                  id: false,
                  year: t(
                    "dashboard.accounting.fiscalYearsDropdown.allYearsOption",
                    "All fiscal years"
                  ),
                },
                ...financialYears,
              ].map((Y) => {
                Y.label = `${Y.year}`;
                return Y;
              })}
              field="id"
              value={selectedFinancialYearId}
              placeholder={t(
                "dashboard.accounting.fiscalYearsDropdown.placeholder",
                "Filter by fiscal year"
              )}
              onChange={(selected) => {
                setSelectedFinancialYearId(selected.id);
              }}
              marginbottom={0}
              style={{ minWidth: 300 }}
            />
          </div>
        </div>

        {showAccounting ? (
          <>
            <ProvisionsTable
              data={[
                ...provisions.filter(
                  (P) =>
                    !isProvisionPaid(P) &&
                    (selectedFinancialYearId === false ||
                      selectedFinancialYearId === P.FinancialYearId)
                ),
                ...provisions.filter(
                  (P) =>
                    isProvisionPaid(P) &&
                    (selectedFinancialYearId === false ||
                      selectedFinancialYearId === P.FinancialYearId)
                ),
              ].sort((a, b) =>
                new Date(a.period) > new Date(b.period) ? -1 : 1
              )}
              onMarkFullyPaid={async (provision) => {
                const res = await postProvision({
                  ...provision,
                  Transactions: [
                    {
                      paid_at: new Date().toISOString(),
                      amount: String(provision.amount),
                    },
                  ],
                });
                setProvisions((provisions) =>
                  provisions.map((p) =>
                    p.id === res.Provision.id
                      ? { ...p, Transactions: res.Provision.Transactions }
                      : p
                  )
                );
              }}
              onMarkUnpaid={async (provision) => {
                const res = await postProvision({
                  ...provision,
                  Transactions: [],
                });
                setProvisions((provisions) =>
                  provisions.map((p) =>
                    p.id === res.Provision.id
                      ? { ...p, Transactions: res.Provision.Transactions }
                      : p
                  )
                );
              }}
            />
          </>
        ) : (
          <div>
            <Trans
              i18nKey="dashboard.accounting.hideAccounting.description"
              t={t}
              ns="common"
            >
              First of all, the general ledger accounts of the VME must be
              known. Go to{" "}
              <a href="/app/general-ledger-accounts">G/L Accounts</a>.
            </Trans>
          </div>
        )}
      </div>
    </div>
  );
}
