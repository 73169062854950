import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { Table } from "./shared/Table";
import { formatNumber, formatDate, formatDateTime } from "./shared/utils";
import { getBudgets } from "@syntbeheer/api/services/documents";
import { useTranslation } from "react-i18next";
import ModalView from "../../Modal";
import Field from "../../../atoms/Input/Field";

//-----------------------------------------------------------------------------
const font = {
  normal: "Poppins",
  bold: "Poppins",
};
//-----------------------------------------------------------------------------
export async function getBudgetsData({ t, vmeId, startDate, endDate }) {
  const data = await getBudgets({ vmeId, startDate, endDate });

  const years = data.financialYears
    .map((fy) => ({
      start_date: new Date(fy.start_date),
      end_date: new Date(fy.end_date),
      is_settled: fy.is_settled,
    }))
    .sort((a, b) => {
      if (a.start_date < b.end_date) {
        return 1;
      }
      return -1;
    })
    .slice(0, 2);

  const glas = {};

  data.purchases.forEach((p) => {
    p.PurchaseEntries.forEach((pe) => {
      const id = pe.GeneralLedgerAccount.id;

      glas[id] = glas[id] ?? {};

      glas[id].gla = pe.GeneralLedgerAccount;
      glas[id].purchases = glas[id].purchases ?? [];
      glas[id].purchases.push(p);
    });
  });

  return {
    vme:
      (data.vme.var_number ? data.vme.var_number + " - " : "") + data.vme.alias,
    address: data.vme.Company.address,
    period: `${formatDate(data.start_date)} - ${formatDate(data.end_date)}`,
    date: formatDateTime(new Date()),
    items: Object.values(glas).map(({ gla, purchases }) => {
      const purchasesPerYear = years.map((year) => {
        return {
          year,
          purchases: purchases.filter((p) => {
            const invoiceDate = new Date(p.invoice_date);
            return (
              invoiceDate >= year.start_date && invoiceDate < year.end_date
            );
          }),
        };
      });

      return {
        name: gla.code + " - " + gla.name,
        prevCost:
          purchasesPerYear
            .at(1)
            ?.purchases.reduce((s, p) => s + p.total_amount, 0) ?? 0,
        currCost:
          purchasesPerYear
            .at(0)
            ?.purchases.reduce((s, p) => s + p.total_amount, 0) ?? 0,
      };
    }),
  };
}
//-----------------------------------------------------------------------------

const styles = StyleSheet.create({
  body: {
    padding: "30 30 30 30",
    fontSize: 8,
    fontFamily: font.normal,
  },
  title: {
    fontSize: 9,
    fontFamily: font.bold,
    color: "#c01d21",
    fontWeight: 700,
  },
  pageNumber: {
    fontSize: 9,
    fontFamily: font.bold,
    fontWeight: 700,
    alignItems: "flex-end",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tableHeaderCell: {
    fontFamily: font.bold,
    fontWeight: 700,
    textDecoration: "underline",
    backgroundColor: "#0036361a",
  },
});

export function BudgetModal({ visible, value, onChange, onClose }) {
  const { t } = useTranslation("common");

  return (
    <ModalView
      show={visible}
      title={t("Edit forecast", "Edit forecast")}
      submitTitle={t("Okay!", "Okay!")}
      dialogClassName="max-width-none w-80"
      onSubmit={onClose}
      showCancelButton={false}
      body={
        <Field
          label={t("Forecast (%)", "Forecast (%)")}
          placeholder={t("enter forecast", "enter forecast")}
          value={value.forecast}
          onChange={(v) => onChange({ ...value, forecast: v })}
          type="number"
        />
      }
    />
  );
}

export function BudgetsDocument({ data, popupResult }) {
  const { t } = useTranslation("common");
  let forecast = Number(popupResult.forecast);
  forecast = Number.isNaN(forecast) ? 0 : forecast;

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View fixed>
          <View style={styles.header}>
            <View style={styles.title}>
              <Text>{data.vme}</Text>
              <Text>{data.address}</Text>
            </View>
            <Text>
              {t("Budget based on period", "Budget based on period")}:{" "}
              {data.period}
            </Text>
            <View style={styles.pageNumber}>
              <Text>
                {t("Date", "Date")}: {data.date}
              </Text>
              <PageNumber />
            </View>
          </View>
        </View>
        <Table
          data={[
            {
              cells: [
                {
                  value: <Text>{t("Cost item", "Cost item")}</Text>,
                },
                {
                  value: (
                    <>
                      <Text>{t("Total cost", "Total cost")}</Text>
                      <Text style={{ fontSize: 5 }}>
                        {t(
                          "previous financial year",
                          "previous financial year"
                        )}
                      </Text>
                    </>
                  ),
                },
                {
                  value: (
                    <>
                      <Text>{t("Total cost", "Total cost")}</Text>
                      <Text style={{ fontSize: 5 }}>
                        {t("financial year", "financial year")}
                      </Text>
                    </>
                  ),
                },
                {
                  value: <Text>+/- {t("Percentage", "Percentage")}</Text>,
                },
                {
                  value: <Text>{t("Budget forecast", "Budget forecast")}</Text>,
                },
              ],
              style: styles.tableHeaderCell,
            },
            ...data.items.map((item) => {
              let percentage = 0;
              if (item.prevCost === 0) {
                percentage = item.currCost * 100;
              } else {
                percentage =
                  ((item.currCost - item.prevCost) / item.prevCost) * 100;
              }

              return {
                cells: [
                  {
                    value: <Text>{item.name}</Text>,
                  },
                  {
                    value: <Text>{formatNumber(item.prevCost)}</Text>,
                  },
                  {
                    value: <Text>{formatNumber(item.currCost)}</Text>,
                  },
                  {
                    value: (
                      <Text>
                        {percentage > 0 ? "+" : ""}
                        {formatNumber(percentage)}%
                      </Text>
                    ),
                  },
                  {
                    value: (
                      <Text>
                        {formatNumber(item.currCost * (forecast / 100 + 1))} (
                        {forecast >= 0 ? `+${forecast}` : forecast}%)
                      </Text>
                    ),
                  },
                ],
              };
            }),
            {
              cells: [
                {
                  value: (
                    <Text style={{ textAlign: "right" }}>
                      {t("Totals", "Totals")}:
                    </Text>
                  ),
                },
                {
                  value: (
                    <Text>
                      {formatNumber(
                        data.items.reduce((s, i) => s + i.prevCost, 0)
                      )}
                    </Text>
                  ),
                },
                {
                  value: (
                    <Text>
                      {formatNumber(
                        data.items.reduce((s, i) => s + i.currCost, 0)
                      )}
                    </Text>
                  ),
                },
                {
                  value: "",
                },
                {
                  value: "",
                },
              ],
              style: {
                fontFamily: font.bold,
                fontWeight: 700,
              },
            },
          ]}
          columns={[
            { style: { width: "40%" } },
            { style: { width: "15%", textAlign: "right" } },
            { style: { width: "15%", textAlign: "right" } },
            { style: { width: "15%", textAlign: "right" } },
            { style: { width: "15%", textAlign: "right" } },
          ]}
          useBorders={true}
        />
      </Page>
    </Document>
  );
}

//-----------------------------------------------------------------------------

const pageDateNumberStyles = StyleSheet.create({
  pageNumber: {
    fontSize: 12,
    fontFamily: font.bold,
    fontWeight: 700,
  },
});

function PageNumber({ date }) {
  return (
    <Text
      style={pageDateNumberStyles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
    />
  );
}

//-----------------------------------------------------------------------------

export const exampleData = {
  vme: "003 - VME ALFEN",
  address: "Church Street 42 - 46\n1742 Sint-Katherina-lombeek\n0684.473.075",
  period: "01/06/2021 - 31/05/2022",
  date: "31/07/2022",
  items: [
    {
      name: "610040 FIRE ALERT AND FIRE DETECTION",
      prevCost: 2122.11,
      currCost: 2091.91,
    },
    {
      name: "610100 INSPECTION LIFTS",
      prevCost: 0,
      currCost: 325.9,
    },
    {
      name: "610110 MAINTENANCE CONTRACT LIFTS",
      prevCost: 0,
      currCost: 4693.32,
    },
    {
      name: "610120 MAINTENANCE AND REST. LIFTS NBI MAINTENANCE CONTROL",
      prevCost: 551.52,
      currCost: 3189.96,
    },
    {
      name: "610220 ABOUT. MAINTENANCE ELECTRIC. INSTALLATIONS",
      prevCost: 88.56,
      currCost: 901.38,
    },
    {
      name: "610410 GARAGE GATE MAINTENANCE",
      prevCost: 0,
      currCost: 180,
    },
    {
      name: "610500 BUILDING CLEANING ACCORDING TO CONTRACT",
      prevCost: 2329.2,
      currCost: 1328.56,
    },
    {
      name: "666601 ROUNDING DIFFERENCE AT CHECKOUT",
      prevCost: -0.01,
      currCost: 0.08,
    },
    {
      name: "610040 FIRE ALERT AND FIRE DETECTION",
      prevCost: 2122.11,
      currCost: 2091.91,
    },
    {
      name: "610100 INSPECTION LIFTS",
      prevCost: 0,
      currCost: 325.9,
    },
    {
      name: "610110 MAINTENANCE CONTRACT LIFTS",
      prevCost: 0,
      currCost: 4693.32,
    },
    {
      name: "610120 MAINTENANCE AND REST. LIFTS NBI MAINTENANCE CONTROL",
      prevCost: 551.52,
      currCost: 3189.96,
    },
    {
      name: "610220 ABOUT. MAINTENANCE ELECTRIC. INSTALLATIONS",
      prevCost: 88.56,
      currCost: 901.38,
    },
    {
      name: "610410 GARAGE GATE MAINTENANCE",
      prevCost: 0,
      currCost: 180,
    },
    {
      name: "610500 BUILDING CLEANING ACCORDING TO CONTRACT",
      prevCost: 2329.2,
      currCost: 1328.56,
    },
    {
      name: "666601 ROUNDING DIFFERENCE AT CHECKOUT",
      prevCost: -0.01,
      currCost: 0.08,
    },
    {
      name: "610040 FIRE ALERT AND FIRE DETECTION",
      prevCost: 2122.11,
      currCost: 2091.91,
    },
    {
      name: "610100 INSPECTION LIFTS",
      prevCost: 0,
      currCost: 325.9,
    },
    {
      name: "610110 MAINTENANCE CONTRACT LIFTS",
      prevCost: 0,
      currCost: 4693.32,
    },
    {
      name: "610120 MAINTENANCE AND REST. LIFTS NBI MAINTENANCE CONTROL",
      prevCost: 551.52,
      currCost: 3189.96,
    },
    {
      name: "610220 ABOUT. MAINTENANCE ELECTRIC. INSTALLATIONS",
      prevCost: 88.56,
      currCost: 901.38,
    },
    {
      name: "610410 GARAGE GATE MAINTENANCE",
      prevCost: 0,
      currCost: 180,
    },
    {
      name: "610500 BUILDING CLEANING ACCORDING TO CONTRACT",
      prevCost: 2329.2,
      currCost: 1328.56,
    },
    {
      name: "666601 ROUNDING DIFFERENCE AT CHECKOUT",
      prevCost: -0.01,
      currCost: 0.08,
    },
    {
      name: "610040 FIRE ALERT AND FIRE DETECTION",
      prevCost: 2122.11,
      currCost: 2091.91,
    },
    {
      name: "610100 INSPECTION LIFTS",
      prevCost: 0,
      currCost: 325.9,
    },
    {
      name: "610110 MAINTENANCE CONTRACT LIFTS",
      prevCost: 0,
      currCost: 4693.32,
    },
    {
      name: "610120 MAINTENANCE AND REST. LIFTS NBI MAINTENANCE CONTROL",
      prevCost: 551.52,
      currCost: 3189.96,
    },
    {
      name: "610220 ABOUT. MAINTENANCE ELECTRIC. INSTALLATIONS",
      prevCost: 88.56,
      currCost: 901.38,
    },
    {
      name: "610410 GARAGE GATE MAINTENANCE",
      prevCost: 0,
      currCost: 180,
    },
    {
      name: "610500 BUILDING CLEANING ACCORDING TO CONTRACT",
      prevCost: 2329.2,
      currCost: 1328.56,
    },
    {
      name: "666601 ROUNDING DIFFERENCE AT CHECKOUT",
      prevCost: -0.01,
      currCost: 0.08,
    },
    {
      name: "610040 FIRE ALERT AND FIRE DETECTION",
      prevCost: 2122.11,
      currCost: 2091.91,
    },
    {
      name: "610100 INSPECTION LIFTS",
      prevCost: 0,
      currCost: 325.9,
    },
    {
      name: "610110 MAINTENANCE CONTRACT LIFTS",
      prevCost: 0,
      currCost: 4693.32,
    },
    {
      name: "610120 MAINTENANCE AND REST. LIFTS NBI MAINTENANCE CONTROL",
      prevCost: 551.52,
      currCost: 3189.96,
    },
    {
      name: "610220 ABOUT. MAINTENANCE ELECTRIC. INSTALLATIONS",
      prevCost: 88.56,
      currCost: 901.38,
    },
    {
      name: "610410 GARAGE GATE MAINTENANCE",
      prevCost: 0,
      currCost: 180,
    },
    {
      name: "610500 BUILDING CLEANING ACCORDING TO CONTRACT",
      prevCost: 2329.2,
      currCost: 1328.56,
    },
    {
      name: "666601 ROUNDING DIFFERENCE AT CHECKOUT",
      prevCost: -0.01,
      currCost: 0.08,
    },
    {
      name: "610040 FIRE ALERT AND FIRE DETECTION",
      prevCost: 2122.11,
      currCost: 2091.91,
    },
    {
      name: "610100 INSPECTION LIFTS",
      prevCost: 0,
      currCost: 325.9,
    },
    {
      name: "610110 MAINTENANCE CONTRACT LIFTS",
      prevCost: 0,
      currCost: 4693.32,
    },
    {
      name: "610120 MAINTENANCE AND REST. LIFTS NBI MAINTENANCE CONTROL",
      prevCost: 551.52,
      currCost: 3189.96,
    },
    {
      name: "610220 ABOUT. MAINTENANCE ELECTRIC. INSTALLATIONS",
      prevCost: 88.56,
      currCost: 901.38,
    },
    {
      name: "610410 GARAGE GATE MAINTENANCE",
      prevCost: 0,
      currCost: 180,
    },
    {
      name: "610500 BUILDING CLEANING ACCORDING TO CONTRACT",
      prevCost: 2329.2,
      currCost: 1328.56,
    },
    {
      name: "666601 ROUNDING DIFFERENCE AT CHECKOUT",
      prevCost: -0.01,
      currCost: 0.08,
    },
  ],
};
