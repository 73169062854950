import converter from "xml-js";
import { downloadTextFile } from "./utils";

async function toXML(columns, rows) {
  const data = {
    _declaration: {
      _attributes: {
        version: "1.0",
        encoding: "utf8",
      },
    },
    rows: {
      row: rows.map((row) => ({
        column: Object.entries(row).map(([name, value]) => ({
          _attributes: {
            header: name,
          },
          _text: value,
        })),
      })),
    },
  };

  const options = { compact: true, ignoreComment: true, spaces: 4 };

  return converter.js2xml(data, options);
}

async function downloadXML(filename, data) {
  await downloadTextFile(filename + ".xml", data, "xml");
}

export const xmlConverter = {
  name: "xml",
  stringify: toXML,
  download: downloadXML,
};
