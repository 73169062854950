import React from "react";
import TextArea from "../atoms/Input/TextArea";
import Button from "../atoms/Buttons/Button";
import { postRequest } from "@syntbeheer/api/services/requests/index";
import { withTranslation } from "react-i18next";

class RequestForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message || "",
      success: false,
      errors: {},
    };
  }
  static defaultProps = {
    category: "general",
  };
  handleSubmit = (event) => {
    event.preventDefault();
    postRequest({
      message: this.state.message,
      category: this.props.category,
    }).then((response) => {
      console.log(response);
      if (response.success) {
        this.setState({ success: true, message: "" });
      } else {
        // error
        for (const key in response.errors) {
          this.setState((prevState) => ({
            errors: { ...prevState.errors, key: response.errors[key] },
          }));
        }
      }
    });
  };
  render() {
    const { t } = this.props;
    const { props, state } = this;
    return (
      <>
        <div className="mt-5 mb-5">
          <h3 className="mt-5 mb-3">{props.title}</h3>
          <p>{props.description}</p>
          <TextArea
            label={t(
              "dashboard.requestForm.fields.message.label",
              "Your request"
            )}
            margin={0}
            placeholder={t(
              "dashboard.requestForm.fields.message.placeholder",
              "enter here a new comment"
            )}
            value={state.message}
            onChange={(v) => {
              this.setState({ message: v, success: false });
            }}
            error={state.errors.message || ""}
          />

          <Button
            buttoncategory="btn-primary"
            title={t("dashboard.buttons.send", "Send")}
            category="button"
            onClick={this.handleSubmit}
          />
        </div>
        <div>
          {state.success && (
            <div className="alert alert-success" role="alert">
              {t("dashboard.requestForm.success", "The request has been sent.")}
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation("common")(RequestForm);
