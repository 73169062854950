import colors from "@syntbeheer/assets/colors";
import React from "react";
import UpdateIcon from "@mui/icons-material/Update";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import RequestForm from "./../../organisms/RequestForm";
import BankTransactions from "../../organisms/banking/Transactions";
import BankSaldo from "./../../organisms/banking/Saldo";
import { useTranslation } from "react-i18next";

const Financial = () => {
  const VME = vmesSelectors.get().VME || {};
  const btRef = React.useRef();
  const bsRef = React.useRef();
  const { t } = useTranslation("common");
  return (
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h2 style={{ fontWeight: 700, color: colors.primary }}>
              {t("dashboard.financial.title", "Financial")}
            </h2>
            {VME.has_banking ? (
              <>
                <div className="ml-3 mr-3 mt-5 mb-5">
                  <label>
                    {t(
                      "dashboard.financial.hasBanking.title",
                      "Start up Bank Link"
                    )}
                  </label>
                  <br />
                  <div className="ml-5 mt-3">
                    <button
                      type="button"
                      onClick={() => {
                        bsRef.current.updateBanking();
                        btRef.current.updateBanking();
                      }}
                      className="btn btn-outline-primary"
                    >
                      <UpdateIcon />{" "}
                      {t(
                        "dashboard.financial.hasBanking.updateButton",
                        "Bank Link"
                      )}
                    </button>
                  </div>
                </div>
                <BankSaldo ref={bsRef} />
                <BankTransactions ref={btRef} />
              </>
            ) : (
              <>
                {t(
                  "dashboard.financial.hasNoBanking.description",
                  'This VME has not activated the "Financial" module. This is necessary to enable integration with the bank to make payments, request payments to co-owners, and process bank transactions.'
                )}
                <RequestForm
                  message={t(
                    "dashboard.financial.hasNoBanking.requestForm.message",
                    "Dear, I would like information about the activation module 'Financial'."
                  )}
                  title={t(
                    "dashboard.financial.hasNoBanking.requestForm.title",
                    "Request for extra module"
                  )}
                  description={t(
                    "dashboard.financial.hasNoBanking.requestForm.description",
                    "You can start a request for a new module using this form. Synt BV's support service will then contact you as soon as possible."
                  )}
                  category="banking"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Financial;
