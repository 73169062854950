import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { emptyCell, Table } from "./shared/Table";
import { formatNumber, formatDate, formatDateTime } from "./shared/utils";
import { getGlobalSettlement } from "@syntbeheer/api/services/documents";
import { useTranslation } from "react-i18next";

export async function getInvoiceData({ t, vmeId, startDate, endDate }) {
  const data = await getGlobalSettlement({ vmeId, startDate, endDate });

  const glas = {};

  data.purchases.forEach((p) => {
    p.PurchaseEntries.forEach((pe) => {
      const id = pe.GeneralLedgerAccount.id;

      glas[id] = glas[id] ?? {};

      pe.Purchase = p;

      glas[id].gla = pe.GeneralLedgerAccount;
      glas[id].entries = glas[id].entries ?? [];
      glas[id].entries.push(pe);
    });
  });

  return {
    vme:
      (data.vme.vat_number ? data.vme.vat_number + " - " : "") + data.vme.alias,
    address: data.vme.Company.address,
    invoice: "General ledger account",
    registrationDateFrom: formatDate(data.start_date),
    registrationDateTo: formatDate(data.end_date),
    dateTime: formatDateTime(new Date()),
    documents: Object.values(glas).map(({ gla, entries }) => {
      return {
        name: gla.code + " - " + gla.name,
        items: entries.map((pe) => {
          console.log({ pe });
          return {
            date: formatDate(new Date(pe.Purchase.invoice_date)),
            description:
              pe.Purchase.reference +
              (pe.Purchase.description
                ? " - " + pe.Purchase.description + " "
                : ""),
            scan: "",
            company:
              (pe.Purchase?.Supplier?.alias ||
                pe.Purchase?.Supplier?.Company?.name) ??
              "",
            doc: "",
            vat: pe.amount * pe.vat_percentage,
            total_excl: pe.amount,
            total_incl: pe.amount * (1 + pe.vat_percentage),
          };
        }),
      };
    }),
  };
}
//-----------------------------------------------------------------------------
const font = {
  normal: "Poppins",
  bold: "Poppins",
};
//-----------------------------------------------------------------------------

const styles = StyleSheet.create({
  body: {
    padding: "30 30 30 30",
    fontSize: 8,
    fontFamily: font.normal,
  },
  title: {
    fontSize: 9,
    color: "#c01d21",
  },
  pageNumber: {
    fontSize: 9,
    fontFamily: font.bold,
    fontWeight: 700,
    alignItems: "flex-end",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    fontFamily: font.bold,
    fontWeight: 700,
  },
  tableHeader: {
    flexDirection: "row",
    width: "100%",
    border: "2px solid black",
    padding: 4,
    fontFamily: font.bold,
    fontWeight: 700,
    marginBottom: 5,
    backgroundColor: "#0036361a",
  },
});

const columnWidthsRatio = [
  1, // date
  3, // description
  2, // company
  1, // vat
  1, // total
];

const totalRatio = columnWidthsRatio.reduce((s, r) => s + r, 0);

// in %
const columnWidths = columnWidthsRatio.map((r) => (r / totalRatio) * 100);

export function InvoiceList({ data }) {
  const { t } = useTranslation("common");

  const total_excl = data.documents.reduce(
    (s, doc) => s + doc.items.reduce((ss, item) => ss + item.total_excl, 0),
    0
  );
  const vat = data.documents.reduce(
    (s, doc) => s + doc.items.reduce((ss, item) => ss + item.vat, 0),
    0
  );

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View fixed>
          <View style={styles.header}>
            <View style={styles.title}>
              <Text>{data.vme}</Text>
              <Text>{data.address}</Text>
            </View>
            <View style={{ alignItems: "center" }}>
              <Text>
                {t("INVOICE LIST", "INVOICE LIST")} - {data.invoice}
              </Text>
              <View style={{ fontFamily: font.normal, alignItems: "center" }}>
                <Text>
                  {t("From period : {{from}} to {{to}}", {
                    defaultValue: "From period : {{from}} to {{to}}",
                    from: data.registrationDateFrom,
                    to: data.registrationDateTo,
                  })}
                </Text>
              </View>
            </View>
            <View style={styles.pageNumber}>
              <Text>
                {t("Issue", "Issue")}: {data.dateTime}
              </Text>
              <PageNumber />
            </View>
          </View>
          <View style={styles.tableHeader}>
            <View style={{ width: columnWidths[0] + "%" }}>
              <Text>{t("DATE", "DATE")}</Text>
            </View>
            <View style={{ width: columnWidths[1] + "%" }}>
              <Text>{t("DESCRIPTION", "DESCRIPTION")}</Text>
            </View>
            <View style={{ width: columnWidths[2] + "%" }}>
              <Text>{t("COMPANY", "COMPANY")}</Text>
            </View>
            <View style={{ width: columnWidths[3] + "%", textAlign: "right" }}>
              <Text>{t("VAT", "VAT")}</Text>
            </View>
            <View style={{ width: columnWidths[4] + "%", textAlign: "right" }}>
              <Text>{t("TOTAL", "TOTAL")}</Text>
            </View>
          </View>
        </View>
        {data.documents.map((doc, index) => (
          <View key={index}>
            <Text
              style={{ padding: 4, fontFamily: font.bold, fontWeight: 700 }}
            >
              {doc.name}
            </Text>
            <Table
              data={[
                ...doc.items.map((item) => ({
                  cells: [
                    {
                      value: <Text>{item.date}</Text>,
                    },
                    {
                      value: <Text>{item.description}</Text>,
                    },
                    {
                      value: <Text>{item.company}</Text>,
                    },
                    {
                      value: <Text>{formatNumber(item.vat)}</Text>,
                    },
                    {
                      value: <Text>{formatNumber(item.total_incl)}</Text>,
                    },
                  ],
                })),
                {
                  cells: [
                    emptyCell,
                    emptyCell,
                    {
                      value: <Text>{t("TOTAL", "TOTAL")}:</Text>,
                      style: {
                        textAlign: "right",
                      },
                    },
                    {
                      value: (
                        <Text>
                          {formatNumber(
                            doc.items.reduce((s, i) => s + i.vat, 0)
                          )}
                        </Text>
                      ),
                    },
                    {
                      value: (
                        <Text>
                          {formatNumber(
                            doc.items.reduce((s, i) => s + i.total_incl, 0)
                          )}
                        </Text>
                      ),
                    },
                  ],
                  style: {
                    borderTop: "1px solid black",
                    fontFamily: font.bold,
                    fontWeight: 700,
                  },
                },
              ]}
              columns={[
                { style: { width: columnWidths[0] + "%" } },
                { style: { width: columnWidths[1] + "%" } },
                { style: { width: columnWidths[2] + "%" } },
                {
                  style: {
                    width: columnWidths[3] + "%",
                    textAlign: "right",
                  },
                },
                {
                  style: {
                    width: columnWidths[4] + "%",
                    textAlign: "right",
                  },
                },
              ]}
            />
          </View>
        ))}
        <View
          style={{
            width: "40%",
            alignSelf: "flex-end",
            marginTop: 40,
            fontFamily: font.bold,
            fontWeight: 700,
          }}
        >
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Text style={{ padding: 5 }}>
              {t("TOTAL COSTS WITHOUT VAT", "TOTAL COSTS WITHOUT VAT")} :
            </Text>
            <Text style={{ padding: 5, textAlign: "right", flexGrow: 1 }}>
              {formatNumber(total_excl)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Text style={{ padding: 5 }}>{t("TOTAL VAT", "TOTAL VAT")} :</Text>
            <Text
              style={{
                padding: 5,
                textAlign: "right",
                flexGrow: 1,
              }}
            >
              {formatNumber(vat)}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <Text style={{ padding: 5 }}>
              {t("GRAND TOTAL", "GRAND TOTAL")} :
            </Text>
            <Text
              style={{
                padding: 5,
                textAlign: "right",
                flexGrow: 1,
              }}
            >
              {formatNumber(total_excl + vat)}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}

//-----------------------------------------------------------------------------
function PageNumber() {
  const { t } = useTranslation("common");

  return (
    <Text
      render={({ pageNumber, totalPages }) =>
        t("Page {{pageNumber}} of {{totalPages}}", {
          defaultValue: "Page {{pageNumber}} of {{totalPages}}",
          pageNumber,
          totalPages,
        })
      }
    />
  );
}
//-----------------------------------------------------------------------------
