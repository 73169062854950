import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  display: inline-block;
  button {
    color: var(--primary-color);
    text-decoration: none;
    background-color: transparent;
    border: 0;
    padding: 0;
  }
  button:hover {
    color: var(--primary-hover);
    text-decoration: underline;
  }
`;

export default class TextLink extends React.Component {
  render() {
    return (
      <Styles>
        <button type="button" {...this.props}>
          {this.props.title}
        </button>
      </Styles>
    );
  }
}
