import React from "react";
import { useTranslation } from "react-i18next";
import { processPayment } from "@syntbeheer/api/services/banking";

export function translatePaymentStatus(t, status) {
  if (status === "open") {
    return t("payment.statuses.open", { defaultValue: "Open", ns: "common" });
  }
  if (status === "initiated") {
    return t("payment.statuses.initiated", {
      defaultValue: "Initiated",
      ns: "common",
    });
  }
  if (status === "pending") {
    return t("payment.statuses.pending", {
      defaultValue: "Pending",
      ns: "common",
    });
  }
  if (status === "cancelled") {
    return t("payment.statuses.cancelled", {
      defaultValue: "Cancelled",
      ns: "common",
    });
  }
  if (status === "paid") {
    return t("payment.statuses.paid", { defaultValue: "Paid", ns: "common" });
  }

  return t("payment.statuses.unknown", {
    defaultValue: "Unknown",
    ns: "common",
  });
}

export function PaymentStatus({ id, type, paid_at, payment }) {
  const { t } = useTranslation("common");
  const [status, setStatus] = React.useState(
    translatePaymentStatus(t, paid_at ? "paid" : payment?.status ?? "open")
  );
  const [refresh, setRefresh] = React.useState(1);

  React.useEffect(() => {
    if (
      !paid_at &&
      payment &&
      payment.status !== "paid" &&
      payment.status !== "cancelled" &&
      refresh
    ) {
      let cancelled = false;
      let timeoutId = null;

      processPayment({ id, type })
        .then((res) => {
          if (res.success && !cancelled) {
            setStatus(res.status);

            if (res.status !== "paid" && res.status !== "cancelled") {
              timeoutId = setTimeout(() => {
                setRefresh((r) => r + 1);
              }, 5000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });

      return () => {
        cancelled = true;
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    }
  }, [id, paid_at, payment, refresh, type]);

  return status;
}
