import React from "react";
import { Nav } from "react-bootstrap";
import ChevronIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessIcon from "@mui/icons-material/ExpandLessRounded";

export default function Item(props) {
  return (
    <Nav.Item
      className={`${props.activeKey === props.href ? "active" : ""} py-3`}
    >
      <Nav.Link eventKey={props.href} href={props.href} onClick={props.onClick}>
        <span style={{ verticalAlign: "text-top" }}>
          {props.dropdownItem && <ChevronIcon />}
          {props.icon}
        </span>
        {props.title && <span className="pl-3">{props.title}</span>}
        {props.dropdown && (
          <span style={{ verticalAlign: "text-top" }}>
            {!props.expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </span>
        )}
      </Nav.Link>
    </Nav.Item>
  );
}
