import { useTranslation } from "react-i18next";
import colors from "@syntbeheer/assets/colors";
import React, { useRef } from "react";
import { useHistory } from "react-router";
import { ExportTable } from "./ExportTable";
import DateTimeField from "../atoms/Input/DateTime";
import * as usersSelectors from "@syntbeheer/api/services/users/selectors";
import DoneIcon from "@mui/icons-material/Done";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ListGroup from "react-bootstrap/ListGroup";

export function ProvisionPaymentStatus({ amount, paidAmount }) {
  const { t } = useTranslation("common");

  const status =
    paidAmount === 0 ? (
      <span style={{ color: colors.danger }}>
        {t("dashboard.provisionForm.transactions.statuses.notPaid", "Not paid")}
      </span>
    ) : paidAmount < amount ? (
      <span style={{ color: colors.warning }}>
        {t(
          "dashboard.provisionForm.transactions.statuses.partial",
          "Partially paid"
        )}
      </span>
    ) : paidAmount === amount ? (
      <span style={{ color: colors.success }}>
        {t("dashboard.provisionForm.transactions.statuses.paid", "Paid")}
      </span>
    ) : (
      <span style={{ color: colors.warning }}>
        {t(
          "dashboard.provisionForm.transactions.statuses.overdraft",
          "Overpaid"
        )}
      </span>
    );

  return status;
}

export function getProvisionPaymentStatus(provision) {
  const total = provision.amount ?? 0;
  const paid = (provision.Transactions ?? [])
    .map((p) => Number(p.amount))
    .filter((a) => !Number.isNaN(a))
    .reduce((s, a) => s + a, 0);

  return {
    total,
    paid,
    status:
      total === paid
        ? "fully_paid"
        : paid === 0
        ? "unpaid"
        : paid < total
        ? "partially_paid"
        : "overdraft",
  };
}

export function isProvisionPaid(provision) {
  const { status } = getProvisionPaymentStatus(provision);

  return status === "fully_paid" || status === "overdraft";
}

export function getProvisionColumns({
  hasAccessToCreate,
  t,
  onMarkFullyPaid,
  onMarkUnpaid,
}) {
  const columnsProvisions = [
    {
      Header: "Id",
      accessor: "id",
      showOnEmail: true,
    },
    {
      Header: t(
        "dashboard.accounting.provisions.columns.reference",
        "Reference"
      ),
      accessor: "reference",
      Cell: ({ row, value }) => {
        return hasAccessToCreate ? (
          <a href={`/app/provision/${row.values.id}`}>{value}</a>
        ) : (
          value
        );
      },
      showOnEmail: true,
    },
    {
      Header: t("dashboard.accounting.provisions.columns.period", "Period"),
      accessor: "period",
      showOnEmail: true,
    },
    {
      Header: t("dashboard.accounting.provisions.columns.invoice", "Invoiced"),
      accessor: (row) =>
        row.Lot.name +
        " (" +
        (row.Users[0]?.CompanyId
          ? row.Users[0].Company.name
          : row.Users[0].full_name) +
        ")",
      showOnEmail: true,
    },
    {
      Header: t("dashboard.accounting.provisions.columns.amount", "Amount"),
      accessor: (row) => ({
        amount: row.amount
          ? `${row.amount
              .toFixed(2) // always two decimal digits
              .replace(".", ",") // replace decimal point character with ,
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}`
          : "" +
            (row.correction > 0
              ? `${row.correction
                  .toFixed(2)
                  .replace(".", ",")
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")} (C)`
              : ""),
        row: row,
      }),
      Cell: ({ row, value }) => value.amount,
      showOnEmail: true,
      aggregate: (leaf, group) => {
        const total = group.reduce((sum, item) => {
          const normalizedAmount = item.amount
            .replace(/\./g, "")
            .replace(",", ".");
          const parsedAmount = parseFloat(normalizedAmount);
          return sum + parsedAmount;
        }, 0);

        const paid = group
          .flatMap((g) =>
            Array.isArray(g.row.Transactions)
              ? g.row.Transactions.map((p) => Number(p.amount)).filter(
                  (i) => !Number.isNaN(i)
                )
              : []
          )
          .reduce((s, a) => s + a, 0);

        return {
          amount:
            total !== paid ? (
              total
                .toFixed(2) // always two decimal digits
                .replace(".", ",") // replace decimal point character with ,
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") +
              " (" +
              paid
                .toFixed(2) // always two decimal digits
                .replace(".", ",") // replace decimal point character with ,
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") +
              " " +
              t("Betaald", "Betaald") +
              ")"
            ) : (
              <>
                {total
                  .toFixed(2) // always two decimal digits
                  .replace(".", ",") // replace decimal point character with ,
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                <DoneIcon
                  style={{ color: colors.success, stroke: colors.success }}
                />
              </>
            ),
          status: null,
        };
      },
    },
    {
      Header: t(
        "dashboard.accounting.provisions.columns.status.header",
        "Status"
      ),
      accessor: "status",
      Cell: ({ row, value }) => {
        return (
          <StatusCell
            row={row}
            value={value}
            hasAccessToCreate={hasAccessToCreate}
            onMarkFullyPaid={() => onMarkFullyPaid(row.original)}
            onMarkUnpaid={() => onMarkUnpaid(row.original)}
          />
        );
      },
      showOnEmail: false,
    },
  ];

  return columnsProvisions;
}

export function ProvisionsTable({ data, onMarkFullyPaid, onMarkUnpaid }) {
  const User = usersSelectors.get().User;
  const hasAccessToCreate =
    User?.is_admin ||
    User?.UserVME?.type === "synt_authoriser" ||
    User?.UserVME?.type === "bookkeeper";

  const { t } = useTranslation("common");

  const columnsProvisions = getProvisionColumns({
    t,
    hasAccessToCreate,
    onMarkFullyPaid,
    onMarkUnpaid,
  });

  return (
    <ExportTable
      columns={columnsProvisions}
      data={data}
      title={t("dashboard.accounting.provisions.title", "Queries")}
      enableSearch={true}
      enableReminder={true}
      enableSort={true}
      groupedByAccessors={["period", "status"]}
      addPath={hasAccessToCreate ? "/app/provision" : null}
      addTitle={t("dashboard.accounting.provisions.addButton", "New query")}
      exportFileName="provisions"
      renderDataFilterComponent={({ filters, setFilters }) => (
        <DataFilterComponent filters={filters} setFilters={setFilters} />
      )}
      dataFilter={dataFilter}
      defaultSortColumns={["period"]}
    />
  );
}

function StatusCell({
  row,
  hasAccessToCreate,
  value,
  onMarkFullyPaid,
  onMarkUnpaid,
}) {
  const { t } = useTranslation("common");
  const history = useHistory();
  const ref = useRef(null);
  const { total, paid } = getProvisionPaymentStatus(row.original ?? {});

  return (
    <div className="d-flex justify-content-between align-items-center">
      {!!row.original ? (
        <>
          <ProvisionPaymentStatus amount={total} paidAmount={paid} />
          {hasAccessToCreate ? (
            <OverlayTrigger
              ref={ref}
              placement="left"
              rootClose={true}
              overlay={
                <ListGroup>
                  <ListGroup.Item
                    action
                    disabled={total === paid}
                    onClick={() => {
                      onMarkFullyPaid();
                      document.body.click();
                    }}
                  >
                    {t(
                      "dashboard.accounting.provisions.status.fullyPay",
                      "Mark as fully paid"
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    disabled={paid === 0}
                    onClick={() => {
                      onMarkUnpaid();
                      document.body.click();
                    }}
                  >
                    {t(
                      "dashboard.accounting.provisions.status.unpay",
                      "Mark as unpaid"
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    disabled={total === paid}
                    onClick={() => {
                      history.push(`/app/provision/${row.values.id}`);
                    }}
                  >
                    {t(
                      "dashboard.accounting.provisions.status.add_partial_payment",
                      "Add partial payment"
                    )}
                  </ListGroup.Item>
                </ListGroup>
              }
              trigger="click"
            >
              <MoreVertIcon style={{ cursor: "pointer" }} />
            </OverlayTrigger>
          ) : null}
        </>
      ) : (
        <span>{value}</span>
      )}
    </div>
  );
}

function DataFilterComponent({ filters, setFilters }) {
  const { t } = useTranslation("common");

  return (
    <div className="row mt-3 ml-3">
      <div className="col-md-4">
        <DateTimeField
          label={t(
            "dashboard.financialYearForm.fields.startDate.label",
            "Start date"
          )}
          name="start_date"
          value={filters.start_date ?? false}
          onChange={(start_date) =>
            setFilters((prev) => ({ ...prev, start_date }))
          }
          max={filters.end_date}
          utc={true}
          futureonly={0}
        />
      </div>
      <div className="col-md-4">
        <DateTimeField
          label={t(
            "dashboard.financialYearForm.fields.endDate.label",
            "End date"
          )}
          name="end_date"
          value={filters.end_date}
          onChange={(end_date) => setFilters((prev) => ({ ...prev, end_date }))}
          min={filters?.start_date ?? ""}
          utc={true}
          futureonly={0}
        />
      </div>
    </div>
  );
}

function dataFilter(data, filters) {
  let filteredData = data;

  if (filters.start_date && filters.start_date.getTime() > 0) {
    filteredData = filteredData.filter((item) => {
      return new Date(item.invoice_date) >= filters.start_date;
    });
  }

  if (filters.end_date && filters.end_date.getTime() > 0) {
    filteredData = filteredData.filter((item) => {
      return new Date(item.invoice_date) <= filters.end_date;
    });
  }

  return filteredData;
}
