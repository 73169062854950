import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  pdf,
  Polygon,
  Rect,
  Svg,
} from "@react-pdf/renderer";
import PDFIcon from "@mui/icons-material/PictureAsPdf";
import logo_red from "@syntbeheer/assets/brand/logo_red.png";
import { saveAs } from "file-saver";
import TextLink from "../atoms/Buttons/TextLink";
import LoadingRotating from "../atoms/LoadingRotating";

const TreeSvg = () => (
  <Svg width="20" height="20" viewBox="-100 -100 200 250">
    <Polygon points="0,0 80,120 -80,120" fill="#234236" />
    <Polygon points="0,-40 60,60 -60,60" fill="#0C5C4C" />
    <Polygon points="0,-80 40,0 -40,0" fill="#38755B" />
    <Rect x="-20" y="120" width="40" height="30" fill="#A32B2D" />
  </Svg>
);

// Create styles
export const styles = StyleSheet.create({
  page: {
    paddingTop: 30,
    paddingBottom: 30,
  },
  body: {
    padding: 30,
  },
  logo: {
    marginRight: 20,
  },
  title: {
    fontSize: 24,
    fontWeight: 900,
    color: "#c01d21",
    fontFamily: "Poppins",
  },
  titlePostFix: {
    fontSize: 12,
    fontWeight: 900,
    color: "black",
    fontFamily: "Poppins",
  },
  topSubtitle: {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 40,
    fontFamily: "Poppins",
  },
  subtitle: {
    fontSize: 13,
    margin: 12,
    fontWeight: 700,
    fontFamily: "Poppins",
  },
  text: {
    margin: 6,
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Poppins",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    borderBottom: "1px solid black",
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    fontSize: 8,
    marginLeft: 20,
    marginRight: 20,
    height: 50,
    color: "grey",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10, // Add padding top to separate footer from content
    borderTop: "1px solid #f0f0f0", // Add border top to separate footer from content
  },
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 24,
  },
  tableHeader: {
    backgroundColor: "#0036361a",
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 5,
  },
  tableOddRow: {
    backgroundColor: "#f2f4f4",
  },
  tableCell: {
    width: "25%",
    textAlign: "left",
    flexWrap: "wrap",
    paddingLeft: 12,
    paddingRight: 12,
    fontSize: 10,
    fontFamily: "Poppins",
  },
  topContainer: {
    marginTop: -30,
    backgroundColor: "#0036361a",
    color: "black",
    borderBottom: "1px solid black",
  },
  subHeaderContainer: {
    flex: 1,
    backgroundColor: "#f2f4f4",
    borderRadius: 6,
    marginLeft: 12,
    marginRight: 12,
  },
});

// Create Document Component
export function PDFDocument({ document }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.body, styles.topContainer]}>
          <View style={styles.headerContainer}>
            <View>
              <Text style={styles.title}>{document.title}</Text>
              <Text style={styles.titlePostFix}>{document.title_postfix}</Text>
            </View>
            <View style={styles.logo}>
              <Image style={{ width: 20, height: 20 }} src={logo_red} />
            </View>
          </View>
          <Text style={styles.topSubtitle}>{document.subtitle}</Text>
          {document.left_subheader && document.right_subheader && (
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={[styles.subHeaderContainer]}>
                <Text style={styles.text}>{document.left_subheader}</Text>
                {document.left_subheader_postfix}
              </View>
              <View style={[styles.subHeaderContainer]}>
                <Text style={styles.text}>{document.right_subheader}</Text>
                {document.right_subheader_postfix}
              </View>
            </View>
          )}
        </View>
        <View style={[styles.body]}>
          {(document.sections || []).map((section, h) => {
            return (
              <View key={"section" + h} style={section.containerStyle || {}}>
                {!section.title && !section.text && (
                  <Text style={{ marginTop: 25 }} />
                )}
                {section.title ? (
                  <Text style={styles.subtitle}>{section.title}</Text>
                ) : (
                  <Text />
                )}
                {section.text ? (
                  <View style={section.textContainerStyle || {}}>
                    <Text style={styles.text}>{section.text}</Text>
                  </View>
                ) : (
                  <Text />
                )}
                {!!section.content ? (
                  <View style={section.textContainerStyle || {}}>
                    {section.content}
                  </View>
                ) : (
                  <Text />
                )}
                {section.table ? (
                  <View style={styles.table}>
                    {section.table.headers && (
                      <View style={[styles.tableRow, styles.tableHeader]}>
                        {section.table.headers.map((header, i) => (
                          <Text
                            style={[
                              styles.tableCell,
                              {
                                width: section.table.settings.widths[i],
                                textAlign: section.table.settings.textAligns[i],
                                color: "#c01d21",
                                fontWeight: 700,
                              },
                            ]}
                            key={"section" + h + "header" + i}
                          >
                            {header}
                          </Text>
                        ))}
                      </View>
                    )}
                    {section.table.rows &&
                      section.table.rows.map((row, i) => (
                        <View
                          style={[
                            styles.tableRow,
                            i % 2 === 1 ? styles.tableOddRow : {},
                          ]}
                          key={"section" + h + "row" + i}
                        >
                          {row.cells.map((cell, k) => (
                            <Text
                              style={[
                                styles.tableCell,
                                {
                                  width: section.table.settings.widths[k],
                                  textAlign:
                                    section.table.settings.textAligns[k],
                                },
                              ]}
                              key={"section" + h + "row" + i + "cell" + k}
                            >
                              {cell}
                            </Text>
                          ))}
                        </View>
                      ))}
                  </View>
                ) : (
                  <Text />
                )}
              </View>
            );
          })}
        </View>
        <View style={{ height: 80 }} fixed />
        <View style={styles.footer} fixed>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <TreeSvg />
            <Text style={{ fontSize: 8 }}>
              Please consider the environment before printing this document
            </Text>
          </View>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
}

export function BaseDownloadPdf({ renderDocument, title = "" }) {
  const [loading, setLoading] = React.useState(false);

  return (
    <TextLink
      onClick={async () => {
        setLoading(true);

        try {
          const metaData = await renderDocument();

          if (metaData) {
            const { document, fileName = "Synt" } = metaData;
            const blob = await pdf(document).toBlob();
            saveAs(blob, fileName + ".pdf");
          }
        } catch (error) {
          console.log(error);
        }

        setLoading(false);
      }}
      title={
        <>
          {title} <PDFIcon />
          <LoadingRotating loading={loading} />
        </>
      }
    />
  );
}

export const DownloadPdf = ({
  onPdfCreated,
  file,
  document,
  fileName = "Synt",
  title = "",
  triggerFetchBlob = false,
}) => {
  const fetchBlob = async () => {
    if (onPdfCreated && !file) {
      console.log("FETCHING BLOB");
      const Document = <PDFDocument document={document} />;
      const blob = await pdf(Document).toBlob();
      onPdfCreated(blob);
    }
  };

  React.useEffect(() => {
    console.log("triggerFetchBlob");
    fetchBlob();
  }, [triggerFetchBlob]);

  if (file) {
    // file already uploaded
    return (
      <a href={file.presignedUrl} target="_blank" rel="noreferrer">
        {file.type && file.type.includes("image") ? (
          <img
            src={file.presignedUrl}
            alt={`${file.original_name} | Synt`}
            className="img-thumbnail img-responsive"
            style={{ maxWidth: "50px" }}
          />
        ) : (
          <>
            <PDFIcon />
          </>
        )}
        &nbsp;&nbsp;&nbsp;
        {file.original_name}
      </a>
    );
  } else {
    return (
      <TextLink
        onClick={async () => {
          const Document = <PDFDocument document={document} />;
          const blob = await pdf(Document).toBlob();
          saveAs(blob, fileName + ".pdf");
        }}
        title={
          <>
            {title} <PDFIcon />
          </>
        }
      />
    );
  }
};

export async function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export async function pdfToBlob(document) {
  const Document = <PDFDocument document={document} />;
  const blob = await pdf(Document).toBlob();

  return blob;
}
/*
export function DownloadPdf({
  document,
  index,
  onPdfCreated,
  fileName = "Synt",
}) {
  const Document = <PDFDocument document={document} />;

  // send created pdf back
  React.useEffect(() => {
    // code to run after render goes here
    async function fetchBlob() {
      // You can await here
      console.log("FETCHING BLOB");
      const blob = await pdf(Document).toBlob();
      onPdfCreated(blob);
    }
    fetchBlob();
  }, []);

  return (
    <PDFDownloadLink
      document={Document}
      fileName={fileName + ".pdf"}
      key={index}
    >
      {({ loading }) =>
        loading ? (
          "loading..."
        ) : (
          <>
            <PDFIcon />
          </>
        )
      }
    </PDFDownloadLink>
  );
}
*/

export default PDFDocument;
