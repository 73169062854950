import React from "react";
import { View } from "@react-pdf/renderer";

export function Line({ margin = 5, fixed }) {
  return (
    <View
      fixed={fixed}
      style={{
        borderBottom: "1px solid black",
        marginTop: margin,
        marginBottom: margin,
      }}
    />
  );
}
