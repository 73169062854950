export function getMajorityOptions(t) {
  return [
    {
      label: t("dashboard.majority.options.100", {
        defaultValue: "Unanimous - Everyone",
        ns: "common",
      }),
      majority: 1,
    },
    {
      label: t("dashboard.majority.options.80", {
        defaultValue: "80% Majority",
        ns: "common",
      }),
      majority: 0.8,
    },
    {
      label: t("dashboard.majority.options.75", {
        defaultValue: "75% Majority",
        ns: "common",
      }),
      majority: 0.75,
    },
    {
      label: t("dashboard.majority.options.50_1", {
        defaultValue: "50% + 1 Majority",
        ns: "common",
      }),
      majority: 0.5001,
    },
  ];
}

export function getMajorityLabelByValue(t, majority) {
  const options = getMajorityOptions(t);

  return options.find((o) => o.majority === majority)?.label;
}
