import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { emptyCell, Table } from "./shared/Table";
import { formatNumber as commonFormatNumber } from "./shared/utils";
import { Line } from "./shared/Line";
import { useTranslation } from "react-i18next";
import { getOwners } from "@syntbeheer/api/services/documents";
import { formatDate } from "./shared/utils";

//-----------------------------------------------------------------------------
export async function getOwnersData({ t, vmeId, startDate, endDate }) {
  const data = await getOwners({ vmeId, startDate, endDate });

  return {
    building:
      data.vme.alias +
      (data.vme.vat_number ? " (" + data.vme.vat_number + ")" : ""),
    period: `${formatDate(data.start_date)} - ${formatDate(data.end_date)}`,
    date: new Date().toLocaleDateString(),
    conditionOn: formatDate(data.end_date),

    owners: data.commissioners.map((c) => {
      const lots = c.provisions.reduce((res, p) => {
        res[p.LotId] = res[p.LotId] ?? [];
        res[p.LotId].push(p);
        return res;
      }, {});

      //const allocationLots = c.allocations.at(0)?.Lots || [];
      const settlementLots = c.settlementFiles.at(0)?.Lots || [];
      const totalShares = settlementLots.reduce(
        (total, item) => total + item.share,
        0
      );

      return {
        name: c.full_name,
        lots: [
          ...Object.values(lots).map((provisions) => {
            /*
            const glas =
              settlementLots
                .find((sl) => sl.id === provisions.at(0)?.Lot?.id)
                ?.GeneralLedgerAccounts.map((gla) => {
                  return {
                    date: "",
                    ref: "",
                    description: t("Settlement {{startDate}} - {{endDate}}", {
                      defaultValue: "Settlement {{startDate}} - {{endDate}}",
                      ns: "common",
                      startDate: formatDate(
                        provisions.at(0)?.FinancialYear.start_date
                      ),
                      endDate: formatDate(
                        provisions.at(0)?.FinancialYear.end_date
                      ),
                    }),
                    debit: gla.total_amount_incl,
                    credit: 0,
                  };
                }) ?? [];

            const groupByYear = glas.reduce((res, gla) => {
              res[gla.description] = res[gla.description] ?? [];
              res[gla.description].push(gla);

              return res;
            }, {});

            const settlements = Object.values(groupByYear).map((items) => {
              return items.reduce(
                (res, item) => {
                  res.date = item.date;
                  res.ref = item.ref;
                  res.description = item.description;
                  res.debit += item.debit;
                  res.credit += item.credit;

                  return res;
                },
                {
                  date: "",
                  ref: "",
                  description: "",
                  debit: 0,
                  credit: 0,
                }
              );
            });
            */

            return {
              name: provisions.at(0)?.Lot?.name,
              provisions: provisions.flatMap((p) => [
                {
                  date: formatDate(p.invoice_date),
                  ref: p.reference,
                  description:
                    t(p.type, p.type) + " " + formatDate(p.invoice_date, true),
                  debit: p.amount,
                  credit: 0,
                },
                ...(p.paid_at
                  ? [
                      {
                        date: formatDate(p.paid_at),
                        ref: "BT" + p.reference,
                        description:
                          p.reference +
                          " " +
                          c.full_name +
                          " " +
                          formatDate(p.invoice_date, true),
                        debit: 0,
                        credit: p.amount,
                      },
                    ]
                  : []),
              ]),
              settlements: c.settlementFiles.flatMap((s) => [
                {
                  date: formatDate(s.createdAt),
                  ref: "AF" + s.id,
                  description:
                    t("Settlement", "Settlement") +
                    " " +
                    formatDate(s.createdAt, true),
                  debit:
                    (s.total_amount *
                      (s.Lots.find((l) => l.id === provisions.at(0)?.Lot?.id)
                        ?.share || totalShares)) /
                    totalShares,
                  credit: 0,
                },
                ...(s.paid_at
                  ? [
                      {
                        date: formatDate(s.paid_at),
                        ref: "BTAF" + s.id,
                        description:
                          s.reference +
                          " " +
                          c.full_name +
                          " " +
                          formatDate(s.createdAt, true),
                        debit: 0,
                        credit:
                          (s.total_amount *
                            (s.Lots.find(
                              (l) => l.id === provisions.at(0)?.Lot?.id
                            )?.share || totalShares)) /
                          totalShares,
                      },
                    ]
                  : []),
              ]),
            };
          }),
        ],
      };
    }),
  };
}

//-----------------------------------------------------------------------------

function formatNumber(num, showZero = false) {
  if (showZero || num) {
    return commonFormatNumber(num);
  }

  return "";
}
//-----------------------------------------------------------------------------
const font = {
  normal: "Poppins",
  bold: "Poppins",
};
//-----------------------------------------------------------------------------

const styles = StyleSheet.create({
  body: {
    padding: "30 30 30 30",
    fontSize: 8,
    fontFamily: font.normal,
  },
  title: {
    fontSize: 9,
    color: "#c01d21",
  },
  pageNumber: {
    fontSize: 9,
    fontFamily: font.bold,
    fontWeight: 700,
    alignItems: "flex-end",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    fontFamily: font.bold,
    fontWeight: 700,
  },
  tableHeader: {
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid black",
    padding: 4,
    fontFamily: font.bold,
    fontWeight: 700,
    marginBottom: 5,
    backgroundColor: "#0036361a",
  },
});

const columnWidthsRatio = [
  4, // date
  4, // ref
  8, // description
  3, // debit
  3, // credit
  3, // balance debit
  3, // balance credit
];

const totalRatio = columnWidthsRatio.reduce((s, r) => s + r, 0);

// in %
const columnWidths = columnWidthsRatio.map((r) => (r / totalRatio) * 100);

const columns = [
  { style: { width: columnWidths[0] + "%" } },
  { style: { width: columnWidths[1] + "%" } },
  { style: { width: columnWidths[2] + "%" } },
  {
    style: {
      width: columnWidths[3] + "%",
      textAlign: "right",
    },
  },
  {
    style: {
      width: columnWidths[4] + "%",
      textAlign: "right",
    },
  },
  {
    style: {
      width: columnWidths[5] + "%",
      textAlign: "right",
    },
  },
  {
    style: {
      width: columnWidths[6] + "%",
      textAlign: "right",
    },
  },
];

export function Owners({ data }) {
  const { t } = useTranslation("common");

  const owners = data.owners.map((owner) => {
    const lots = owner.lots.map((lot) => {
      const provisionDebit = lot.provisions.reduce((s, i) => s + i.debit, 0);
      const provisionCredit = lot.provisions.reduce((s, i) => s + i.credit, 0);

      const settlementDebit = lot.settlements.reduce((s, i) => s + i.debit, 0);
      const settlementCredit = lot.settlements.reduce(
        (s, i) => s + i.credit,
        0
      );

      const debit = provisionDebit + settlementDebit;
      const credit = provisionCredit + settlementCredit;

      return {
        ...lot,
        debit,
        credit,
        provisionDebit,
        provisionCredit,
        settlementDebit,
        settlementCredit,
      };
    });

    const debit = lots.reduce((s, i) => s + i.debit, 0);
    const credit = lots.reduce((s, i) => s + i.credit, 0);

    return {
      ...owner,
      lots,
      debit,
      credit,
    };
  });

  const debit = owners.reduce((d, s) => d + s.debit, 0);
  const credit = owners.reduce((c, s) => c + s.credit, 0);

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View fixed>
          <View style={styles.header}>
            <View style={styles.title}>
              <Text>{t("CA Owners", "CA Owners")}</Text>
              <Text>{data.building}</Text>
              <Text>{data.period}</Text>
            </View>
            <Text>
              {t("Condition on", "Condition on")}: {data.conditionOn}
            </Text>
            <View style={styles.pageNumber}>
              <Text>{data.date}</Text>
              <PageNumber />
            </View>
          </View>
          <View style={styles.tableHeader}>
            <View style={{ width: columnWidths[0] + "%" }}>
              <Text>{t("Owner/Private", "Owner/Private")}</Text>
            </View>
            <View style={{ width: columnWidths[1] + "%" }}>
              <Text>{t("Ref", "Ref.")}</Text>
            </View>
            <View style={{ width: columnWidths[2] + "%" }}>
              <Text>{t("Description", "Description")}</Text>
            </View>
            <View style={{ width: columnWidths[3] + "%", textAlign: "right" }}>
              <Text>{t("Debit", "Debit")}</Text>
            </View>
            <View style={{ width: columnWidths[4] + "%", textAlign: "right" }}>
              <Text>{t("Credit", "Credit")}</Text>
            </View>
            <View style={{ width: columnWidths[5] + "%", textAlign: "right" }}>
              <Text>{t("Balance DB", "Balance DB")}</Text>
            </View>
            <View style={{ width: columnWidths[6] + "%", textAlign: "right" }}>
              <Text>{t("Balance CR", "Balance CR")}</Text>
            </View>
          </View>
        </View>
        {owners.map((owner, index) => {
          return (
            <View key={index} style={{ padding: 4 }}>
              <Text style={{ fontFamily: font.bold, fontWeight: 700 }}>
                {owner.name}
              </Text>
              {owner.lots.map((lot, lotIndex) => (
                <View key={lotIndex} style={{ padding: 4 }}>
                  <Text style={{ fontFamily: font.bold, fontWeight: 700 }}>
                    {lot.name}
                  </Text>

                  <Table
                    data={[
                      ...lot.provisions.map((item) => ({
                        cells: [
                          {
                            value: <Text>{item.date}</Text>,
                          },
                          {
                            value: <Text>{item.ref}</Text>,
                          },
                          {
                            value: <Text>{item.description}</Text>,
                          },
                          {
                            value: <Text>{formatNumber(item.debit)}</Text>,
                          },
                          {
                            value: <Text>{formatNumber(item.credit)}</Text>,
                          },
                          emptyCell,
                          emptyCell,
                        ],
                      })),
                      {
                        cells: [
                          emptyCell,
                          emptyCell,
                          {
                            value: (
                              <Text>
                                {t("Total Provision", "Total Provision")}
                              </Text>
                            ),
                            style: {
                              borderTop: "1px solid black",
                              textAlign: "right",
                            },
                          },
                          {
                            value: (
                              <Text>
                                {formatNumber(lot.provisionDebit, true)}
                              </Text>
                            ),
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            value: (
                              <Text>
                                {formatNumber(lot.provisionCredit, true)}
                              </Text>
                            ),
                            style: {
                              borderTop: "1px solid black",
                              textAlign: "right",
                            },
                          },
                          {
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                        ],
                      },
                      ...lot.settlements.map((settlement) => ({
                        cells: [
                          {
                            value: <Text>{settlement.date}</Text>,
                          },
                          {
                            value: <Text>{settlement.ref}</Text>,
                          },
                          {
                            value: <Text>{settlement.description}</Text>,
                          },
                          {
                            value: (
                              <Text>{formatNumber(settlement.debit)}</Text>
                            ),
                          },
                          {
                            value: (
                              <Text>{formatNumber(settlement.credit)}</Text>
                            ),
                          },
                          emptyCell,
                          emptyCell,
                        ],
                      })),
                      {
                        cells: [
                          emptyCell,
                          emptyCell,
                          {
                            value: (
                              <Text>
                                {t("Total Settlements", "Total Settlements")}
                              </Text>
                            ),
                            style: {
                              borderTop: "1px solid black",
                              textAlign: "right",
                            },
                          },
                          {
                            value: (
                              <Text>
                                {formatNumber(lot.settlementDebit, true)}
                              </Text>
                            ),
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            value: (
                              <Text>
                                {formatNumber(lot.settlementCredit, true)}
                              </Text>
                            ),
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                        ],
                      },
                      {
                        cells: [
                          {
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            value: (
                              <Text>
                                {t("Total", "Total")} {lot.name}
                              </Text>
                            ),
                            style: {
                              borderTop: "1px solid black",
                              fontFamily: font.bold,
                              fontWeight: 700,
                              textAlign: "right",
                            },
                          },
                          {
                            value: <Text>{formatNumber(lot.debit, true)}</Text>,
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            value: (
                              <Text>{formatNumber(lot.credit, true)}</Text>
                            ),
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            value:
                              lot.debit > lot.credit ? (
                                <Text>
                                  {formatNumber(lot.debit - lot.credit, true)}
                                </Text>
                              ) : null,
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                          {
                            value:
                              lot.credit > lot.debit ? (
                                <Text>
                                  {formatNumber(lot.credit - lot.debit, true)}
                                </Text>
                              ) : null,
                            style: {
                              borderTop: "1px solid black",
                            },
                          },
                        ],
                      },
                    ]}
                    columns={columns}
                  />
                </View>
              ))}
              <Table
                data={[
                  {
                    cells: [
                      {
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                      {
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                      {
                        value: (
                          <Text>
                            {t("Total", "Total")} {owner.name}
                          </Text>
                        ),
                        style: {
                          borderTop: "1px solid black",
                          fontFamily: font.bold,
                          fontWeight: 700,
                          textAlign: "right",
                        },
                      },
                      {
                        value: <Text>{formatNumber(owner.debit)}</Text>,
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                      {
                        value: <Text>{formatNumber(owner.credit)}</Text>,
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                      {
                        value:
                          owner.debit > owner.credit ? (
                            <Text>
                              {formatNumber(owner.debit - owner.credit)}
                            </Text>
                          ) : null,
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                      {
                        value:
                          owner.credit > owner.debit ? (
                            <Text>
                              {formatNumber(owner.credit - owner.debit)}
                            </Text>
                          ) : null,
                        style: {
                          borderTop: "1px solid black",
                        },
                      },
                    ],
                  },
                ]}
                columns={columns}
              />
            </View>
          );
        })}
        <Line />
        <Line />
        <Table
          data={[
            {
              cells: [
                {
                  style: {
                    borderTop: "1px solid black",
                  },
                },
                {
                  style: {
                    borderTop: "1px solid black",
                  },
                },
                {
                  style: {
                    borderTop: "1px solid black",
                  },
                },
                {
                  value: <Text>{formatNumber(debit)}</Text>,
                  style: {
                    borderTop: "1px solid black",
                  },
                },
                {
                  value: <Text>{formatNumber(credit)}</Text>,
                  style: {
                    borderTop: "1px solid black",
                  },
                },
                {
                  value:
                    debit > credit ? (
                      <Text>{formatNumber(debit - credit)}</Text>
                    ) : null,
                  style: {
                    borderTop: "1px solid black",
                  },
                },
                {
                  value:
                    credit > debit ? (
                      <Text>{formatNumber(credit - debit)}</Text>
                    ) : null,
                  style: {
                    borderTop: "1px solid black",
                  },
                },
              ],
            },
          ]}
          columns={columns}
        />
      </Page>
    </Document>
  );
}

//-----------------------------------------------------------------------------
function PageNumber() {
  const { t } = useTranslation("common");

  return (
    <Text
      render={({ pageNumber, totalPages }) =>
        `${t("Page", "Page")} ${pageNumber} / ${totalPages}`
      }
    />
  );
}
//-----------------------------------------------------------------------------
