import { pdfConverter } from "./pdf";

let index = 0;

async function printPDF(filename, data) {
  const blobURL = URL.createObjectURL(data);

  const iframe = document.createElement("iframe"); //load content in an iframe to print later
  iframe.setAttribute("data-type", "for-printing");
  iframe.id = `for-printing-${index++}`;
  document.body.appendChild(iframe);

  iframe.style.display = "none";
  iframe.src = blobURL;
  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();

      const forPrinting = document.querySelectorAll(
        `iframe[data-type="for-printing"]`
      );

      forPrinting.forEach((el) => {
        if (iframe.id !== el.id) {
          document.body.removeChild(el);
        }
      });
    }, 1);
  };
}

export const pdfPrintConverter = {
  name: "pdf (print)",
  stringify: pdfConverter.stringify,
  download: printPDF,
};
