const numberFormatter = new Intl.NumberFormat("de-DE", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function formatNumber(num) {
  return numberFormatter.format(num);
}

export function formatDate(date, long = false) {
  return !long
    ? new Date(date).toLocaleDateString()
    : new Date(date).toLocaleDateString("nl-BE", {
        year: "numeric",
        month: "long",
      });
}

export function formatTime(date) {
  return new Date(date).toLocaleTimeString();
}

export function formatDateTime(date) {
  return `${formatDate(date)} ${formatTime(date)}`;
}
