import PersonIcon from "@mui/icons-material/Person";
import CompanyIcon from "@mui/icons-material/Business";

const UserName = ({ User }) => {
  return User.Company ? (
    <span>
      {`${User.Company?.name} (${User.full_name})`} <CompanyIcon />
    </span>
  ) : (
    <span>
      {User.full_name} <PersonIcon />
    </span>
  );
};

export default UserName;
