import React from "react";
import { useLocation } from "react-router-dom";

import "./dashboard.css";
import DashboardView from "../templates/DashboardView";

import Overview from "./Overview";
import Profile from "./Profile";
import Sales from "./Sales";
import Associations from "./Associations";
import Definitions from "./Definitions";
import Incidents from "./Incidents";
import Accounting from "./Accounting";
import Purchases from "./Purchases";
import Provisions from "./Provisions";
import Financial from "./Financial";
import Documents from "./Documents";
import Meetings from "./Meetings";
import Reminders from "./Reminders";
import Bookkeeping from "./Bookkeeping";
import Suppliers from "./Suppliers";
import Data from "./Data";
import { useTranslation } from "react-i18next";

const components = {
  Overview: <Overview key="Overview" />,
  Profile: <Profile key="Profile" />,
  Associations: <Associations key="Associations" />,
  Sales: <Sales key="Sales" />,
  Definitions: <Definitions key="Definitions" />,
  Incidents: <Incidents key="Incidents" />,
  Accounting: <Accounting key="Accounting" />,
  Purchases: <Purchases key="Purchases" />,
  Provisions: <Provisions key="Provisions" />,
  Financial: <Financial key="Financial" />,
  Bookkeeping: <Bookkeeping key="Bookkeeping" />,
  Documents: <Documents key="Documents" />,
  Meetings: <Meetings key="Meetings" />,
  Suppliers: <Suppliers key="Suppliers" />,
  Data: <Data key="Data" />,
  Reminders: <Reminders key="Reminders" />,
};

export default function Dashboard(props) {
  const location = useLocation();
  const hash = location.hash.replace("#", "");
  const { t } = useTranslation("common");

  const translations = {
    Profile: t("dashboard.menu.profile.title", "Profile"),
    Overview: t("dashboard.menu.overview.title", "Overview"),
    Sales: t("dashboard.menu.sales.title", "Sales"),
    Associations: t("dashboard.menu.associations.title", "VMEs"),
    Definitions: t("dashboard.menu.definitions.title", "Definitions"),
    Incidents: t("dashboard.menu.incidents.title", "Incidents"),
    Accounting: t("dashboard.menu.financial_years.title", "Financial Years"),
    Bookkeeping: t("dashboard.menu.bookkeeping.title", "Reports"),
    Financial: t("dashboard.menu.financial.title", "Bank Accounts"),
    Purchases: t("dashboard.menu.purchases.title", "Purchases"),
    Provisions: t("dashboard.menu.provisions.title", "Provisions"),
    Documents: t("dashboard.menu.documents.title", "Documents"),
    Meetings: t("dashboard.menu.meetings.title", "Agenda"),
    Suppliers: t("dashboard.menu.suppliers.title", "Suppliers"),
    Data: t("dashboard.menu.data.title", "Basic data"),
    Reminders: t("dashboard.menu.reminders.title", "Reminders"),
  };

  return (
    <DashboardView
      title={translations[hash]}
      activeKey={"/app" + location.hash}
    >
      <div className="container-fluid">
        {Object.keys(translations).map((key) => {
          return hash === key && components[key];
        })}
      </div>
    </DashboardView>
  );
}
