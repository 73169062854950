import React from "react";
import Table from "../atoms/List/Table";
import { ExportModal } from "./ExportModal";
import { EmailModal } from "./EmailModal";
import CheckBox from "../atoms/Input/CheckBox";

import { postImmediateReminders } from "@syntbeheer/api/services/reminders";

const initialState = {
  visible: false,
  data: [],
};

function reducer(state, action) {
  if (action.type === "open export modal") {
    return {
      ...state,
      visible: true,
      data: action.data,
    };
  }

  if (action.type === "close export modal") {
    return {
      ...state,
      visible: false,
      data: [],
    };
  }

  return state;
}

function getOriginalData(rows) {
  return rows.flatMap((row) =>
    row.original ? [row.original] : getOriginalData(row.subRows)
  );
}

export function ExportTable(props) {
  const {
    exportFileName,
    renderDataFilterComponent,
    dataFilter,
    modalData,
    mapColumns = (columns) => columns,
    ...tableProps
  } = props;

  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [emailModalVisible, setEmailModalVisible] = React.useState(false);
  const [disableIds, setDisableIds] = React.useState([]);

  return (
    <>
      <Table
        {...tableProps}
        enableExport={true}
        onExport={(rows) => {
          console.log("rows", rows);
          dispatch({
            type: "open export modal",
            data: getOriginalData(rows),
          });
        }}
        onEmail={(rows) => {
          console.log("rows", rows);
          setEmailModalVisible(true);
        }}
      />
      <ExportModal
        data={modalData ?? state.data}
        columns={mapColumns(tableProps.columns)}
        modalVisible={state.visible}
        onCloseModal={() => {
          dispatch({
            type: "close export modal",
          });
        }}
        exportFileName={exportFileName}
        tableTitle={tableProps.title}
        renderDataFilterComponent={renderDataFilterComponent}
        dataFilter={dataFilter}
      />
      <EmailModal
        data={modalData ?? tableProps.data}
        columns={[
          {
            Header: "Select",
            accessor: "select",
            Cell: ({ row }) => (
              <CheckBox
                label=""
                checked={!disableIds.includes(row.values.id)}
                onChange={(event) => {
                  console.log(event.target.checked);
                  if (!event.target.checked) {
                    setDisableIds((prev) => [...prev, row.values.id]);
                  } else {
                    setDisableIds((prev) =>
                      prev.filter((id) => id !== row.values.id)
                    );
                  }
                }}
                marginbottom={0}
              />
            ),
          },
          ...tableProps.columns.filter((c) => c.showOnEmail),
        ]}
        onDisableAll={() => {
          setDisableIds((modalData ?? tableProps.data).map((r) => r.id));
        }}
        modalVisible={emailModalVisible}
        onCloseModal={(filteredData) => {
          setEmailModalVisible(false);
        }}
        onSubmit={(data) => {
          console.log("Send");
          // send reminders with ids from filteredData to backend
          postImmediateReminders({
            data: data.filter((r) => !disableIds.includes(r.id)),
            type: "provisions",
          })
            .then((res) => {
              console.log("res", res);
              if (res.success) {
                setEmailModalVisible(false);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        }}
        tableTitle={tableProps.title}
        renderDataFilterComponent={renderDataFilterComponent}
        dataFilter={dataFilter}
      />
    </>
  );
}
