import { pdf } from "@react-pdf/renderer";
import React from "react";
import PDFDocument from "../../PDF";
import { saveAs } from "file-saver";

async function toPDF(columns, rows) {
  const document = {
    sections: [
      {
        table: {
          headers: columns.map((column) => column.header),
          rows: rows.map((row) => ({
            cells: columns.map((column) => row[column.header]),
          })),
          settings: {
            widths: columns.map(() => `${100 / columns.length}%`),
            textAligns: columns.map(() => "left"),
            fontSize: 8,
          },
        },
      },
    ],
  };

  const Document = <PDFDocument document={document} />;
  const blob = await pdf(Document).toBlob();
  return blob;
}

async function downloadPDF(filename, data) {
  saveAs(data, filename + ".pdf");
}

export const pdfConverter = {
  name: "pdf",
  stringify: toPDF,
  download: downloadPDF,
};
