import React from "react";
import Select from "react-select";

const DropDown = ({
  options,
  marginbottom = true,
  field = false,
  minwidth = true,
  menuPlacement = "auto",
  ...props
}) => {
  options = options.map((option) => {
    if (typeof option === "string" || typeof option === "number") {
      option = { label: option, value: option };
    } else if (field) {
      option.value = option[field];
      option.label = option.label || option[field];
    }
    return option;
  });
  const customStyles = {
    control: (base, state) => [
      {
        ...base,
        borderRadius: "1rem",
        borderWidth: "1px",
        borderColor: "#ced4da",
        "&:hover": { borderColor: "var(--primary-transparent-color)" }, // border style on hover
        boxShadow: state.isFocused
          ? "0 0 0 0.2rem rgb(192 29 33 / 25%)"
          : "none", // no box-shadow
      },
      props.error && {
        borderColor: "var(--danger-color)",
      },
    ],
    menu: (provided, state) => ({
      ...provided,
      color: state.selectProps.menuColor,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused
          ? "var(--primary-transparent-color)"
          : "",
      color: "black",
      "&:active": { backgroundColor: "var(--primary-transparent-color)" },
    }),
    container: (base) => ({
      ...base,
      flex: 1,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0.5rem 1rem",
    }),
    placeholder: (base) => ({
      ...base,
      color: "var(--placeholder-color)",
    }),
  };
  const getLabel = (value) => {
    let label = null;
    options.forEach((option) => {
      if (option.value === value) {
        label = option.label;
      }
    });
    return label;
  };
  return (
    <div
      className={`form-group has-validation ${marginbottom ? "mb-5" : "mb-0"}`}
      style={minwidth ? { minWidth: "300px" } : {}}
    >
      {props.label && <label htmlFor={props.name}>{props.label}</label>}
      <Select
        {...props}
        value={
          typeof props.value !== "object"
            ? { label: getLabel(props.value), value: props.value }
            : props.value
        }
        name={props.name}
        options={options}
        aria-describedby={`${props.name}Help`}
        className={`${props.error ? "is-invalid" : ""}`}
        styles={customStyles}
        isDisabled={props.disabled}
        menuPlacement={menuPlacement}
      />
      {props.help && (
        <small id={`${props.name}Help`} className="form-text text-muted">
          {props.help}
        </small>
      )}
      {props.error && (
        <small id={`${props.name}Error`} className="invalid-feedback form-text">
          {props.error}
        </small>
      )}
    </div>
  );
};

export default React.memo(DropDown);
