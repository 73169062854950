import { BaseDownloadPdf } from "./../../organisms/PDF";
import colors from "@syntbeheer/assets/colors";
import React from "react";
import { useTranslation } from "react-i18next";
import { VMEsPicker } from "../../atoms/Input/VMEsPicker";
import {
  Owners,
  getOwnersData,
} from "../../organisms/bookkeeping/documents/Owners";
import {
  Suppliers,
  getSuppliersData,
} from "../../organisms/bookkeeping/documents/Suppliers";
import {
  FullEntityHistoryDocument,
  getMovementOwnersData,
  getMovementSuppliersData,
} from "../../organisms/bookkeeping/documents/FullEntityHistory";
import {
  InvoiceList,
  getInvoiceData,
} from "../../organisms/bookkeeping/documents/InvoiceList";
import {
  BudgetModal,
  BudgetsDocument,
  getBudgetsData,
} from "../../organisms/bookkeeping/documents/Budgets";
import {
  DiaryPurchase,
  getDiaryPurchasesData,
} from "../../organisms/bookkeeping/documents/DiaryPurchase";
import {
  ActivePassiveBalanceDocument,
  getActivePassiveData,
} from "../../organisms/bookkeeping/documents/ActivePassiveBalance";
import Table from "./../../atoms/List/Table";
import DropDown from "../../atoms/Input/DropDown";
import DateTimeField from "./../../atoms/Input/DateTime";
import * as vmesSelectors from "@syntbeheer/api/services/vmes/selectors";
import { getFinancialYears } from "@syntbeheer/api/services/accounting";

function FinancialYearPicker({ value, onChange, label, filter = false }) {
  const { t } = useTranslation("common");
  const [financialYears, setFinancialYears] = React.useState([]);

  React.useEffect(() => {
    let cancelled = false;

    getFinancialYears().then((response) => {
      if (response.success && !cancelled) {
        setFinancialYears(
          response.FinancialYears.filter(
            (fy) => !filter || new Date(fy[filter]) <= new Date()
          )
        );
      }
    });

    return () => {
      cancelled = true;
    };
  }, []);

  return (
    <DropDown
      label={label}
      options={financialYears.map((fy) => ({
        value: fy,
        label: fy.year,
      }))}
      value={value}
      placeholder={t(
        "dashboard.bookkeeping.fields.financialYear.placeholder",
        "select financial year"
      )}
      onChange={(selected) => {
        onChange(selected);
      }}
    />
  );
}

export default function Bookkeeping() {
  const { t } = useTranslation("common");
  const VME = vmesSelectors.get().VME || {};
  // current vme is enabled by default
  const [selectedVME, setSelectedVME] = React.useState({
    label: VME.alias || VME.name,
    value: VME.id,
  });

  const [financialYearStart, setFinancialYearStart] = React.useState(null);
  const [financialYearEnd, setFinancialYearEnd] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(new Date());

  return (
    <div className="row">
      <div className="col my-3">
        <h2 className="mb-5" style={{ fontWeight: 700, color: colors.primary }}>
          {t("dashboard.bookkeeping.title", "Bookkeeping")}
        </h2>
        <VMEsPicker
          label={t(
            "dashboard.bookkeeping.fields.vmespicker.label",
            "Select VME(s)"
          )}
          value={selectedVME}
          onChange={setSelectedVME}
        />

        <div className="row">
          <div className="col">
            <FinancialYearPicker
              label={t(
                "dashboard.bookkeeping.fields.financialYearStart.label",
                "Start (Financial year)"
              )}
              value={financialYearStart}
              onChange={(fy) => {
                setFinancialYearStart(fy);
                setStartDate(new Date(fy.value.start_date));
              }}
              filter="start_date"
            />
          </div>
          <div className="col">
            <FinancialYearPicker
              label={t(
                "dashboard.bookkeeping.fields.financialYearEnd.label",
                "End (Financial year)"
              )}
              value={financialYearEnd}
              onChange={(fy) => {
                setFinancialYearEnd(fy);
                setEndDate(new Date(fy.value.end_date));
              }}
              filter="end_date"
            />
          </div>
        </div>

        {financialYearStart && (
          <div className="row">
            <div className="col">
              <DateTimeField
                label={t("dashboard.bookkeeping.startAt.label", "Start")}
                name="startDate"
                value={startDate || null}
                placeholder={t(
                  "dashboard.bookkeeping.startAt.placeholder",
                  "select a starting date"
                )}
                disabled
              />
            </div>
            <div className="col">
              <DateTimeField
                label={t("dashboard.bookkeeping.endDate.label", "End")}
                name="endDate"
                value={endDate || null}
                placeholder={t(
                  "dashboard.bookkeeping.endDate.placeholder",
                  "select an end date"
                )}
                disabled
              />
            </div>
          </div>
        )}

        <Documents
          vmeId={selectedVME?.value}
          startDate={new Date(startDate).toISOString()}
          endDate={new Date(endDate).toISOString()}
          selectedVME={selectedVME}
        />
      </div>
    </div>
  );
}

function Documents({ vmeId, startDate, endDate, selectedVME }) {
  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "",
      accessor: "name",
      Cell: ({ row, value }) => {
        return value;
      },
    },
    {
      Header: "",
      accessor: "doc_type",
      Cell: ({ row }) => {
        const { fetchData, renderDocument, getFileName, name, renderPopup } =
          row.original;

        const [visible, setVisible] = React.useState(false);
        const [value, setValue] = React.useState({});
        const ref = React.useRef(null);

        return (
          <>
            <BaseDownloadPdf
              renderDocument={async () => {
                if (fetchData && renderDocument) {
                  let popupResult = {};
                  if (renderPopup) {
                    popupResult = await new Promise((resolve) => {
                      ref.current = resolve;
                      setVisible(true);
                    });
                  }
                  const data = await fetchData?.({ vmeId, startDate, endDate });
                  return {
                    document: renderDocument(data, popupResult),
                    fileName: getFileName?.(data, name) ?? name,
                  };
                }

                return null;
              }}
            />
            {visible && renderPopup
              ? renderPopup({
                  visible,
                  onClose: () => {
                    setVisible(false);
                    setValue({});
                    if (typeof ref.current === "function") {
                      ref.current(value);
                      ref.current = null;
                    }
                  },
                  value,
                  onChange: setValue,
                })
              : null}
          </>
        );
      },
    },
  ];

  const { t } = useTranslation("common");

  const data = [
    {
      id: 9,
      name:
        t("Global Settlement", "Global Settlement") + " " + selectedVME.label,
      renderDocument: (data) => <InvoiceList data={data} />,
      fetchData: (options) => getInvoiceData({ ...options, t }),
    },
    {
      id: 2,
      name: t("CA Owners", "CA Owners") + " " + selectedVME.label,
      renderDocument: (data) => <Owners data={data} />,
      fetchData: (options) => getOwnersData({ ...options, t }),
    },
    {
      id: 3,
      name: t("CA Suppliers", "CA Suppliers") + " " + selectedVME.label,
      renderDocument: (data) => <Suppliers data={data} />,
      fetchData: (options) => getSuppliersData({ ...options, t }),
    },
    {
      id: 2,
      name:
        t("All Movements Owners", "All Movements Owners") +
        " " +
        selectedVME.label,
      renderDocument: (data) => (
        <FullEntityHistoryDocument
          data={data}
          entityColumnHeader={t("Customer", "Customer")}
        />
      ),
      fetchData: (options) => getMovementOwnersData({ ...options, t }),
    },
    {
      id: 10,
      name:
        t("All Movements Suppliers", "All Movements Suppliers") +
        " " +
        selectedVME.label,
      renderDocument: (data) => (
        <FullEntityHistoryDocument
          data={data}
          entityColumnHeader={t("Supplier", "Supplier")}
        />
      ),
      fetchData: (options) => getMovementSuppliersData({ ...options, t }),
    },
    {
      id: 11,
      name:
        t("Budgets per GL cost account", "Budgets per GL cost account") +
        " " +
        selectedVME.label,
      renderDocument: (data, popupResult) => (
        <BudgetsDocument data={data} popupResult={popupResult} />
      ),
      fetchData: (options) => getBudgetsData({ ...options, t }),
      renderPopup: ({ visible, onClose, value, onChange }) => (
        <BudgetModal
          visible={visible}
          onClose={onClose}
          value={value}
          onChange={onChange}
        />
      ),
    },
    {
      id: 5,
      name: t(
        "Diary: Purchases - List of Invoices",
        "Diary: Purchases - List of Invoices"
      ),
      renderDocument: (data) => <DiaryPurchase data={data} />,
      fetchData: (options) => getDiaryPurchasesData({ ...options, t }),
    },
    {
      id: 1,
      name:
        t("Balance sheet - Active/Passive", "Balance sheet - Active/Passive") +
        " " +
        selectedVME.label,
      renderDocument: (data) => <ActivePassiveBalanceDocument data={data} />,
      fetchData: (options) => getActivePassiveData({ ...options, t }),
    },
    /*
    {
      id: 6,
      name: "Diary fin div BA3 - ACCOUNT 2",
    },
    {
      id: 7,
      name: "Diary fin div BOK - Balance sheet opening",
    },
    {
      id: 8,
      name: "Diary fin div DIV - Various",
    },
    */
  ];
  console.log(`data`, data);

  return (
    <Table
      columns={columns}
      data={data}
      title={t("dashboard.bookkeeping.documents.title", "Documents")}
      enableSearch={false}
      enableSort={false}
    />
  );
}
