import { downloadTextFile } from "./utils";

async function toJSON(columns, rows) {
  const content = JSON.stringify(rows);

  return content;
}

async function downloadJSON(filename, data) {
  await downloadTextFile(filename + ".json", data, "json");
}

export const jsonConverter = {
  name: "json",
  stringify: toJSON,
  download: downloadJSON,
};
